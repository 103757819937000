import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Service from '../views/Service.vue'
import News from '../views/News.vue'
import News2 from '../views/News2.vue'
import QA from '../views/QA.vue'
import Contact from '../views/Contact.vue'
import Sitemap from '../views/Sitemap.vue'



Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/Service',
    name: 'Service',
    component: Service
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },
  {
    path: '/News2',
    name: 'News2',
    component: News2
  },
  {
    path: '/About',
    name: 'About',
    component: About
  },
  {
    path: '/QA',
    name: 'QA',
    component: QA
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/Sitemap',
    name: 'Sitemap',
    component: Sitemap
  }
]


const router = new VueRouter({
  mode: 'history',
  base: 'woman',
  routes,
  scrollBehavior(to,from,savedPosition){
    if(savedPosition){
      return savedPosition;
    }else{
      return {x:0,y:0}
    }
  },
})


export default router
