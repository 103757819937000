<template>
    <div class="QA">
        <div class="hero">
            <div class="container-fluid">
                <div class="row" style="position: relative;">
                    <div class="hero-box">
                        <div class="hero">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="" style="background: #f5f4f0;">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="warp">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="item-content">
                                <div class="item">
                                    <div class="scroll-btn">
                                        <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -80,duration: 1500}">
                                            <div>
                                                <span class="down-arrow-1"></span>
                                                <span class="down-arrow-2"></span>
                                                <span class="down-arrow-3"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center item-t1">
                                        <div class="col-12">
                                            <h2 class="lineder mb-5" id="element">相關問與答</h2>
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-12">
                                            <div class="tb-box mb-3">
                                                <table class="table">
                                                    <tbody>
                                                        <tr>
                                                          <td>
                                                            <p v-b-modal.modal-lg class="p-qa">「徵信公司」須具備那幾種證書或執照或程序才算合法業者?</p>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            <p v-b-modal.modal-1 class="p-qa">通姦罪能否只告配偶或只告與其相姦之人？</p>
                                                          </td>                                                          
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                              <p v-b-modal.modal-2 class="p-qa">何等情形即不得提出通姦罪之告訴？</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                              <p v-b-modal.modal-3 class="p-qa">成立通姦罪一定要抓姦在床？</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                              <p v-b-modal.modal-4 class="p-qa">配偶與人通姦，被害之配偶能否請求損害賠償？</p>                                                              
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                              <p v-b-modal.modal-5 class="p-qa">通姦與強姦、和姦有有何不同？</p>                                                              
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                              <p v-b-modal.modal-6 class="p-qa">通姦罪之告訴期間有多長？如何起算？</p>                                                              
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                              <p v-b-modal.modal-7 class="p-qa">告訴後能否撤回？能否只對配偶或相姦人一人撤回？</p>                                                              
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                              <p v-b-modal.modal-8 class="p-qa">誰可提出通姦之告訴？</p>                                                              
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <nav class="d-flex justify-content-center">
                                                <ul class="pagination">
                                                    <li class="page-item disabled">
                                                        <span class="page-link">回上頁</span>
                                                    </li>
                                                    <li class="page-item active" aria-current="page">
                                                        <span class="page-link">1</span>
                                                    </li>
                                                    <li class="page-item disabled">
                                                        <span class="page-link">下一頁</span>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
          <b-modal id="modal-lg" size="lg" centered title="「徵信公司」須具備那幾種證書或執照或程序才算合法業者?">
            <div class="d-block">
              <p class="qa-p">
                依據經濟部徵信業者管理相關法規摘述如下: <br>
                一、所稱徵信業，指財團法人中華徵信聯合中心及經營左列業務之營利事業：<br>
                <ul class="list">
                  <li>企業及其負責人財務及債信資料之蒐集、整理、分析、研判、編譯及提供。</li>
                  <li>一般經濟、市場及行業徵信資料之蒐集、分析、研判、編譯及提供。</li>
                  <li>個人信用及財產徵信資料之蒐集、分析、研判、編譯及提供。</li>
                  <li>動產、不動產時價徵信資料之蒐集、分析、研判、編譯及提供。</li>
                  <li>其他有關經濟徵信之業務。</li>
                </ul>
                二、徵信業需向經濟部或地方縣市政府申請營業許可登記，應由負責人填具申請書(包含營利事業登記、公司登記、徵信業經營電腦處理個人資料許可登記)，向主管機關(經濟部)申辦。<br><br>
                三、徵信業於核准許可登記後，應將本法(電腦處理個人資料保護法)第二十條第一項第一款至第十款所列之事項登載於當地新聞紙，其期間不得少於二日，並於登載後十日內將新聞紙一份檢送主管機關。<br><br>
                四、徵信業應備置簿冊登載本法第二十條第一項第一款至第十款所列事項，並供查閱。前項簿冊得以電腦終端設備或其他足供當事人查閱之相關設備、文件方式代替之。
              </p>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  class="float-right"
                  @click="hideModal"
                >
                  Close
                </b-button>
              </div>
            </template>
          </b-modal>
          <b-modal id="modal-1" size="lg" centered title="通姦罪能否只告配偶或只告與其相姦之人？">
            <div class="d-block">
              <p class="qa-p">
                刑事訴訟法第二百三十九條本文規定，告訴乃論之罪，對於共犯之一人告訴或撤回告訴者，其效力及於其他共犯，稱之為「告訴之主觀不可分原則」。<br><br>
                所以假如提起告訴，不能只告配偶或只告與其相姦之人，這是因為告訴係對於犯罪事實為之，並非對於特定之犯人為之，因之，告訴權之行使，僅就該犯罪事實是否告訴有自由決定之權，並非許其有選擇所告訴之犯人之意。雖然，依照法條明文，告訴之主觀不可分，僅適用於「告訴」及「撤回告訴」兩種情形，但實務將其擴張至其他情形，承認所謂「不得告訴之主觀不可分」及「不得自訴之主觀不可分」。前者，如甲、乙犯通姦罪，甲之妻丙對甲為縱容、宥恕而不得告訴者（刑法第二百四十五條第二項），依實務見解，丙對乙亦不得告訴。<br><br>
                後者，如甲、乙犯通姦罪，甲之妻丙對甲不得自訴者（刑事訴訟法第三百二十一條），依實務見解，丙對乙亦不得自訴。所以通姦罪，不論是提告訴或提自訴，都不能只告配偶或只告與其相姦之人。
              </p>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  class="float-right"
                  @click="hideModal2"
                >
                  Close
                </b-button>
              </div>
            </template>
          </b-modal>
          <b-modal id="modal-2" size="lg" centered title="何等情形即不得提出通姦罪之告訴？">
            <div class="d-block">
              <p class="qa-p">
                刑法第二百四十五條第二項規定：<br><br>
                &nbsp;&nbsp;&nbsp;&nbsp;「第二百三十九條之罪，配偶縱容或宥恕者，不得告訴。」所謂縱容是指放縱或容許，乃事前所為之表示；宥恕則指原宥或寬恕，乃事後所為。實務對此曾表示：縱容配偶與人通姦，告訴權即已喪失，不能因嗣後翻悔而回復。又所謂縱容，但有容許其配偶與人通姦之行為即足，至相姦之人，原不必經其容許（司法院二十五年第一六○五號解釋）。<br><br>
                &nbsp;&nbsp;&nbsp;&nbsp;所以，「如甲、乙明知彼此均係有配偶之人，仍發生姦淫行為，嗣為甲之配偶丙查獲，甲即懇求丙宥恕上開行為，經丙同意以甲應月付生活費二萬元為條件予以宥恕。甲允諾後即置之不理，丙怒極乃告訴甲、乙通姦行為。此時，依刑法第二百四十五第二項規定宥恕配偶與人通姦即不得告訴，此為配偶告訴權之限制，換言之對於配偶之通姦行為，若事後宥恕，一經有宥恕之表示告訴權即已喪失，不能因嗣後反悔而回復即喪失其告訴權，因此丙對於二人均不得再告訴。甲不付月費應僅得循民事途徑救濟，而不得再行告訴。
              </p>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  class="float-right"
                  @click="hideModal3"
                >
                  Close
                </b-button>
              </div>
            </template>
          </b-modal>
          <b-modal id="modal-3" size="lg" centered title="成立通姦罪一定要抓姦在床？">
            <div class="d-block">
              <p class="qa-p">
                通姦罪是否一定要抓姦在床才能成立？<br><br>
                &nbsp;&nbsp;&nbsp;&nbsp;這個問題就像問殺人罪是否一定要當場看到白刀子進，紅刀子出，有人死了才會成立？或是小偷當場被活逮才會成立竊盜罪一樣，當然不是。因為前述情形都是犯罪在實施中或實施後即時發覺之現行犯（刑事訴訟法第八十八條第二項），然而絕大部分犯罪往往都是在事後才被發覺，但我們不能因此說它就不是犯罪，問題是如何去認定的問題。<br><br>
                所以刑事訴訟法第一百五十四條規定：<br><br>
                &nbsp;&nbsp;&nbsp;&nbsp;「犯罪事實應依證據認定之，無證據不得推定其犯罪事實。」這裡的證據係指證據能力，也就是證據資格。要取得「證據能力」須經過嚴格的證明程序，包括法定證據方法（被告、證人、文書、鑑定、勘驗）和法定調查程序，刑事訴訟法對此訂有詳細的規定。於具備證據能力後則有所謂證明力的問題，法律對此採自由心證原則，即關於如何評價證據之證據價值（證明力）的原則。至於有罪之判決，必須證明至何等程序，最高法院曾謂：<br>
                認定犯罪事實所憑之證據，雖不以直接證據為限，間接證據亦包括在內；但無論其為直接或間接證據，其為訴訟上之證明，必須達於一般人均可得確信其為真實的程度，而無合理之懷疑存在時，始得據為被告有罪之認定（最高法院七六年台上四九八六號判例，八十七年台上一五四二號判例）。<br><br>
                &nbsp;&nbsp;&nbsp;&nbsp;所以通姦罪即使不是捉姦在床，但若從其他證據方法中，（例如目擊者看到通姦人進出賓館，鑑定犯罪人遺留物上之DNA等。）能達得到上述之有罪判決之確定程度者，仍可成立通姦罪。
              </p>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  class="float-right"
                  @click="hideModal4"
                >
                  Close
                </b-button>
              </div>
            </template>
          </b-modal>
          <b-modal id="modal-4" size="lg" centered title="配偶與人通姦，被害之配偶能否請求損害賠償？">
            <div class="d-block">
              <p class="qa-p">
                侵權行為之損害賠償以權利或利益受有損害為前提，配偶與人通姦，究竟是何種權利或利益受侵害？對此最高法院曾表示如下見解：<br>
                &nbsp;&nbsp;&nbsp;&nbsp;通姦之足以破壞夫妻間之共同生活而非法之所許，此從公序良俗之觀點可得斷言，不問所侵害係何權利，對於配偶之他方應構成共同侵權行為。婚姻係以夫妻之共同生活為其目的，配偶應互助協力保持其共同生活之圓滿安全及幸福，而夫妻互守誠實，係為確保其共同生活之圓滿安全及幸福之必要條件，故應解為配偶因婚姻契約而互負誠實之義務，配偶之一方行為不誠實，破壞共同生活之圓滿安全及幸福者，即為違反因婚姻契約之義務而侵害他方之權利。<br><br>
                民法第一百九十五條第三項規定：<br>
                &nbsp;&nbsp;&nbsp;&nbsp;「前二項規定，於不法侵害他人基於父、母、子、女或配偶關係之身分法法益而情節重大者，準用之」所謂基於婚姻關係的身分法益，指配偶權而言，立法理由書以配偶之一方被強姦為例，立法理由書雖未舉實務上最具爭議的通姦案例，解釋上應肯定之。是故，被害配偶得請求損害賠償（即慰撫金）。<br><br>
                第195條（侵害身體健康名譽或自由之非財產上損害賠償）<br>
                &nbsp;&nbsp;&nbsp;&nbsp;不法侵害他人之身體、健康、名譽、自由、信用、隱私、貞操，或不法侵害其他人格法益而情節重大者，被害人雖非財產上之損害，亦得請求賠償相當之金額。其名譽被侵害者，並得請求回復名譽之適當處分。<br><br>
                &nbsp;&nbsp;&nbsp;&nbsp;前項請求權，不得讓與或繼承。但以金額賠償之請求權已依契約承諾，或已起訴者，不在此限。 前二項規定，於不法侵害他人基於父、母、子、女或配偶關係之身分法益而情節重大者，準用之。
              </p>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  class="float-right"
                  @click="hideModal5"
                >
                  Close
                </b-button>
              </div>
            </template>
          </b-modal>
          <b-modal id="modal-5" size="lg" centered title="通姦與強姦、和姦有有何不同？">
            <div class="d-block">
              <p class="qa-p">
                侵權行為之損害賠償以權利或利益受有損害為前提，配偶與人通姦，究竟是何種權利或利益受侵害？對此最高法院曾表示如下見解：<br>
                &nbsp;&nbsp;&nbsp;&nbsp;通姦之足以破壞夫妻間之共同生活而非法之所許，此從公序良俗之觀點可得斷言，不問所侵害係何權利，對於配偶之他方應構成共同侵權行為。婚姻係以夫妻之共同生活為其目的，配偶應互助協力保持其共同生活之圓滿安全及幸福，而夫妻互守誠實，係為確保其共同生活之圓滿安全及幸福之必要條件，故應解為配偶因婚姻契約而互負誠實之義務，配偶之一方行為不誠實，破壞共同生活之圓滿安全及幸福者，即為違反因婚姻契約之義務而侵害他方之權利。<br><br>
                民法第一百九十五條第三項規定：<br>
                &nbsp;&nbsp;&nbsp;&nbsp;「前二項規定，於不法侵害他人基於父、母、子、女或配偶關係之身分法法益而情節重大者，準用之」所謂基於婚姻關係的身分法益，指配偶權而言，立法理由書以配偶之一方被強姦為例，立法理由書雖未舉實務上最具爭議的通姦案例，解釋上應肯定之。<br><br>
                是故，被害配偶得請求損害賠償（即慰撫金）。<br><br>
                第195條（侵害身體健康名譽或自由之非財產上損害賠償）<br>
                &nbsp;&nbsp;&nbsp;&nbsp;不法侵害他人之身體、健康、名譽、自由、信用、隱私、貞操，或不法侵害其他人格法益而情節重大者，被害人雖非財產上之損害，亦得請求賠償相當之金額。其名譽被侵害者，並得請求回復名譽之適當處分。<br><br>
                &nbsp;&nbsp;&nbsp;&nbsp;前項請求權，不得讓與或繼承。但以金額賠償之請求權已依契約承諾，或已起訴者，不在此限。 前二項規定，於不法侵害他人基於父、母、子、女或配偶關係之身分法益而情節重大者，準用之。
              </p>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  class="float-right"
                  @click="hideModal6"
                >
                  Close
                </b-button>
              </div>
            </template>
          </b-modal>
          <b-modal id="modal-6" size="lg" centered title="通姦罪之告訴期間有多長？如何起算？">
            <div class="d-block">
              <p class="qa-p">
                &nbsp;&nbsp;&nbsp;&nbsp;依刑事訴訟法第二百三十七條第一項規定：<br>
                「告訴乃論之罪，其告訴應自得為告訴之人知悉犯人之時起，於六個月內為之。」所謂的「知悉」，是指得為告訴之人確知犯人之犯罪行為，也就是說，以得為告訴之人之「主觀認知」為標準，而且認知應該達到「確知」之程度，如果僅係懷疑他人有此犯罪行為，但未得確切之證實者，尚不得稱為知悉。至於連續性、繼續性的犯罪，其告訴期間應自知悉犯人最後一次行為或行為終了時起算，否則難免發生侵害行為尚未結束，但是告訴期間已過之窘境。
              </p>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  class="float-right"
                  @click="hideModal7"
                >
                  Close
                </b-button>
              </div>
            </template>
          </b-modal>
          <b-modal id="modal-7" size="lg" centered title="告訴後能否撤回？能否只對配偶或相姦人一人撤回？">
            <div class="d-block">
              <p class="qa-p">
                刑事訴訟法第二百三十八條第一項規定：<br>
                &nbsp;&nbsp;&nbsp;&nbsp;「告訴乃論之罪，告訴人於第一審辯論終結前，得撤回其告訴。」又通姦罪，對於配偶提出告訴者，效力固然及於相姦人；但「對於配偶撤回告訴者，其效力不及於相姦人」（刑事訴訟法第二百三十九條但書）。因此，依其反面解釋，若只對相姦人撤回告訴，其效力則及於配偶。
              </p>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  class="float-right"
                  @click="hideModal8"
                >
                  Close
                </b-button>
              </div>
            </template>
          </b-modal>
          <b-modal id="modal-8" size="lg" centered title="誰可提出通姦之告訴？">
            <div class="d-block">
              <p class="qa-p">
                通姦罪法律上設有專屬告訴之限制，非配偶不得告訴（刑事訴訟法第二百三十四條第二項），亦即，犯通姦罪之配偶，為唯一之專屬告訴權人。<br><br>
                第234條（特定案件之告訴人）<br>
                刑法第230條之妨害風化罪，非左列之人不得告訴：<br>
                一、本人之直系血親尊親屬。<br>
                二、配偶或其直系血親尊親屬。<br>
                刑法第239條之妨害婚姻及家庭罪，非配偶不得告訴。<br>
                刑法第240條第二項之妨害婚姻及家庭罪，非配偶不得告訴。<br>
                刑法第298條之妨害自由罪，被略誘人之直系血親、三親等內之旁系血親、二親等內之姻親或家長、家屬亦得告訴 。<br>
                刑法第312條之妨害名譽及信用罪，已死者之配偶、直系血親、三親等內之旁系血親、二親等內之姻親或家長、家屬得為告訴。
              </p>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  class="float-right"
                  @click="hideModal9"
                >
                  Close
                </b-button>
              </div>
            </template>
          </b-modal>
        </section>
    </div>
</template>

<script>
export default {
    name: "QA",
    data() {    
        return {};
    },
    methods: {
      hideModal() {
        this.$root.$emit('bv::hide::modal', 'modal-lg', '#btnShow')
      },
      hideModal2() {
        this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
      },
      hideModal3() {
        this.$root.$emit('bv::hide::modal', 'modal-2', '#btnShow')
      },
      hideModal4() {
        this.$root.$emit('bv::hide::modal', 'modal-3', '#btnShow')
      },
      hideModal5() {
        this.$root.$emit('bv::hide::modal', 'modal-4', '#btnShow')
      },
      hideModal6() {
        this.$root.$emit('bv::hide::modal', 'modal-5', '#btnShow')
      },
      hideModal7() {
        this.$root.$emit('bv::hide::modal', 'modal-6', '#btnShow')
      },
      hideModal8() {
        this.$root.$emit('bv::hide::modal', 'modal-7', '#btnShow')
      },
      hideModal9() {
        this.$root.$emit('bv::hide::modal', 'modal-8', '#btnShow')
      },

    }
};
</script>



<style scoped>
.hero-box {
  width: 100%;
}
.hero {
  background-image: url(../assets/images/service.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  z-index: 1;
  -webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
.lineder {
  font-size: 3rem;
  text-align: center;
}
.lineder::after {
  content: "";
  width: 8%;
  height: 1.5px;
  background: red;
  display: block;
  margin: 0 auto;
}
.warp {
  display: block;
  position: relative;
  width: 100%;
  top: -100px;
  margin-bottom: -80px;
}
.item-t1{
  margin-top: 55px;
}
.item-content{
  margin-bottom: 20px;
}
.item-content .item {
  padding: 1rem;
  background: white;
  text-align: center;
  border-top: 5px solid rebeccapurple;
  border-radius: 0px 0px 10px 10px;
  text-align: inherit;
}
.scroll-btn{
  text-align: center;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid black; 
  border-bottom: 2px solid black;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
.tb-box{
    border: 5px solid #dee2e6;
}
.table{
    margin-bottom: 0;
}
.p-qa{
  margin-bottom: 0;
}
.p-qa:hover{
  color: slateblue;
}
.qa-p{
  margin-bottom: 0;
}
.list{
  margin: 0.5rem auto;
  list-style-type: decimal;
}
.disabled:hover{
  cursor: not-allowed;
}
/* .frame {
  margin: 0 auto;
  padding: 1.5rem;
  background: white;
  border-top: 5px solid rebeccapurple;
  margin-bottom: 20px;
  border-radius: 0px 0px 10px 10px;
} */

@media(max-width:767px){
  .lineder::after{
    width: 20%;
  }
  .modal-open .modal{
    padding: 0 !important;
  }
  .modal-dialog{
    margin: 0 0.5rem;
  }
}
</style>
