<template>
<div class="container-fluid">
   <div class="row">
      <div class="intro">
         <div class="viewbox">
            <vue-flux :options="vfOptions" :images="vfImages" :transitions="vfTransitions" ref="slider">
               <template v-slot:preloader>
                  <flux-preloader />
               </template>
            </vue-flux>
         </div>
         <div class="viewtext">
            <h2>國華女人徵信</h2>
            <p>Gwohaw-Woman, Peivate Detective</p>
            <!-- <div class="mt-5">
               <p>24小時免付費專線：
                  <a href="tel:0800363333" class="phone">0800-36-3333</a>
               </p>
            </div> -->
         </div>
      </div>
   </div>
</div>



</template>

<script>
import {
   VueFlux,
   FluxPreloader
} from 'vue-flux';

export default {
   components: {
      VueFlux,
      FluxPreloader
   },

   data: () => ({
      vfOptions: {
         allowFullscreen: true,
         autoplay: true,
         autohideTime: 2500,
         delay: 5000
      },
      vfImages: [ require("../assets/images/1.jpg"),
                  require("../assets/images/2.jpg"),
                  require("../assets/images/3.jpg")],
      vfTransitions: [ 'swipe' ],
      vfCaptions: [
         'Caption for image 1',
         'Caption for image 2',
         'Caption for image 3',
      ],
   }),
}
</script>

<style scoped >
   .intro{
      display: block;
      height: 100vh;
      width: 100vw;
      position: relative;
   }

   .viewbox{
      width: 100%;
      height: 100%;
      background: white;
   }
   .vue-flux{
      display: block;
      height: 100% !important;
      width: 100% !important;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
   }
   .viewtext{
      position: absolute;
      width: 85%;
      top: 40%;
      left: 0;
      z-index: 10;
   }
   .viewtext h2{
      font-size: 4rem;
      font-weight: bolder;
      text-align: right;
      color: rgb(255, 222, 89);
      padding-right: 15px;
   }
   .viewtext p{
      font-size: 1.8rem;
      padding: 5px 22px 5px 0px;
      text-align: right;
      color: black;
      background-color: rgb(255, 222, 89);
   }
   .phone{
      color: red;
      text-decoration: none;
      font-size: 40px;
   }
   .phone:hover{
      color: blue;
   }
   /* @media(max-width:1025px){
      .intro{
         height: 95vh;
      }
      .intro::before{
         border-width: 95vh 0 0 100vw;
      }
      .viewbox{
         height: 95%;
      }
      .viewtext{
         width: 40%;
         top: 40%;
      }
      .viewtext h2{
         font-size: 4rem;
      }
      .viewtext p{
         font-size: 1.5rem;
      }
      .phone{
         display: block;
      }
   }
   @media(max-width:911px){
      .viewtext h2{
         font-size: 3rem;
      }
      .viewtext p{
         font-size: 1.1rem;
      }
   }
   @media (max-width: 767px){
      .intro{
         height: 92vh;
      }
      .intro::before{
         border-width: 92vh 0 0 100vw;
      }
      .viewbox{
         margin-left: 6.9%;
         width: 85vw;
         height: 90%;
      }
      .viewtext{
         display: none;
      }
   }
   @media(max-width:322px){
      .intro{
         height: 88.5vh;
      }
      .intro::before{
         border-width: 88.5vh 0 0 100vw;
      }
   } */
</style>