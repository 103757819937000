import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import jQuery from 'jquery'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight,faLongArrowAltRight,faSignOutAlt,faPhoneVolume,faShareSquare } from '@fortawesome/free-solid-svg-icons'
import { faBimobject,faPiedPiperPp,faPage4,faContao,faWpforms,faHireAHelper } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'
import VueStepWizard from 'vue-step-wizard'
import 'vue-step-wizard/dist/vue-step-wizard.css'
import Vuelidate from 'vuelidate'








library.add(faArrowRight,faLongArrowAltRight,faSignOutAlt,faPhoneVolume,faBimobject,faPiedPiperPp,faPage4,faContao,faWpforms,faHireAHelper,faShareSquare )


var VueScrollTo = require('vue-scrollto');
window.$ = window.jQuery = jQuery

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueScrollTo);
Vue.use(VueAwesomeSwiper);
swiper.use([Navigation, Pagination, Autoplay])
Vue.use(VueStepWizard);
Vue.use(Vuelidate);


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
