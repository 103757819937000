<template>
  <div class="Main">
    <section class="hero-inne">
      <Intro />
    </section>
    <section class="bg about">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-12 img-1"></div>
          <div class="col-lg-7 col-md-7 col-sm-12 d-flex align-items-center img-2">
            <div class="textbox">
              <h2 class="tbox-title">關於我們</h2>
              <p class="tbox-p">
                國華女人徵信有專業優良的女性徵信專員，擁有優秀的專案處理能力，提供婚前徵信、離婚法規諮詢、外遇蒐證、專業捉姦、家暴防治處理、台灣大陸兩案三地徵信、工商徵信、專案尋人等服務。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-1 service">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <h2 class="title">服務項目</h2>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row row-cols-lg-3 row-cols-md-2 row-cols-1 g-4">
              <div class="col">
                <router-link to="/Service">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=1" alt="" :style="image1">
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">外遇調查</h5>
                        <p class="card-text">針對被查人進行各項分析、調查，並與客戶討論商量，以達到確認被查人是否有不忠誠之行徑。</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=2" alt="" :style="image1">
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">外遇蒐證</h5>
                        <p class="card-text">針對其被查人實施各項掌控，並運用各項器材（錄音、錄影、拍照 存證）實施蒐證工作。</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=3" alt="" :style="image1">
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">大陸及海外案件</h5>
                        <p class="card-text">對於大陸二奶問題特別重視，針對台商問題除有專業人員派遣之外，並與當地的業者配合加上精密的蒐證儀器，針對案情無往不利，所有的姦情絕對讓您瞭若指掌，並且及早預防。</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=4" alt="" :style="image1">
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">外遇抓姦</h5>
                        <p class="card-text">您掌握了另一半不忠誠的實證了嗎？有把握讓自己是站在不吃虧的那邊嗎？我們可以幫助你對第三者和平勸離，或是決定還給自己一個公道。</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=5" alt="" :style="image1">
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">家庭暴力</h5>
                        <p class="card-text">情人?還是朋友?我們究竟能不能繼續走下去？當您還在猶豫的時候，傷害可能不是只有加諸在您的身上，我們可以幫助你；或是對第三者的和平勸離，避免極端憾事發生，讓感情有圓滿的結局。</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=6" alt="" :style="image1">
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">婚前徵信</h5>
                        <p class="card-text">你真的了解你身邊的另一半？婚前徵信的重要性更甚於健康檢查！您所選的對象真的值得您託負終生嗎?</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=7" alt="" :style="image1">
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">婚姻諮詢</h5>
                        <p class="card-text">國華女人徵信一直致力於協助公私立機關團體、社區、學校推廣幸家庭講座，例如：兩性關係、婚前教育、婚姻與家庭生活...溝通、互動與危機調適、人際關係強化的服務，因此有關於兩性婚姻等問題，歡迎網友線上諮詢我們竭誠為您服務。</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=8" alt="" :style="image1">
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">專案尋人查址</h5>
                        <p class="card-text">您是否有多年不見的朋友因久未聯絡而找不到人，或是因有金錢糾紛而避而不見面，或是另一半與你吵架而離家出走。交給我們來處理吧！</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/Service">
                  <div class="card-box animated2">
                    <div class="card-img">
                      <img src="https://picsum.photos/300/200?random=9" alt="" :style="image1">
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">免費法律諮詢</h5>
                        <p class="card-text">如果這是你所不了解的? 就讓我們來提供最專業的服務吧！協助您、幫助您！法律顧問提供各種您不確定的民法、刑法諮詢。重要的是......這是免費的唷！！</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="link-box d-flex justify-content-center mb-4">
              <router-link to="/Service">
                <button class="learn-more">
                  <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">Learn More</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-2 column">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <h2 class="title2">徵信新聞</h2>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row row-cols-lg-3 row-cols-md-2 row-cols-1 g-4">
              <div class="col">
                <router-link to="/News">
                  <div class="card-box animated">
                    <div class="card-img2">
                      <div class="card-conect d-flex align-items-center">
                        <font-awesome-icon :icon="[ 'fab', 'bimobject' ]" style="font-size:50px;"  />
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">夫外遇偷生女嬰 妻怒丟包</h5>
                        <p class="card-text">壹蘋果網路 2008/09/17 外遇私生女成無辜棄嬰！二林鎮喜樂保育院門口日前出現女棄嬰...</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/News">
                  <div class="card-box animated">
                    <div class="card-img2">
                      <div class="card-conect d-flex align-items-center">
                        <font-awesome-icon :icon="[ 'fab', 'pied-piper-pp' ]" style="font-size:51px;" />
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">竊聽器捉姦 未觸妨害秘密</h5>
                        <p class="card-text">自由時報 2008/08/14 桃園縣賴姓男子與徐姓情婦92年間通姦生子被判刑，卻持續交往，馮姓妻子...</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/News">
                  <div class="card-box animated">
                    <div class="card-img2">
                      <div class="card-conect d-flex align-items-center">
                        <font-awesome-icon :icon="[ 'fab', 'page4' ]" style="font-size:46px;" />
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title"> 醋男持槍 轟女友老闆大腿</h5>
                        <p class="card-text">壹蘋果網路 2008/09/15 台北縣一名男子，不滿女友當傳播妹，雙方多次為此發生口角，女友還...</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/News">
                  <div class="card-box animated">
                    <div class="card-img2">
                      <div class="card-conect d-flex align-items-center">
                        <font-awesome-icon :icon="[ 'fab', 'contao' ]" style="font-size:45px;" />
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title"> 廚工保飯碗 替老闆娶二奶</h5>
                        <p class="card-text">壹蘋果網路 2008/08/02 一名在團膳公司幫廚的男子，今年元月以假結婚方式幫老闆娶回越南籍...</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/News">
                  <div class="card-box animated">
                    <div class="card-img2">
                      <div class="card-conect d-flex align-items-center">
                        <font-awesome-icon :icon="[ 'fab', 'wpforms' ]" style="font-size:50px;" />
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">欠債綁架姪孫 老公氣到離婚</h5>
                        <p class="card-text">中央新聞社 2008/09/09 台北縣連姓婦人向地下錢莊借錢賭六合彩，利滾利積欠5、600萬元債...</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link to="/News">
                  <div class="card-box animated">
                    <div class="card-img2">
                      <div class="card-conect d-flex align-items-center">
                        <font-awesome-icon :icon="[ 'fab', 'hire-a-helper' ]" style="font-size:46px;" />
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">印尼看護外遇產女 殺嬰藏屍</h5>
                        <p class="card-text">中時電子報 2008/08/19 印尼籍看護女工桑達麗，十六日在三重雇主家產下女嬰，她怕曝光遭...</p>
                        <div class="btn-more">
                          <button class="btn-more-link">MORE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="link-box d-flex justify-content-center mb-4">
              <router-link to="/News">
                <div class="btn-contain">
                  <div class="btn-skew-contain">
                      <span class="btn-skew">Learn More</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Intro from "@/components/Intro.vue";
// import ServiceType from '@/components/ServiceType.vue'

export default {
  name: "Main",
  data() {
    return {
      image1: {
        width:'150px',
        height:'150px',
      }
    };
  },
  components: {
    Intro,
    // ServiceType
  },
};
</script>

<style scoped>
a:hover{
  text-decoration: none;
  color: black;
}
.bg {
  background: rgb(255, 222, 89);
}
.img-1 {
  background-image: url(../assets/images/about.jpg);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  padding-bottom: 50%;
}
.img-2{
  display: none;
}
.textbox{
  width: 100%;
  height: 100%;
  position: relative;
}
.tbox-title{
  position: absolute;
  right: 0;
  top: 40%;
  width: 80%;
  background: white;
  text-align: left;
  padding: 10px 0px 10px 15px;
  font-weight: 600;
}
.tbox-title::before{
  content: '';
  position: absolute;
  top: 0;
  right: -15px;
  background: white;
  width: 2vw;
  height: 58px;
}
.tbox-p{
  position: absolute;
  right: 0;
  top: 53%;
  width: 80%;
}
.bg-1 {
  background:  rgb(255, 222, 89);
}
.title{
  background: black;
  width: 100%;
  color: rgb(255, 222, 89);
  text-align: center;
  padding: 1rem 0px;
  margin-bottom: 30px;
}
.card-box{
  position: relative;
  height: 100%;
  /* margin-bottom: 25px; */
}
.card-img{
  position: absolute;
  top: 0;
  left: 31.333333%;
  margin: 0 auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: rgb(255, 222, 89);
  z-index: 5;
}
.card-img img{
  border-radius: 50%;
}
.card-img2{
  position: absolute;
  top: 0;
  left: 31.333333%;
  margin: 0 auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid teal;
  background: rgb(255, 222, 89);
  z-index: 5;
}
.card{
  position: initial;
  background: transparent;
  border: 0px;
  z-index: -1 !important;
  padding-top: 75px;
  height: 100%;
  padding-bottom: 30px;
}
.card-body {
  text-align: center;
  background: white;
  position: relative;
}
.card-title{
  margin-top: 75px;
  font-weight: 600;
  font-size: 25px;
}
.card-text{
  text-align: left;
  padding-bottom: 15px !important;
}
.bg-2 {
  background: black;
}
.title2{
  background:rgb(255, 240, 89);
  width: 100%;
  color: black;
  text-align: center;
  padding: 1rem 0px;
  margin-bottom: 30px;
}
.card-conect{
  margin: 0 auto;
  height: 100%;
  width: 30%;
}
button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
button.learn-more {
  width: 12rem;
  height: auto;
}
button.learn-more .circle {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #282936;
  border-radius: 1.625rem;
}
button.learn-more .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
button.learn-more .circle .icon.arrow {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
button.learn-more .button-text {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}
button:hover .circle {
  width: 100%;
}
button:hover .circle .icon.arrow {
  background: #fff;
  -webkit-transform: translate(1rem, 0);
          transform: translate(1rem, 0);
}
button:hover .button-text {
  color: #fff;
}
.btn-contain {
  margin: 0 auto;
}
.btn-skew-contain {
  max-width: 270px;
  margin: 0 auto;
}
.btn-skew {
  font-family:sans-serif;
  font-weight:bold;
  text-align: center;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.85rem 20px;
  border: 2px solid #f7f7f7;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  -webkit-transition: .3s;
  transition: .3s;
  display:block;
  border-radius: 5px;
  z-index: 2;
}
.btn-skew:after {
  position: absolute;
  -webkit-transition: .3s;
  transition: .3s;
  content: '';
  width: 0;
  bottom: 0;
  background: #f7f7f7;
  height: 120%;
  left: -10%;
  -webkit-transform: skewX(15deg);
  transform: skewX(15deg);
  z-index: -1;
}
.btn-skew:hover {
  cursor: pointer;
}
.btn-skew:hover:after {
  width: 100%;
  left: 0;
}
.btn-skew:hover {
  color: black;
}
.btn-skew:hover:after {
  left: -10%;
  width: 120%;
}
.animated:hover .card-img2{
  -webkit-transition: .6s all;
  transition: .6s all;
  background-color: rgb(228, 220, 220);
  border-color: #99CC00;
}
.animated:hover .svg-inline--fa {
  -webkit-transition: .6s all;
  transition: .6s all;
  -wekbkit-transform: scale(1.5);
  transform: scale(1.5);
}
.btn-more{
  position: absolute;
  right: 45px;
  bottom: 1.25rem;
}
.btn-more-link{
  position: relative;
  padding-right: 25px;
}
.btn-more-link::before{
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: url(../assets/images/arrow-right.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 24px;
  height: 24px;
}
.animated:hover .btn-more-link::before ,.animated:hover .btn-more-link{
  -webkit-transition: .6s all;
  transition: .6s all;
  letter-spacing: 1.5px;
  right: -8px;
  color: blueviolet;
}
.animated:hover .btn-more-link::before{
  filter: invert(100%) sepia(158%) saturate(2974%) hue-rotate(251deg) brightness(65%) contrast(70%);
}
.card-img img{
  -webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
.animated2:hover .card-img img{
  -webkit-filter: grayscale(0%);
	filter: grayscale(0%);
  box-shadow: 0px 5px 5px black;
  transform: scale(1.15);
	-webkit-transition: .6s ease-in-out;
	transition: .6s ease-in-out;
}
.animated2:hover .btn-more-link::before,.animated2:hover .btn-more-link{
  -webkit-transition: .6s all;
  transition: .6s all;
  letter-spacing: 1.5px;
  right: -8px;
  color: blueviolet;
}
.animated2:hover .btn-more-link::before{
  filter: invert(100%) sepia(158%) saturate(2974%) hue-rotate(251deg) brightness(65%) contrast(70%);
}

@media (max-width: 1025px) {
  .card-img{
    left: 25.333333%;
  }
  .card-img2{
    left: 25.333333%;
  }
  .img-1{
    padding-bottom: 80%;
  }
}
@media (max-width: 911px) {
  .card-img{
    left: 28.333333%;
  }
  .card-img2{
    left: 28.333333%;
  }
}
@media (max-width: 767px) {
  .img-1{
    display: none;
  }
  .img-2 {
    display: block;
    background-image: url(../assets/images/about.png);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color:transparent !important;
    padding: 60% 0;
  }
  .textbox{
    height: 40vh;
  }
  .tbox-title{
    top: 20%;
  }
  .tbox-title::before{
    width: 0vw;
    height: 58px;
  }
}
@media (max-width: 415px) {
  .card-img{
    left: 30.333333%;
  }
  .card-img2{
    left: 30.333333%;
  }
  .tbox-p{
    top: 44%;
  }
}
@media (max-width: 376px) {
  .card-img{
    left: 27.333333%;
  }
  .card-img2{
    left: 27.333333%;
  }
}
@media (max-width: 322px) {
  .card-img{
    left: 23.333333%;
  }
  .card-img2{
    left: 23.333333%;
  }
  .textbox{
    height: 50vh;
  }
  .tbox-title{
    width: 85%;
  }
  .tbox-p{
    top: 46%;
    right: 16px;
  }
}
</style>
