<template>
<div class="BackTop">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="postion">
                    <a href="tel:0800363333" class="img_phone" id="FloatPhone" v-show="showIcon" >
                        <img src="../assets/images/telephone-fill.svg"/>
                    </a>
                    <a href="https://line.me/ti/p/0L61m6O3cJ" target="_blank" class="img_line" id="Floatline" v-show="showIcon" >
                        <img src="../assets/images/line.svg"/>
                    </a>
                    <div id="connectUsImg" class="img_box" v-show="showIcon" @click="backTop()">
                        <img src="../assets/images/chevron-bar-up.svg" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "connectUsImg",
    data() {
        return {
        showIcon: false
        };
    },
    mounted() {
        window.addEventListener('scroll', this.scrollBackTop);
    },
    methods: {
        backTop () {
            const that = this
            let timer = setInterval(() => {
                let ispeed = Math.floor(-that.scrollTop / 5);
                    document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed;
                    if (that.scrollTop === 0) {
                    clearInterval(timer);
                }
            }, 16)
        },
        scrollBackTop () {
            const that = this;
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            that.scrollTop = scrollTop;
            if (that.scrollTop > 60) {
            that.showIcon = true;
            } else {
            that.showIcon = false;
            }
        }
    }, 
    destroyed () {
    window.removeEventListener('scroll', this.scrollBackTop)
    }
};
</script>

<style scoped>
.postion{
    position: fixed;
    right: 2%;
    bottom: 2%;
    z-index: 999;
}
.img_phone img{
    width:40px;
    height:40px;
    z-index:999;
    padding: 0.5rem;
    border-radius: 10%;
    background: whitesmoke;
    margin-bottom: 8px;
}
.img_phone{
    background-color: transparent;
    display: block;
}
.img_line img{
    width:40px;
    height:40px;
    z-index:999;
    padding: 0.3rem;
    border-radius: 10%;
    background: whitesmoke;
    margin-bottom: 8px;
}
.img_line{
    background-color: transparent;
    display: block;
}
.img_box img{
    width:40px;
    height:40px;
    z-index:999;
    border-radius: 10%;
    background: whitesmoke;
}
.img_box{
    background-color: transparent;
    display: block;
}
.img_box:hover img{
    cursor: pointer;
    background-color: sandybrown;
}
.img_phone:hover img{
    cursor: pointer;
    background-color: sandybrown;
}
.img_line:hover img{
    cursor: pointer;
    background-color: sandybrown;
}
</style>