<template>
  <div class="Footer">
    <div class="footer-inner">
      <div class="container-fluid">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="contact">
              <div class="contact-box">
                <h2>CONTACT</h2>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="right-box py-3">
              <h5>聯絡電話：</h5>
              <p class="mb-2">
                全台24小時服務專線：<a
                  href="tel:0800363333"
                  class="phone"                  
                  >0800-36-3333</a
                >
              </p>
              <p style="margin-bottom:0px;">
                  Copyright © 2021 All rights reserved.
                  <br>
                  國華女人徵信有限公司 Woman-Care Detect Co., Ltd.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>

<style scoped>
.footer-inner {
  margin: 0 auto;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  background-image: url(../assets/images/footer.png);
  background-position: 50% 20%;
  background-size: cover;
  background-repeat: no-repeat;
}
.contact {
  text-align: left;
  color: white;
  padding: 100px 0px;
}
.contact-box{
  background: rgb(255, 222, 89);
  width: 80%;
  position: relative;
  padding: 5px 0px;
}
.contact-box::before{
  content: '';
  background: rgb(255, 222, 89);
  position: absolute;
  top: 0;
  left: -15px;
  width: 15%;
  height: 100%;
}
.contact-box h2 {
  font-size: 3rem;
  text-align: right;
  padding-right: 15px;
  margin-bottom: 0;
  color: black;
}
.right-box h5{
  color: rgb(255, 222, 89);
}
.right-box p{
  color: white;
}
.phone {
  text-decoration: none;
  padding: 0;
  font-size: 22px;
  color: rgb(255, 222, 89);
}
.phone:hover {
  color: blue;
}
@media(max-width:1025px){
  .phone {
    display: block;
  }
}
@media(max-width:767px){
  .contact {
    padding: 40px 0px;
  }
}
@media(max-width:322px){
  .contact-box{
    width: 90%;
  }
  .contact-box::before{
    width: 7%;
  }
}
</style>
