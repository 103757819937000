<template>
  <div class="News">
    <div class="hero">
      <div class="container-fluid">
        <div class="row" style="position: relative;">
          <div class="hero-box">
            <div class="hero">
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="" style="background: #f5f4f0;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="warp">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="item-content">
                <div class="item">
                  <div class="scroll-btn">
                      <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -80,duration: 1500}">
                        <div>
                            <span class="down-arrow-1"></span>
                            <span class="down-arrow-2"></span>
                            <span class="down-arrow-3"></span>
                        </div>
                      </div>
                  </div>
                  <div class="row justify-content-center item-t1">
                    <div class="col-12">
                      <h2 class="lineder mb-5" id="element">徵信新聞</h2>
                    </div>
                    <div class="col-12">
                        <div class="accordion" role="tablist">
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-1 variant=""
                                    >夫外遇偷生女嬰&nbsp;妻怒丟包</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-1"
                                visible
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路2008/09/17&nbsp;孫英哲╱彰化報導</h4>
                                        <p>
                                            &emsp;&emsp;外遇私生女成無辜棄嬰！二林鎮喜樂保育院門口日前出現女棄嬰，警方據報調查發現，丟棄女嬰的女子是因不滿丈夫外遇生女，還將女嬰帶回家要她養，「我無法心甘情願照顧她，也不希望女嬰人格發展受負面影響。」警方昨將女子依遺棄罪嫌函送法辦。<br><br>
                                            &emsp;&emsp;警方調查，本月十二日上午九時許，收容身心障礙者的喜樂保育院突然接到女子來電，指稱該院側門紙箱裡有名一個多月大的女嬰，請代為照顧；院方派員查看，發現一名女嬰，身旁有奶瓶、奶粉及尿布等物，懷中的信寫著，因家貧不得已要將女嬰送人收養。<br><br>
                                            <span style="font-weight:600;">無法心甘情願</span><br>
                                            &emsp;&emsp;由於該院並非棄嬰留置處所，因此通報轄區，警方根據路口監視器，發現打電話的女子，就是棄置女嬰的人，並從其駕駛轎車查出是埤頭鄉的三十歲柯姓女子。柯女 向警方供稱，女嬰是丈夫外遇所生，她曾一度要告對方妨害家庭，卻因心軟而作罷，沒想到丈夫竟將情婦生的女兒帶回家，還要她照顧。她實在無法心甘情願照顧女 嬰才予棄置，並騙丈夫女嬰已被領養。女嬰由彰化縣社會處暫時安置，近日將交給祖母照顧。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-2 variant=""
                                    >黑人教英文&nbsp;騙乾母女陪睡&nbsp;少女買驗孕棒揭發&nbsp;警嘆：長這樣都能騙</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-2"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路2008/09/17&nbsp;楊勝裕╱新竹報導</h4>
                                        <p>
                                            &emsp;&emsp;外新竹市警方日前破獲一起奈及利亞人組成的詐騙集團，結果意外發現其中一名成員雖其貌不揚，但卻以流利英文結交不少女友，其中一名熟女 竟甘願為他買房，賺錢供他吃住；但這名老外仍不知足，竟連熟女的十五歲乾女兒都以教導「身體英文」哄騙上床，最後少女因驗孕棒被母親發現，事件才曝光，承 辦警員不禁搖頭說：「都是崇洋心態所害！長這樣都能來台灣騙財騙色。」<br><br>
                                            <span style="font-weight:600;">大小通吃</span><br>
                                            &emsp;&emsp;檢警調查，今年初以商務簽證到台的Sunday Amos（二十八歲），雖然長得不怎麼樣，卻憑著滿嘴流利的英文，在南部藉口教英文，結識三十八歲的蘇姓女貿易商，事後兩人陷入熱戀，蘇女還買房子共築愛巢，並賺錢供對方花用。<br><br>
                                            <span style="font-weight:600;">佯稱教「身體英文」</span><br>
                                            &emsp;&emsp;由於蘇女認了鄰居一名十五歲、亭亭玉立的少女為乾女兒，少女常到乾媽家玩，因此結識乾媽男友。由於Amos滿嘴英文，國三的十五歲少女為了想讓英文溜一些，常透過與對方英文對話，學習英語。 四月某日少女找上門，Amos見女友不在家，當天竟以教少女身體器官的英文為由，哄騙少女脫下衣褲，進而發生性關係。事後少女及Amos私底下還瞞著乾媽，甘願當男子的小女友。<br>
                                            &emsp;&emsp;由於少女暗中多次與對方發生關係，少女害怕懷孕，買了驗孕棒放在家裡抽屜檢驗，未料遭到母親發現追問，少女才坦承與Amos發生性關係，令少女母親憤怒不 已，向鄰居蘇女告狀，並帶到醫院驗傷，且怒告對方性侵。蘇女得知男友竟背著她，對十五歲少女下手性侵，大小通吃，氣得將對方趕出門。<br>
                                            &emsp;&emsp;由於Amos與另兩名奈籍男子以買賣中古機車到非洲為由，詐騙一名在竹科上班的菲籍女子，因此上個月中旬遭到逮捕。少女家人從新聞報導看到狼蹤，立即帶著少女到新竹市警局指認。<br><br>
                                            <span style="font-weight:600;">被逮狡辯不懂法律</span><br>
                                            &emsp;&emsp;被收容在新竹外國人收容中心的Amos昨天被請到警局說明案情，極力否認性侵少女，僅說是兩情相悅發生性關係，且辯稱自己不了解台灣法律，不知與未成年少女發生性行為是犯法，但警方訊後仍依妨害性自主罪嫌將他送辦，並呼籲其他受害女子出面指認，以利全案偵辦。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-3 variant=""
                                    >職業狐狸精正夯&nbsp;助妻離婚分財產</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-3"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;大陸新聞中心／綜合報導&nbsp;2008/09/16</h4>
                                        <p>
                                            &emsp;&emsp;別懷疑，狐狸精也是一種職業。「職業狐狸精」是重慶的新興行業，工作內容就是發揮媚功設下騙局，讓妻子掌握花心老公的外遇把柄，等到離婚時就能夠多分一點財產。雖然聽起來不是很入流，不過「職業狐狸精」按件計費，一個案子收費從3萬5千元起跳，據說生意非常的好。<br><br>
                                            &emsp;&emsp;據悉，負責統籌「職業狐狸精」的王先生表示，雇主要先付一半的訂金，收費通常從8千元到3萬元人民幣不等，狐狸精的姿色如果出眾，或是手腕高明，收費還可以多增加兩成。<br><br>
                                            &emsp;&emsp;據報導，「職業狐狸精」的來源從兼差的女學生或是家庭主婦都有，通常會僱用狐狸精的案主都是老闆太太。王先生表示，要達成任務的時間不等，有的人3天就可以結案，慢一點也要花到1個月。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-5 variant=""
                                    >夫一周喝酒5天&nbsp;妻訴請離婚獲准</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-5"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;今日新聞&nbsp;2008/09/16</h4>
                                        <p>
                                            &emsp;&emsp;彰化縣田尾鄉一名林姓男子長期酗酒，一周起碼要喝上5天，酒後即大吵大鬧並施以暴力，雖曾立下悔過書，但仍惡習難改，他的妻子受不了提出離婚要求，法官認定婚姻關係出現重大破綻，已難以再修復，15日判准雙方離婚。
                                            &emsp;&emsp;林妻向法官表示，她與先生結婚17年，剛開始感情還不錯，但慢慢發現雙方在許多事情有認知上的差異，後來先生又染上酗酒惡習，一個禮拜起碼要喝上5天，每月所得全部拿去買酒，喝不夠還拿家中金飾變賣。
                                            &emsp;&emsp;林妻還說，先生常酒後亂性，把原本美滿的家庭搞得家不像家，連小孩說要繳學費，還氣得賞兒子一巴掌，後來在村長和長輩出面協調下，立悔過書宣告戒除酒癮，不料仍惡習難改，三杯黃湯下肚吵鬧不止，她對先生已經不抱任何希望。
                                            &emsp;&emsp;法官認為，夫妻共同生活，應以誠摯互信為基礎，林男有長期酗酒的嗜好，且有向對方施以暴力的行為，婚姻關係已難以再修復，故准予雙方離婚。至於夫妻倆兒子的監護權，法官考量被告有酗酒惡習，不足以作為孩子身教的典範，為了子女著想，交由母親實行監護權。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-6 variant=""
                                    >報復外遇&nbsp;妻誘老公同事上床</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-6"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;自由時報&nbsp;2008/09/16&nbsp;記者蔡彰盛／竹市報導</h4>
                                        <p>
                                            <span style="font-weight:600;">報復先生外遇，搭上先生同事，還南下200公里上床！</span><br>
                                            &emsp;&emsp;新竹市林姓女子在南科工作的先生外遇，她為了報復，竟多次南下200公里，找先生同事兼好友徐某到汽車旅館姦宿，林女事後發曖昧簡訊，被林女先生發現提告，徐某被依通姦罪判徒刑6月，合併執行有期徒刑5月。<br><br>
                                            <span style="font-weight:600;">丈夫偷情 妻決「各玩各的」</span><br>
                                            &emsp;&emsp;判決書指出，林女先生曾某與被告徐某不僅熟識，而且是科技公司同事，兩人同時從竹科被調往南科工作。去年9月間，林女懷疑先生有外遇，因而心情不佳，卻給了徐某可乘之機。<br>
                                            &emsp;&emsp;徐某向法官供稱，本來跟林女根本沒有聯繫，是因為曾某在外面有女人，自己也在台南，林女要求代為打聽，不到3個禮拜，林女說奈何不了先生，所以就「各玩各的」。<br><br>
                                            <span style="font-weight:600;">女南下200公里 找郎開房間</span><br>
                                            &emsp;&emsp;令法官咋舌的是，林女多次要徐介紹一個「男友」給她，這時徐某竟見獵心喜地說「那就我好了！」之後幾天林女就往南奔200公里找徐，前往台南縣永康市、仁德鄉等地汽車賓館連開3次房間，每次平均相隔1個星期。<br>
                                            &emsp;&emsp;徐某說，這3次姦宿都是林女在電話中邀約後，才從新竹下來台南，不過第一次發生後，他跟林女說「我們不應該這樣子。」可是林女卻說，她老公在外面有女人，她也要報復他，「大家各玩各的！」 徐某說，自己原本想叫停，然因林女又一直傳簡訊，徐禁不起她的誘惑，所以才接二連三地再發生關係。<br><br>
                                            <span style="font-weight:600;">曖昧簡訊露餡 男挨告判刑</span><br>
                                            &emsp;&emsp;去年10月1日，徐某妻子發現徐的手機內有一則林女所發送內容曖昧的簡訊「我好想要，你能再給我一次嗎？」由於兩家是多年好友，起疑的徐妻乃委請曾某查詢，曾某調閱通聯紀錄及詢問妻子後，始知上情，曾某選擇原諒妻子，對徐某提出妨害家庭告訴。<br>
                                            &emsp;&emsp;合議庭法官認為，被告徐某和告訴人曾某為舊識，竟與曾某妻子相姦，妨害告訴人家庭生活美滿，因此連續判他3次通姦罪，每次徒刑2月，最後應合併執行有期徒刑5月。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-7 variant=""
                                    >醋男持槍&nbsp;轟女友老闆大腿</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-7"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路&nbsp;2008/09/15&nbsp;謝東明╱台北報導</h4>
                                        <p>
                                            &emsp;&emsp;台北縣一名男子，不滿女友當傳播妹，雙方多次為此發生口角，女友還搬到傳播公司老闆住處，更讓男子氣憤吃醋。 這名男子三天前，帶著自製的鐵管霰彈槍，埋伏在女友老闆的住處，趁著對方出門時，開槍打傷其大腿，逃亡三天後終於在昨天落網。<br><br>
                                            <span style="font-weight:600;">拿自製鐵管霰彈槍</span><br>
                                            &emsp;&emsp;警方調查，嫌犯黃冠瑋（二十五歲，有槍砲、毒品、妨害自由等前科）今年剛出獄，上月涉及毒品案，如今又犯下槍擊案。黃嫌的女友從事傳播妹工作，他多次要求女友換工作遭拒，女友還搬到方姓老闆住處，他前往找人時，反遭方男嗆聲：「別騷擾員工！」<br>
                                            &emsp;&emsp;三天前凌晨，黃嫌拿著自製的鐵管霰彈槍，駕車埋伏在中和市民德街方男住處附近，見方男騎機車外出時，立刻朝方男右大腿開槍，並迅速開車逃逸，方男送醫後所幸沒有大礙。<br>
                                            &emsp;&emsp;警方追查發現，黃嫌常在板橋市四川路出沒，昨天凌晨三時在附近停車場將他逮捕，還有另名藏毒共犯也一併到案。警方稍後起獲犯案用的霰彈槍及三發制式子彈，偵訊後已依殺人未遂、槍砲、毒品等罪嫌移送法辦。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-8 variant=""
                                    >女控乾哥強姦&nbsp;還拍裸照威脅</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-8"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路&nbsp;2008/09/15&nbsp;蕭夙眉╱台中報導</h4>
                                        <p>
                                            台中縣一名女子控告乾哥哥林姓男子以散布裸照威脅，近一年強迫與她發生性行為七次，男方在偵訊時堅稱雙方是你情我願，但承認確以手機拍下對方裸照，不過拍攝前經乾妹同意，後因對方覺得噁心已刪除。雙方各執一詞，警方昨仍依妨害性自主罪嫌將林男送辦。<br><br>
                                            <span style="font-weight:600;">後遭淫6次忍無可忍</span><br>
                                            &emsp;&emsp;警方調查，嫌犯現年三十多歲，其乾妹年齡約小他十歲，兩人在年幼時即以乾哥、乾妹相稱，後因求學等因素，雙方約十年未聯繫，直到去年七月，女子打電話給林男要求借電腦，雙方才再度碰面。 女子日前向警方報案，控訴遭乾哥性侵。女子表示，去年七月，她去乾哥家用電腦，卻遭性侵，且林男用手機拍下其裸照，陸續以散布照片要脅，又對她性侵六次，她忍無可忍，決定報警。<br>
                                            &emsp;&emsp;警方日前傳訊林男，他說：「去年乾妹到我家，兩人是在你情我願下發生性關係，我有替她拍照，但給她看過之後，她認為噁心，我就刪除，接下來發生幾次性關係，我都沒強迫她！」警方檢視林男手機，其中確無女子裸照，除將林男送辦，並呼籲女性若遭威脅性侵，應立刻採集或保留相關跡證報案。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-9 variant=""
                                    >不滿分手！&nbsp;男砍女友刀刃留體內</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-9"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;TVBS&nbsp;2008/09/12</h4>
                                        <p>
                                            &emsp;&emsp;桃園一對男女朋友因為感情生變，談判分手時男方心有不甘，竟然拿出水果刀往女友背上猛刺，甚至因為太用力了，刀刃斷掉留在女友體內，被人報警送醫，幸好動過手術把刀刃取出來，女子暫時沒有生命危險，而殺人的男子隨後投案，被移送法辦。<br><br>
                                            &emsp;&emsp;女子坐在醫院病床一臉痛苦表情，和男友談判分手不成，還被對方拿水果刀刺傷全身，背後大片血跡，剛送到醫院時情況相當危急。<br><br>
                                            &emsp;&emsp;警方：「他就亂揮，先劃傷她左手背，再朝她胸口刺傷，刺到右肩，她一縮，痛就一縮，就插到她背上，原本先送署桃，署桃說傷口太深不敢開，所以就轉長庚。」<br><br>
                                            &emsp;&emsp;交往同居4年的這對男女朋友，疑似因為債務壓力加上感情生變，女方約男方到這間汽車旅館談分手，還寫下切結書還給男方10萬元，沒想到男方不甘心分手，拿出預藏的水果刀往女友身上猛刺，還因為太用力，刀柄斷掉，長達6公分的刀刃就留在女子體內。<br><br>
                                            &emsp;&emsp;女子受傷倒地，陪同前往談判的朋友報警送醫，幸好留在體內的刀刃沒有傷到內臟，開刀取出來後，女子暫時沒有生命危險，而殺人男子隨後投案，他說因為太愛對方才會鑄下大錯，最後還是被依殺人罪移送法辦。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-10 variant=""
                                    >未如期訂婚&nbsp;懷恨潑女友父兄硫酸&nbsp;判刑一年四月</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-10"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;中時電子報&nbsp;2008/09/12</h4>
                                        <p>
                                            &emsp;&emsp;男子范嫌因故未能與論及婚嫁的女友如期訂婚，懷恨在心，先打電話到女方家恐嚇，恫嚇女方兄嫂「要給你家人好看」，繼而拿水槍裝硫酸，接連對女友哥哥、父親噴灑，造成臉、手、腳等處嚴重灼傷，最高法院十一日駁回上訴，判刑一年四月確定。<br><br>
                                            &emsp;&emsp;范嫌（四十六歲）與呂姓女子原已論及婚嫁，後來因女方家人反對等原因，呂女提議分手，兩人分手後，范嫌懷在心，從八十八年六月至九十年二月間，連續打電 話到呂女兄長、大嫂家中或上班地點，恐嚇聲稱「你跟你家人要小心」、「要給你家人好看」、「下班注意一點，我要在門口等」等語，令呂女兄嫂心生畏懼。<br><br>
                                            &emsp;&emsp;言語恐嚇後，范某仍未解其心頭之恨，九十年一月十一日傍晚六點多，竟拿水槍裝硫酸，埋伏在呂女大哥上班的公司角落，待呂兄出現後，即朝呂兄頭、臉等部位噴灑，造成呂兄左臉、左耳、頸、左大腿等處化學性灼傷，所幸及時用水清洗，未造成永久性傷害。<br><br>
                                            &emsp;&emsp;同年二月二日清晨七點左右，范嫌到呂父任職大樓管理員的管理室，朝正在執勤的呂父噴灑硫酸，造成呂父顏面、頸部、雙手等處二至三度灼傷，亦因及時清洗，未造成毀容。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-11 variant=""
                                    >疑妻外遇&nbsp;告訴兒：我殺了你媽</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-11"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;中時電子報&nbsp;2008/09/11</h4>
                                        <p>
                                            &emsp;&emsp;卓姓男子懷疑妻子陳女外遇，抓姦不成後兩人發生爭吵，十日趁妻子熟睡時，他以亂刀砍死妻子；此時就讀小三的兒子返家，卓某親口告訴兒子「我殺了媽媽」，並要兒子打電話通知伯父，並企圖服藥自殺，送醫後已無生命危險。<br><br>
                                            &emsp;&emsp;卓男（卅四歲）的三哥聞訊趕到弟弟、弟媳陳女（卅二歲）位於台中縣大安鄉租屋處。他說，曾聽弟弟提過懷疑妻子外遇，前晚跑去抓姦未果，兩人回家後發生嚴重爭吵。<br><br>
                                            <span style="font-weight:600;">行凶後服藥自盡獲救</span><br>
                                            &emsp;&emsp;三哥說，昨天中午一時許，接到姪子電話說「爸爸殺了媽媽」，他趕緊報警，到場時發現弟弟已服藥半躺在沙發上，意識不清，被送到大甲光田醫院救治。<br>
                                            &emsp;&emsp;院方表示，卓某左胸及右手分別有長一．五、一公分撕裂傷，昏迷指數約十，喉嚨有灼傷痕跡，目前仍無法辨識他到底喝了什麼，經初步催吐、治療後，已將他留置加護病房觀察。警方表示，陳女頸部、胸部分別有刀傷，致命原因仍待解剖釐清。<br><br>
                                            &emsp;&emsp;警方指出，陳女陳屍床邊的地板，頭部以棉被蓋住，腋下還夾著把刀，血跡斑斑；現場發現一把菜刀、水果刀及一把彎曲的削皮刀，還需檢驗何者為兇刀。<br><br>
                                            <span style="font-weight:600;">兒放學知母死&nbsp;臉色發白</span><br>
                                            &emsp;&emsp;陳父氣憤說，女婿十多年前因工作意外墜樓，腿部受傷、行動不便，無固定工作，又愛喝酒，連房租都快付不出來，害得女兒要去幫人剝雞皮賺生活費。<br><br>
                                            &emsp;&emsp;陳父說，前天傍晚約五點多，女兒下班後回娘家吃晚飯，提到工作很累、全身酸軟，隔天不想去上班要去網咖，當時相當正常，根本沒料到會發生這種事，也未聽過女兒有外遇。<br><br>
                                            &emsp;&emsp;鄰居表示，卓姓夫妻搬來僅一年多，鮮少與鄰居打交道，育有十一歲兒子及五歲女兒，事發時女兒已被帶到姑姑家住，倒是大兒子放學回家得知母親被砍死，臉色慘白，令人心疼。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-12 variant=""
                                    >談分手下毒手&nbsp;狠刺劈腿女&nbsp;刀斷兩截</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-12"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;中時電子報&nbsp;2008/09/11</h4>
                                        <p>
                                            &emsp;&emsp;男子王忠順懷疑女友傅美真劈腿，十日竟趁兩人談判分手時，持水果刀猛刺傅女，凶刀甚至斷成兩截，刀片還插在被害人背上，觸目驚心。傅女傷重經緊急轉送長庚醫院急救，目前還在加護病房觀察中。<br><br>
                                            &emsp;&emsp;案發後王忠順躲藏在朋友家中，桃園警方透過家屬策動投案，偵訊時王某不斷喃喃自語說：「我還是很愛她，實在沒辦法割捨這段四年感情。」卅八歲運貨司機王忠順，昨天下午懷疑同居女友傅美真（卅五歲）移情別戀，相約談判分手。<br><br>
                                            &emsp;&emsp;警方調查，傅美真擔心王忠順情緒激動，邀另一名劉姓男子赴約。孰料王忠順一見到對方醋勁大發，就持水果刀瘋狂砍殺傅女。傅美真身上多處刀傷，其中背部一刀更刺進身體十多公分，刀片還插在她背上。<br><br>
                                            &emsp;&emsp;案發後劉姓男子請求附近民眾報警求助，傅女被緊急送往署立桃園醫院急救，因院方無法取出斷刀，只好在轉送林口長庚醫院。警方指出，傅女背部受創甚深，目前還在加護病房觀察中。警方偵訊時，王忠順頻頻表示懊悔，指出自己離婚後獨自帶兒子過活，傅女是他生活的唯一重心，實在是受不了情變打擊，才失手做出傻事。警方訊後已依殺人罪嫌移送。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-13 variant=""
                                    >名教授偷腥&nbsp;女助理判5月&nbsp;前教育部長郭為藩之女&nbsp;活逮老公情婦同床</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-13"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路&nbsp;2008/09/10&nbsp;黎百代、張沛森、王志弘╱綜合報導</h4>
                                        <p>
                                            &emsp;&emsp;中央大學環境工程研究所前教授王鵬堯跟女助理發生婚外情，被妻子捉姦在床，因他拒絕妻子所提兩千萬元贍養費，妻子狀 告他跟女助理妨害家庭。但後來妻子與他離婚並撤告，只堅持告女助理。桃園地院昨依妨害家庭罪判處女助理五月徒刑，可易科罰金。<br><br>
                                            <span style="font-weight:600;">離婚收場</span><br>
                                            &emsp;&emsp;中央大學校方人員昨表示，王鵬堯（四十三歲）是美國哈佛環境衛生博士，主持多項國科會專案研究，在學校頗有名氣，王妻是前教育部長郭為藩的女兒，兩人已在 今年二月五日離婚；而女助理劉文惠（三十六歲）二○○五年考取中央大學中文系碩士在職專班後，再應徵成為王的助理，但王、劉兩人今年七月，以自行創業為 由，分別離開央大。<br><br>
                                            <span style="font-weight:600;">「丟掉工作不值得」</span><br>
                                            &emsp;&emsp;《蘋果》記者昨查訪王鵬堯台南新營老家，沒人在家，王的親戚說：「王會出軌應跟妻子出國唸書有關，因為王當教授很忙，還得獨自帶五歲兒子兩年，難免會空虛、會累，跟女助理有染也是人之常情，卻因此丟掉工作，實在不值得。」<br>
                                            &emsp;&emsp;判決指出，王鵬堯和已離婚的女助理劉文惠因日久生情，前年七月間在王的平鎮住處發生過一次性關係；之後，劉女認為王男已婚，不適合交往，遂有意疏遠。去年十一月間，雙方戀情死灰復燃，每周一次在中壢的汽車旅館發生性關係，王妻因懷疑丈夫外遇，找來至少兩家徵信社跟蹤。<br><br>
                                            <span style="font-weight:600;">妻要求付錢「讓賢」</span><br>
                                            &emsp;&emsp;今年一月十一日晚間七時許，王教授開車載女助理到平鎮市一家汽車旅館，徵信業者通報王妻，當時還因同時有多家徵信業者一起到場，引起騷動，等王妻到場，才 知原來他們追查的是同一對男女。隨後王妻會同警方在旅館內查獲王、劉兩人一絲不掛躺在床上，並查扣兩人使用過的衛生紙，兩人只好承認通姦。<br>
                                            &emsp;&emsp;據當時陪同捉姦的警方描述，王妻曾要求丈夫拿出兩千萬元，她願意離婚「讓賢」，但王教授表示湊不出這麼多錢，王妻才提告。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-14 variant=""
                                    >批扁名律師&nbsp;爆11年畸戀&nbsp;舞女怒控：我為他墮胎4次&nbsp;林憲同喊冤：為她花1500萬</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-14"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路&nbsp;2008/09/10&nbsp;丁牧群、張欽╱台北報導</h4>
                                        <p>
                                            &emsp;&emsp;打扁名律師林憲同捲入桃色糾紛。法界昨爆出林憲同和一名舞小姐張筱梅同居長達十一年，張女指控林經常施暴、毆打，她曾為林墮胎 四次。林憲同昨向《蘋果》坦承小他二十二歲的張女是同居人，但他喊冤表示，自己把張女當女兒照顧，並在對方身上花了一千五百萬元，沒想到被反咬一口。<br><br>
                                            &emsp;&emsp;林憲同（六十三歲）畢業於台大法律系、法研所，是民進黨前主席謝長廷的同學、前總統陳水扁的學長，曾任台北律師公會理事長、台南縣議會副議長，橫跨法界、政界，陳水扁家族洗錢案爆發後，他頻受邀上《文茜小妹大》等政論節目批判扁家。<br><br>
                                            <span style="font-weight:600;">與相差22歲舞女同居</span><br>
                                            &emsp;&emsp;對於傳出桃色糾紛，林憲同昨大方地認了。他在律師事務所受訪時，不停地翻閱這些年來和張筱梅（四十一歲）共同出遊的照片，對於這段長達十一年的畸戀，他感慨地說：「人生向晚，何處訴悲涼？」<br>
                                            &emsp;&emsp;林憲同表示，一九九七年，他和妻子感情冷淡，瀕臨離婚，加上三名子女移民加拿大，每晚沒人陪伴吃晚飯，覺得十分寂寞，於是跟著朋友到紅極一時的米高梅舞廳跳舞，認識當時三十歲的舞女張筱梅，兩人隨即交往、同居，張女隔年離開舞廳，到他的法律事務所當助理。<br>
                                            &emsp;&emsp;他說，到了一九九九年，他和妻子離婚，把張筱梅當成相知相惜的人生伴侶，「張的父親是退伍老兵，家境不好，但她很聰明，高中還考過第一名，迫於經濟才到舞廳上班，我很同情她的際遇。」<br>
                                            &emsp;&emsp;林憲同自認生活上張女照顧他，經濟上他幫助張，每月給十萬元生活費，還幫她付頭期款買套房、公寓各一間，陸續花了一千五百萬元，但兩人爭吵不斷，張女不斷離家、回家。<br><br>
                                            <span style="font-weight:600;">被控施暴賠房子和解</span><br>
                                            &emsp;&emsp;林憲同說，二○○一年，他最疼愛的十九歲么女林宜徵，因為不適應移民生活，在加拿大自殺身亡，他在情感上對張女更依賴，他告訴張女：「妳要代替奶娃（么女乳名）照顧我，我以後會把三分之一財產分給妳。」<br>
                                            &emsp;&emsp;不過，張女指控，去年十一月她和林憲同發生爭執，林把她推倒在床上，不僅掐她脖子，還用棉被蒙住她的臉，害她差點窒息，她告林殺人未遂，但檢方後來以林沒有殺人犯意不起訴。張女聲稱，她後來又被林憲同打巴掌，林挨告後，把台北縣板橋國慶路一間房子過戶給她，兩人達成和解。<br>
                                            &emsp;&emsp;對於林憲同向《蘋果》陳述的交往過程，張筱梅怒斥：「林憲同根本是用金錢掌控女人，我當他助理，幫忙賺錢，還為他墮胎四次，但只要吵架就拿不到薪水，至於 買房子，我自己也有出錢。」她抱怨最無法忍受林對外稱她是「包養的舞女」。張筱梅說：「我把他當成家人，他卻說兩人十一年的親密關係只是交易，每次想離開 他，他就恐嚇要告我，希望這件事見報後，從此一刀兩斷。」<br><br>
                                            <span style="font-weight:600;">擬再找舞小姐伴晚年</span><br>
                                            &emsp;&emsp;林憲同稍後表示沒說過張女是「包養的舞女」，至於張女聲稱曾四度為他墮胎，他說：「不知道是不是我的。」他強調，他只要與張女吵架，就會到新加坡舞廳跳舞，也會帶舞小姐吃消夜，張女愛吃醋，曾為此摔破花瓶，用雨傘戳他，他才會動粗。<br>
                                            &emsp;&emsp;《蘋果》記者昨傍晚訪問林憲同時，正好有舞廳小姐打電話約他，他告訴記者：「今天是律師節，張筱梅的事讓人心煩，我也許會到新加坡舞廳晃一下，那裡有很多 有趣的人和故事，我以後會寫成小說，關於我與張女的故事，就叫做《老兵的女兒》。」林強調，他考慮找一個看盡人生的舞小姐，當終身伴侶。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-15 variant=""
                                    >夫妻失和，夫謊稱同志，怒指遭大陸妻「硬上」</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-15"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;中時電子報&nbsp;2008/09/10</h4>
                                        <p>
                                            &emsp;&emsp;羅姓男子去年迎娶大陸妻子來台，夫妻感情不睦，羅某今年一月竟向警方哭訴自己是同志，遭老婆強逼吃壯陽藥「硬上」，但基隆地檢署查無證據，九日以不起訴偵結。<br><br>
                                            &emsp;&emsp;檢警調查，羅某去年九月一日迎娶卅七歲大陸籍配偶來台，夫婦常爭吵不休。今年一月羅某向警方控訴，他是同性戀的○號，難以勃起，大陸妻子卻強迫他吃大陸製「狼1號」春藥行房。大陸妻甚至覬覦他的保險金，並恐嚇「再這樣，真恨不得殺了你！」<br><br>
                                            &emsp;&emsp;羅某指控歷歷，但檢方調查後，拆穿羅某謊言。檢方調閱羅某精神科就醫紀錄，並未有記載羅某有同性戀女性傾向。同時發現，因大陸妻子討厭魚水之歡，羅某還帶她去看心理醫師。<br><br>
                                            &emsp;&emsp;去年十月，大陸妻與羅某爭吵後揚言離婚，他盛怒下，竟持鐵練將大陸妻綁在床邊，恐嚇「要先折磨你幾天，再殺掉你！」隨後還違反大陸妻子意願，姦淫得逞。大陸妻飽受驚嚇之餘，才會怒罵「再這樣，真恨不得殺了你！」<br><br>
                                            &emsp;&emsp;檢方指出，羅某並非屬同性戀，查無證據證實羅某遭到大陸妻子性侵害。大陸妻怒罵「再這樣，真恨不得殺了你！」這是夫妻口角後的怨懟言詞，無法認定大陸妻有預備殺人之意。檢方昨出不起訴處分。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-16 variant=""
                                    >欠債綁架姪孫&nbsp;老公氣到離婚</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-16"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;聯合新聞網&nbsp;2008/09/09</h4>
                                        <p>
                                            &emsp;&emsp;台北縣連姓婦人向地下錢莊借錢賭六合彩，利滾利積欠5、600萬元債務，她被錢莊逼急了，抱走4個月大的小姪孫，向姪孫的祖母、即丈夫的二嫂勒贖500萬元還債。 得款後把姪孫送返，昨天被士林地檢署依擄人勒贖罪嫌起訴、求刑8年。<br><br>
                                            &emsp;&emsp;連姓婦人（43歲）很後悔犯行，曾當庭向小姪孫的母親及祖母（她二嫂）下跪認錯，二嫂當庭將她扶起，表示原諒，並懇求檢察官高抬貴手，不要讓連婦入監；她丈夫卻無法原諒妻子，已和她離婚。<br><br>
                                            &emsp;&emsp;上月14日上午10時許，連婦受不了地下錢莊逼債，見丈夫的二嫂林姓婦人很富有，到林家騙稱「帶小孩回家玩耍」，抱走二嫂4個月大的姪孫。兩小時後，她打電話給二嫂要求匯錢，林姓婦人當天下午2時許匯出500萬元。<br><br>
                                            &emsp;&emsp;連婦領款還債，並在當晚6時許，將被擄走的嬰兒抱到三重市格致中學附近交給女兒抱回林家。林家原本體諒連婦被錢莊逼債的犯罪動機，但為防他人有樣學樣，報警抓人。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-17 variant=""
                                    >廣達副總林頂立通姦自首&nbsp;大奶狀告&nbsp;二奶無罪</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-17"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;自由時報&nbsp;2008/09/09&nbsp;記者劉志原、卓怡君／台北報導</h4>
                                        <p>
                                            &emsp;&emsp;廣達副總林頂立與何姓女子外遇，他主張，因受不了何女一直要求他送市值800萬的Tiffany鑽石項鍊，終於向老婆陳 端美「自首」，林妻一聽氣炸了，控告何女通姦並求償100萬，林頂立則出庭作證，自白坦承與何女在中國上海嘿咻4次，並描述何女的乳頭顏色。台北地院審理 認為，自白不能當唯一證據，判何女無罪、免賠。<br><br>
                                            <span style="font-weight:600;">女方要求800萬項鍊</span><br>
                                            &emsp;&emsp;北院指出，通姦罪必須是以雙方發生性關係為要件，本案除林頂立自白外，無性愛光碟、體液衛生紙等直接證物。 至於林某說何女皮膚白，法官認為，任何人都看得出來。另林某描述何女乳頭灰黑，法官認為「灰黑」是林某個人主觀評斷，無法據此證明兩人有親密關係。<br>
                                            &emsp;&emsp;判決書指出，林頂立自承他與44歲的何女為南澳大學EMBA同班同學，2人在96年11月至97年7月，在上海「如家快捷酒店」發生4次性關係，後來他想與何女分手，但何女卻向他索討鑽石項鍊，他無法負擔，決向老婆坦承出軌。<br><br>
                                            <span style="font-weight:600;">男方向元配坦承出軌</span><br>
                                            &emsp;&emsp;案發後，林妻撤回對丈夫林頂立通姦告訴，只告何女，林頂立成為證人，他為證明與何女通姦，提出他與何女的往來電子郵件，他在郵件中對何女說，「自己的財產已經全部交給老婆，妳是拿不到錢的」；何女回稱，「告訴你太太我們的事，你太太肯定很難過、後果大家都承擔不起」。<br><br>
                                            <span style="font-weight:600;">男形容女乳頭顏色</span><br>
                                            &emsp;&emsp;林頂立還當庭向法官說，他有5、600度近視，與何女嘿咻時都關燈，無法看清何女全身特徵，但近處可以看到何女皮膚白、乳頭灰黑圓滑等。何女則主張，是林頂立追她追不到，才杜撰說兩人有姦情，但她坦承曾向林某要項鍊，不過這是兩人投資理財行為，不是通姦。判決書指出，除相關認定之外，兩人往來的電子郵件，並沒有提到性關係等內容，綜合事證後判何女無罪。<br><br>
                                            <span style="font-weight:600;">自白不能當唯一證據</span><br>
                                            &emsp;&emsp;法界人士表示，丈夫偷情後向老婆自首或向檢察官自白，均不能證明對方涉通姦罪，必須要有明確證據，如對方已生小孩、捉姦在床等直接證據，才能將被告定罪。依據證交所上市公司資料，林頂立名下的廣達股票有78張，妻子陳端美則有3763張。針對此事，廣達表示，此為個人私事，與公司營運無關，無法評論。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-18 variant=""
                                    >乍見女友劈腿&nbsp;刺得情敵肚腸流</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-18"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;中時電子報&nbsp;2008/09/09</h4>
                                        <p>
                                            &emsp;&emsp;萬姓女子和先生分居十年，又大搞劈腿。八日萬女男友李坤城上門約她共同去找工作，卻瞥見萬女被窩裡另有其人，氣得他跑到廚房找出一把水果刀，當場把情敵刺得肚破腸流，板橋警方局正全力緝兇中。<br><br>
                                            &emsp;&emsp;廿八歲萬姓女子，因有妨害婚姻前科，在和先生分居十年後又大搞劈腿，同時和五十歲的陳姓男子及李坤城（四十八歲）等人交往。<br><br>
                                            &emsp;&emsp;昨日上午十時許，李坤城前往萬女位住處，找萬女和他一起去找工作，卻在萬女床上看到陳某正在呼呼大睡，氣得他當場開罵並衝到廚房裡找傢伙，一刀就朝陳姓男子腹部砍下。<br><br>
                                            &emsp;&emsp;李坤城行兇後就逃；萬姓女子嚇得趕緊報警將陳姓男子送醫急救。警方已據報南下緝兇；陳姓男子經急救後目前已無生命危險。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-19 variant=""
                                    >北縣凶殺案&nbsp;疑女子另結新歡遭男友刺殺</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-19"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;中央新聞社&nbsp;2008/08/19&nbsp;中央社記者卞金峰台北縣十九日電</h4>
                                        <p>
                                            &emsp;&emsp;台北縣永和市上午發生重大凶殺命案，男子陳盈智疑感情因素，持刀刺殺女友蘇逸珩，蘇女左胸中刀，滿身鮮血，經送醫急救仍告不治，而陳男頸胸腹部多處刀傷，有生命危險，已轉送新店耕莘治療。警方指出，陳男任外商業務經理，疑蘇女另結新歡，兩人談判時行凶，造成一死一傷。<br><br>
                                            &emsp;&emsp;台北縣永和市文化路九十巷九弄二號四樓，清晨六時餘發生凶殺案，警消獲報趕至現場，發現女子蘇逸珩（二十八歲）左胸中刀，滿身是血，立即緊急將她送往三總汀洲分院急救，不過，她到院時已無生命跡象，急救後仍告不治。<br><br>
                                            &emsp;&emsp;警方指出，行凶的陳盈智（三十二歲）身上也有多處刀傷，意識不清，被緊急送永和耕莘醫院急救，因為他身上包括頸、胸、腹部等多處刀傷，寬約二至三公分，情況危急；急診醫師指出，經治療後，因為陳盈智有血胸及氣胸的現象，仍有生命危險，隨後他轉院至新店耕莘醫院急救。而被害人的哥哥及家屬先至三總汀洲分院，相當難過，之後再被送至轄區的新生派出所詢問，以釐清案情；警方初步調查，陳男任職於外商公司擔任業務經理，而蘇女則是行銷公司人員，疑蘇女認識新歡，兩人在談判時發生糾紛，陳男才會行凶，至於真正的案發原因還待警方深入調查。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-20 variant=""
                                    >嫌病妻胖訴離&nbsp;偷情夫敗訴</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-20"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路&nbsp;2008/08/19&nbsp;鄧玉瑩╱台中報導</h4>
                                        <p>
                                            &emsp;&emsp;男子廖德滿認為妻子六年不和他行房，而且妻子動輒生病、住院，又常在外賒帳，因此向台中地院訴請離婚。但廖妻則反駁，是丈夫嫌她胖拒絕行房，還發生婚外情。法官審理認為，這場婚姻會破裂，主要是廖不能體諒妻子身體不好，還發生外遇，因此判廖敗訴，駁回離婚聲請。<br><br>
                                            <span style="font-weight:600;">未體諒妻反外遇</span><br>
                                            &emsp;&emsp;住台中縣神岡鄉的廖德滿（五十歲）和妻子包素滿（四十五歲）結婚二十七年，育有兩子。廖指出，兩人已分房十五年，近六年來沒有性生活，且妻子常以身體狀況不佳進出醫院，搭計程車時或到雜貨店購物常賒帳，導致常有人上門討錢。<br>
                                            &emsp;&emsp;但廖妻辯稱，是丈夫嫌她太胖不願與她同房，而且她患有癌症、重度肢障等，因此常進出醫院，但丈夫都沒陪在身邊。而且丈夫曾有外遇，兩人因此吵架，丈夫卻將她推倒在地，讓她因此骨折住院，她不願離婚。廖不否認有外遇，但表示已結束婚外情。法官審理認為，兩人雖婚姻破裂，但主因是廖德滿並未體諒妻子病痛纏身，反發生外遇，因此廖不能聲請離婚，判決駁回。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-4">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-4 variant=""
                                    >印尼看護外遇產女&nbsp;殺嬰藏屍</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-4"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;中時電子報&nbsp;2008/08/19</h4>
                                        <p>
                                            &emsp;&emsp;印尼籍看護女工桑達麗，十六日在三重雇主家產下女嬰，她怕曝光遭遣返，涉嫌以繩索勒斃親生女，再用塑膠袋包裹屍體，棄置廚房冰箱上方；警方喟嘆「小女嬰出生搞不好活不到三分鐘」，訊後以殺人罪嫌移送。<br><br>
                                            &emsp;&emsp;三重警方表示，印尼籍Sundari（中譯桑達麗、卅六歲）兩年前受雇到三重仁興街賴家，看護臥病台北馬偕醫院的雇主母親。十六日下午二時桑女返回雇主家中，懷有七月身孕的她竟在淋浴時產下女嬰。她怕東窗事發被遣返，竟用懸掛浴巾的繩索勒死才呱呱落地的親生女嬰。<br><br>
                                            &emsp;&emsp;她先略事沖洗屍體，再以毛巾包裹，裝入便利商店塑膠袋，最後將屍袋及洗衣粉等雜物放進紅色大塑膠袋，棄置冰箱上方。藏好屍體後，她呆坐在廚房冰箱旁的地板上喝水休息。賴姓雇主越南籍老婆煮好午飯，招呼桑女吃飯時，她以身體不適「要躺一下」回絕。賴家人後來發現她下體不斷出血，下午將她送三重錫安婦產科醫院就醫。<br><br>
                                            &emsp;&emsp;晚間七點，醫生內診發現桑女有生產跡象，雇主趕緊報警並追查嬰兒下落，經過三小時不斷苦勸，桑女才坦承女兒死了。桑女告訴警方，孩子是她跟在台打工的印尼籍男友生的，一出生便夭折，強調「小孩在腹中就不會動」、「剛生下來便無呼吸心跳」，一開始並不承認殺女。不過，鑑識人員發現女嬰頸部有明顯勒痕，質疑有他殺之嫌，案情才急轉直下。<br><br>
                                            &emsp;&emsp;桑女懷孕七個月，為什麼雇主沒發現？警方調查，賴姓雇主及越南籍太太白天均在外工作，與桑女甚少碰面，才未發現桑女已「帶球跑」。檢察官及法醫昨天勘驗，確認女嬰是產下後窒息死亡，並非桑嫌所說胎死腹中。警方指出，桑嫌在印尼已婚並育有子女，她怕在台劈腿懷孕，產子被發現會遭遣返，加上男友日前轉往韓國打工，頓失經濟來源，才狠心產後勒斃親女藏屍。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <nav class="d-flex justify-content-center">
                              <ul class="pagination">
                                <li class="page-item disabled">
                                    <span class="page-link">回上頁</span>
                                </li>
                                <li class="page-item active" aria-current="page">
                                  <span class="page-link">1</span>
                                </li>
                                <li class="page-item">
                                  <router-link to="/News2" class="page-link">2</router-link>
                                </li>
                                <li class="page-item">
                                  <router-link to="/News2" class="page-link">下一頁</router-link>                                
                                </li>
                              </ul>
                            </nav>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <b-modal id="modal-lg" size="lg" centered title="外遇調查">
        <div class="d-block">
          <p class="qa-p">
            愛真的可以天長地久嗎? 我的另一半明天是否依然愛我?<br><br>
            &emsp;&emsp;親愛的他(她)對你越來越冷淡了?當你遇到人生中不如意的事情，正當不知所措急於想知道事情的真相，雖然我們都知道，事實的真相是殘酷的現實，況且您可能只是懷疑，我們給您快速又專業的徵信，你只要一如平常的作息等待我們用最短的時間給您真相。<br>
            國華女人徵信依據業務多年的經驗提供下列幾點給各位做個參考：<br>
            <ul class="list">
              <li>找各種徹夜不歸或晚歸的理由（如：開會、加班）</li>
              <li>行為舉止怪異（如：態度冷漠、動不動就說要離婚或分手）</li>
              <li>接到常有不出聲電話、電話費明顯暴增、手機有曖昧之簡訊</li>
              <li>接到某人來電時藉故迴避談話</li>
              <li>性愛次數明顯減少</li>
              <li>開銷花費明顯增加</li>
              <li>轎車上或身上有來歷不明的物品</li>
              <li>耳聞同事或友人所見之情形</li>
              <li>常外出說要去 7-11 等便利商店買東西，其實是為了掩飾打電話給情人</li>
              <li>突然變得很溫柔，變得很容易妥協 ! ( 彌補偷情的愧疚心理 )</li>
              <li>對於你的問題回答的很模糊，甚至問太多就會動怒</li>
            </ul>
            &emsp;&emsp;以上幾點是一般外遇徵兆常見之情形，也十之八九有問題；其實另一半只要有外遇，不管如何謹慎小心，一定都會有破綻。但是每個人常犯的錯誤就是打草驚蛇、沉不住氣或是杯弓蛇影、無中生有，所以在沒有確切證據前，沒有辦法小心沉著，是許多人無法忍耐的；我們的專業不僅只是告訴您真相，而是可以在您的另一半確實有出軌行為的蒐證與建議，重要的是如果另一半真是沒有外遇行為，也不希望因為懷疑造成家庭破碎。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-1" size="lg" centered title="外遇蒐證">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;在現今社會中，無論是男人或是女人，在外遇的時候都會給自己很多藉口，甚至大部分的偷情者都有種”家花不如野花香”的觀念，讓不知情的另一半因此而深受傷害；因為絕大多數的人，在偷情以後，就忽略了原本一直陪在身邊的伴侶。<br><br>
            &emsp;&emsp;經過了外遇調查後，若證實對方已經有外遇劈腿的情形，後續的處理，需請您沉住氣，因為接下來的就不是像吵架一樣的一吐為快，如何在這個時候佔到優勢的地方，就是您需要最專業的我們的原因，以下大致分為三種方式：<br><br>
            <span style="color:red; font-size:20px;">感情破壞：</span><br>
            針對第三者所研究出來的方式，配合狀況，使第三者或被查人在"不知情"的情況下自然的分手，並徹底不再往來，使夫妻兩人之間感情的障礙移除，但是想要恢復以往美滿和諧的家庭生活，有時候並不只是第三者的原因，在這方面，國華女人也可以提供最完整、實在的方式，來讓您了解問題的癥結點在哪，而達到完美的結局。<br><br>
            <span style="color:red; font-size:20px;">感情挽回：</span><br>
            &emsp;&emsp;如何挽回先生太太的心？需依當時情況而言，之中技巧萬千，非筆墨所能形容；主要需針對客戶狀況，主要為選用科技種類與心理技巧、打動人心......等，以達到挽回之目的。<br>
            其實女人外遇是最困難且挽回機率很低的，相對於男人外遇大多是肉體外遇，女人卻是精神外遇先開始才會到肉體外遇，但是女人變心並不快，可是只要一變心就會變的冷血！如果女人真的外遇，男人真的要沉靜心裡先想想看到底是什麼讓你們的感情走了樣；讓她覺得被忽略？覺得你不再呵護她？不在乎她的感受、把她捧在心上？女人永遠是希望自己是被捧在手中的寶。<br><br>
            &emsp;&emsp;節日送個小禮物、開口說愛她，甜言蜜語都不可少！不要怕肉麻，如果你真想挽回，就趕快去做吧！接著就是做些讓她感動的事，邊觀察她對外遇對象是真的動心，還是一時的慰藉。<br><br>
            <span style="color:red; font-size:20px;">捉姦在床：</span><br>
            &emsp;&emsp;這是對已經精疲力盡，不想再對這段感情付出的人的最佳途徑，也可以徹底和另一半斷絕關係，並且對於小孩監護權及贍養費求償方面有極大勝算。<br>
            相反的這也是斷絕第三者跟另一半糾纏不清的最好方法之一，不要以為這樣就會撕破臉以後夫妻感情會更糟，當然事前這些都會經過我們的建議跟當事人的決定情形來斟酌用何種方式來辦理。<br>
            &emsp;&emsp;然而這最後行動前所運用的精密儀器和專員使用的場合方式，都會讓委託人清楚的明白我們一定是用最符合您的方式，讓不管是任何一方都不會受到不必要的傷害。<br><br>
            提供幾種外遇方式作為參考：<br>
            <ul>
              <li>網路戀情......</li>
              <li>大陸包二奶......</li>
              <li>生意往來......</li>
            </ul>
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal2"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-2" size="lg" centered title="大陸及海外案件">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;對於大陸二奶問題特別重視，針對台商問題除有專業人員派遣之外，並與當地的業者配合加上精密的蒐證儀器，針對案情無往不利，所有的姦情絕對讓您寮如指掌，並且及早預防。<br><br>
            &emsp;&emsp;大陸地區一直是台商的最愛，近來由於政府對大陸政策鬆綁，使得台商更是有如脫韁野馬，每個人都想到大陸去發展，連一般人,尤其是男性，更是把大陸當作【觀光勝地】【出國考察】的第一順位。原因無他，因為大陸地區土地廣大，女人多，價錢便宜（在台灣酒店消費一次，約在大陸可以消費七天，並且可以帶出場），而且大陸女性對台胞情有獨鍾，深深了解如何施展手腕來擄獲台商的心、並非是台灣一般風化場所可比較的。使得每每去過大陸之友人，剛回台又想再去一次。更遑論居住在大陸的台商或台商員工，老婆不在身邊的甘苦，絕非一般人能體會，所以在當地尋找溫柔女子來慰寂反而是一種非常正當的情形,，有這樣，旁人還以為是你有問題呢！當然，妳也不要以為妳搬過去一起夫唱夫隨就可以預防這種事情的發生，據我們調查的案件顯示近3成外遇問題都是夫妻一起過去創業，創業危堅時夫妻同心協力共創美好的未來，有錢時老公就開始作怪，開始往外發展。案情輕者，往往只是拈花惹草。重者，搞得妻離子散，更甚者，除了【錢】進大陸外，事業也跟著走下坡，瀕臨破產的情形更是屢見不鮮，為人妻者不可不防。<br><br>
            <ul>
              <li>市場調查徵信 - 產品產銷地點、行銷管道等等。</li>
              <li>產品仿冒徵信 - 專利、產品商標之侵權、仿冒，生產公司、地點的追查。</li>
              <li>信用調查徵信 - 公司行號或個人生意往來間之信用程度。</li>
              <li>動產、不動產鑑定 鑑定個人及公司動產、不動產狀況。</li>
              <li>各種疑難徵信 - 協助個人或周遭之人、事、物的困難解決。</li>
              <li>外遇徵信-俗稱通姦的調查。</li>
              <li>個人素行 個人行為操守的偵查。</li>
              <li>婚前徵信 男女朋友交往的忠實性。</li>
              <li>尋人查址 如倒債、捲款潛逃、離家出走（逃妻、逃夫、子女等）。</li>
            </ul>
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal3"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-3" size="lg" centered title="外遇抓姦">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;真正優良的徵信公司會依照正常合理的抓姦過程來處理一個專案，因為要符合行使訴訟之人要負舉證之責的律法邏輯。換句話說必需採證到正在進行式的姦情才能在法律的觀點上去維護委託人的全面性立場。如果婚姻經過專業的我們蒐證與審慎評估後，無挽回之必要時，就只有用抓姦的方法，方可解決問題並保障自己與孩子的權益；並且我們會將通姦者對受害的委託人造成的傷害降到最低，這才是專業應有的表現。<br><br>
            &emsp;&emsp;【偷情】【外遇】【抓姦】【婚外情】【養小狼狗】【包二奶】這幾個字詞常態性地出現在電視新聞和報章雜誌裡，甚至媒體也經常的運用這些題材拍成電影或綜藝節目探討的主題。現代人對自我個體有著非常強烈的意識抬頭，忠貞也在現代社會的感情上讓人非常重視，「一夫多妻」和「一妻多夫」這種事情已不被通融允許，法律上也有嚴格的條例規範，但也因為不再光明正大，所以才會有所謂的外遇、偷情、抓姦、婚外情甚至在兩岸三通後更有包二奶的事情頻傳；甚至在價值觀扭曲的現今，有許多人為了這種作壞事的叛逆感而樂此不疲。<br><br>
            &emsp;&emsp;國華女人徵信在提供相關諮詢解答到蒐證之後，雖然台灣人傳統”勸和不卻離，家和萬事興”的道德觀念，但是每一段感情，並不是自己委屈求全就可以讓家庭幸福美滿，但是佛也有發火的時候，我們會給您最專業、貼心的建議，讓您在對的這一方取得優勢。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal4"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-4" size="lg" centered title="家庭暴力">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;國華女人徵信，專為解決家庭暴力，讓您及您的家人走出家庭暴力的陰霾；同時讓您瞭解什麼是家庭暴力防治法，此法是如何規範及保護您的權益；在現今社會中，人民生活水準提昇，所得卻不成比例的比比皆是；民眾面臨的生活壓力持續提昇，進而造成無辜的家人成了發洩的對像，引發社會上多起的家庭暴力事件。<br><br>
            &emsp;&emsp;家庭暴力影響堪大，它是造成家庭破碎的主要原因之一，更是影響孩子成長階段的心靈發展；真相真探徵信家暴防治部提供家暴相關新聞，讓施暴者瞭解家庭暴力對家人所造成的傷害有多大；是否應該冷靜想想，懸崖勒馬。家庭暴力防治法規，讓受害者勇於對外求助，免於家暴傷害。<br><br>
            <span style="font-size:25px;color:red;">注意事項</span><br>
            <ul class="list">
              <li>簡單清楚的流程，您可明白所有的作業程序，若有任何疑問本公司將有專人為您解答</li>
              <li>本公司有常年配合的律師顧問，您可以放心將此重任託付給我們</li>
              <li>對於客戶的資料，本公司負有保密之責，不會將客戶資料隨意散播</li>
              <li>一旦結案，本公司將會把所有資料銷毀，以確保您我權益</li>
            </ul>
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal5"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-5" size="lg" centered title="婚前徵信">
        <div class="d-block">
          <p class="qa-p">
            <span style="font-size:25px; font-weight:600;">背叛與不忠是人類追求刺激的誘因</span> <br><br>
            &emsp;&emsp;背叛與不忠是人類追求刺激的誘因,當你遇到人生中不如意的事情時,在這裡我們打破傳統,給你們迅速又專業的徵信,你只需要高枕無憂的在家中等待我們給您的答案。你真的了解你身邊的另一半嗎 ? 為了你的終身幸福婚,你記得幫身體做健康檢查,怕身體出狀況,給自己多一點保障。國華女人婚前徵信，絕對是你披上白紗前必做的幸福健診！<br>
            &emsp;&emsp;有鑑於此,只要委託者提供被查人之基本資料，國華女人婚前徵信，即可提供以下六大部分,作為日後交往或結婚之參考。<br>
            <ul>
              <li>家庭背景投資事業有無誇大不實</li>
              <li>家族有無先天性疾病或隔代遺傳之精神疾病</li>
              <li>偵查是否與前男 ( 女 ) 友藕斷絲連以及劈腿之惡行</li>
              <li>蒐集有無前科或不良之紀錄</li>
              <li>個人信用調查及銀行往來之紀錄</li>
              <li>學經歷是否虛假偽造</li>
            </ul>
            <span style="font-weight:600;">國華女人婚前徵信</span>調查您所要調查的對象，無論男女情人或者是準新人，為您提供相關法律諮詢或心理輔導等多項服務，並根除不肖份子以婚姻方式之名行詐騙之實，造成社會治安問題及人性道德之淪喪，亦可避免自身無端成為他人婚姻之第三者。我們提供您最溫柔的建議以及百分之百的忠誠服務！保護您的個人權益，更是我們永不退讓的堅持。<br><br>
            <span style="font-size:25px; font-weight:600;">人的一生,往往需要做出許多重要的決定</span><br><br>
            &emsp;&emsp;人的一生中，往往需要做出許多重要的決定，不論事情的大小；婚姻使得兩個相愛的人做出決定，但我們無法正確的得知所嫁或所娶的是不是用真實的那一面對著您？往往我們都會尋求一種模式，交往過一陣子在愛情的喜悅與甜蜜中，自以為他 ( 她 ) 是我的真命天子、 Mr.right ？但真的了解以及認識我們的另一半嗎？<br>
            &emsp;&emsp;所以為何現今社會的婚姻所維繫的時間越來越短？人們當初眼中的佳偶，卻又往往以怨偶收場，不能老是拿那一套個性不合，因不了解而結合又因了解而分離吧！我們常聽到未雨綢繆，這叫事先規劃的風險，評估管理，投資理財會想到、但為何在人生婚姻上不做些評估及管理呢？屆時再來個吵鬧分離人才兩失！<br>
            &emsp;&emsp;一個婚姻是兩個人的結合，我們自以為像童話故事般的王子與公主，從此過快樂無憂的日子，孰不知這才是故事的開始。但這個故事是要喜劇收場還是歹戲拖棚往往看雙方有無用心，干擾一段婚姻能否美滿，在於雙方有無正確了解彼此？<br>
            &emsp;&emsp;因為任何一件小事甚鉅，比如雙方的家庭背景 ( 如前科、已婚、通緝 ) ；財務狀況 ( 如信用、資產 ， 有無遺傳病，在感情尚未真正投入埋下往後的不定時炸彈前，做好婚前的徵信讓用心經營的人種下良緣得到善果，祝福全天下真心相愛的人，永浴愛河！<br><br>
            <span style="font-size:25px; font-weight:600;">你真的了解你身邊的另一半嗎？</span><br><br>
            &emsp;&emsp;你真的了解你身邊的另一半嗎？ 你怕取錯妻、嫁錯郎嗎？ 怕身體出狀況，在走上紅毯前，為了你的終身幸福， 當然更應該替您的婚姻也把把脈！ 給自己多一點保障， 為什麼命運差那麼多...？為什麼最初的理想會崩潰...？為何愛情會被生活磨蝕而幻滅...？<br>
            &emsp;&emsp;時代的變遷，新台灣人的觀念隨之而改變，道德及兩性間的認知或有所不知，導致離婚、家庭暴力不斷發生，進而影響下一代的教育，導致製造社會上的問題！ 其實戀愛是抽象的，婚姻卻是現實的，會有許多衝突摩擦！所以，愛情是沒有永久保證書的！<br>
            &emsp;&emsp;婚前徵信的重要性： 讓你透視另一半的本質，可提供你不了解的另一面，可供參考，作為你走進婚姻的心理依據！ 健診內容<br>
            <ul class="list">
              <li>感情忠實度</li>
              <li>家世背景</li>
              <li>學經歷、經濟狀況...</li>
              <li>動產、不動產</li>
              <li>另一半家族病史</li>
              <li>交友狀況及前科紀錄</li>
            </ul>
            國華女人婚前徵信&emsp;絕對是你披上白紗前必做的幸福健診！<br><br>
            <span style="font-size:25px; font-weight:600;">婚前徵信的重要性更甚於健康檢查！</span><br><br>
            &emsp;&emsp;人的一生中，不管大小事，都不停的在做選擇賭注。 對男人或女人而言，最大的賭注就是婚姻！押對的人，一生幸福快樂；押錯的人，情路坎坷波折不斷，甚至遭到暴力相向以至於家破人亡。 這其中當然有環境或經濟上的因素，難以克服；但也有一部份是因為婚前對於交往對象的了解不夠，而造成終身的遺憾。<br>
            &emsp;&emsp;以前或許你沒有能力及管道去徹底了解你的交往對象，也許是對方的甜言蜜語而沉溺在幸福浪漫的假象裡，而不敢去面對現實。然而婚前徵信就如同婚前健康檢查一樣重要！ 透過健康檢查可以幫您發現對方是否有家族遺傳病史或其他傳染疾病，在個人可接受的範圍內，或許您會願意接受陪伴您的另外一半一路走下來，哪怕是身體有缺陷的人，因為他 ( 她 ) 是坦白的，能夠讓我們眼睛看的見。<br>
            &emsp;&emsp;婚前徵信的重要性更甚於健康檢查！ 有人因為貪圖對方的一切或家產，交往之初，對當事人百依百順，百般奉承的表現出一副忠誠老實模樣，一旦結婚後慢慢地露出真面目侵占當事人的一切或家產；若配偶不從將以百般虐待，甚至於暴力相向，逼迫 ( 他 ) 她就範以及離婚收取贍養費收場。 也有人交往的對象是走私、販毒者前科累累而不知，等到感情全部投入後，才發現真相後悔已來不及了；這麼重要的終身幸福，您能不重視嗎？ 國華女人徵信可幫您做完整的婚前調查，為您的幸福把關。<br><br>
            <span style="font-size:25px; font-weight:600;">結婚就是兩個人帶了不同的期待走進禮堂</span><br><br>
            &emsp;&emsp;有人說：「結婚就是兩個人帶了不同的期待走進禮堂」所以婚後有人吵鬧有人白頭偕老。台灣的結離婚的比例，從前些年的每十對有一對到近年的的每十對就有七對，數字的飆漲不禁令人咋舌，但相對也讓我們警覺出婚前徵信的重要性。<br>
            &emsp;&emsp;愛情的迷思有時讓人無法理性思考，但透過業者的協助，精準的搜尋與客觀的分析，希望能讓您在婚姻的路途上走的更踏實、甜蜜；而且若一但有不利於你的危機查出，你才能理性的去解決 ( 還要不要繼續這段關係；要不要懸崖勒馬 ) 而重新設定您的標準。 離婚率不斷爬升，失婚男女越來越多，當一對對的佳偶變怨偶，當婚後的您必須面對喜歡酗酒、外遇 ( 偷情 ) 或者還要忍受家庭暴力、沉重負債，小孩的監護權及雙方家長的問題，林林總總的痛苦之後，何不讓我們防範於未然，人人都需要有危機意識，有時靠自己的眼光是有限的，求助於外力並不丟臉，而是建立信心的第一步，話說知己知彼，讓我們運用智慧來換取雙贏的美滿婚姻。真正的原因就是這麼簡單，但人們總是被誤導，生活中許多解決事情的辦法都很容易，但要找出這個辦法卻很不容易。<br><br>
            <span style="font-size:25px; font-weight:600;">您所選的對象真的值得您託付終生嗎?</span><br><br>
            &emsp;&emsp;戀愛是那麼甜蜜美好，因為那只是兩個個體的心靈交流、相處。但婚姻是現實的它是兩個家庭、經濟，兩個人的生活的結合。然而愛情是盲目的，熱戀中的男女往往看不清事實的真相，在現成離婚率高的社會，更增顯婚前徵信的重要性。<br>
            &emsp;&emsp;「婚前徵信」有如選舉前對候選人的徵信，也是抓住幸福的機會，它可以準確地為您倆的愛情開出診斷証明，讓您全盤了解將來的另一半，例如財產、信用、交友(包括對你(妳)是否專一)、健康(包括家族遺傳疾病)、學歷(是否造假)....。每個人尋尋覓覓,直到遇見自以為的真命天子(女)而開始交往戀愛，到論及婚嫁。但您所選的對象真的值得您託付終生嗎？婚姻是人生中重要的一環，可知若是選對了人，婚姻就是一輩子，選錯了人，就可以只有幾年，甚至只有短短幾天！ 經過了婚前徵信的調查，若是選擇正確，可以加深您對將來另一半的信任感；選擇錯誤，方可讓您看清真相，是要繼續？還是放棄？有了「婚前徵信」，讓您做出最明智的選擇，再也不怕娶錯妻、嫁錯郎。<br><br>
            <span style="font-size:25px; font-weight:600;">婚前徵信讓您遠離『危險情人』與『分手暴力』生嗎?</span><br><br>
            &emsp;&emsp;自殺殉情、潑硫酸、情殺…等『分手暴力』事件頻傳，熱戀中的男女往往忽略如何觀察自己心儀的對象是否是『危險情人』，而一頭栽進未來可能會遭遇『分手暴力』的未知風險中。 先前白曉燕案獲判無罪的關係人張智輝女友遭其殺害事件，就是分手談判破裂慘遭情殺的典型個案，如果能在交往中小心觀察，委婉、漸進式疏遠的分手，應該可以避免生命遭受威脅甚至喪命的分手暴力事件。 危險情人的特質（資料提供/高雄市立凱旋醫院）：<br>
            <ul class="list">
              <li>甜言蜜語、容易呈現迷人與自吹自擂的特質。</li>
              <li>缺乏長期知交的人際關係。</li>
              <li>低自尊、不關心自己的前途。</li>
              <li>與家人關係多為不和睦。</li>
              <li>不易對他人表示關懷。</li>
              <li>在校、工作期間行為紀錄不良。</li>
              <li>易生氣、衝動、與他人發生衝突。</li>
              <li>堅持伴侶凡事都需要應順從自己的要求。</li>
              <li>工作穩定度不夠。</li>
              <li>有酗酒、吸毒習慣。</li>
              <li>不易為自己的犯錯感到抱歉或自責。</li>
            </ul>
            &emsp;&emsp;一般熱戀交往的男女朋友即將步入結婚禮堂前，往往疏忽將來兩人世界會面臨的諸多問題，僅會規劃拍美美的婚紗照，細心一點的會去做婚前健康檢查及婚後財務、理財約定，『婚前徵信』可以調查交往中的朋友交友狀況、財務現況、個人信用、前科或法院判決、訴訟紀錄、抵押紀錄、學歷文憑是否屬實、日常品性及行為、家庭成員及相處情況等提供給準新郎、新娘做為評估終身伴侶之參考資料。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal6"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-6" size="lg" centered title="婚姻諮詢">
        <div class="d-block">
          <p class="qa-p">
            <span style="font-size:25px; font-weight:600;">Q1:我先生經常半夜喝得酩酊大醉才回來，他說是為了公事，才出去交際應酬的，可是我很擔心他這樣殘害自己的身體，我該怎麼勸他呢？</span><br><br>
            &emsp;&emsp;熬夜、喝酒對身體的負面影響很大，而且這種影響往往不是立即展現，所以很多夜貓族、酗酒族就忽視了其危害性。先生經常在外熬夜喝酒交際，不但戕害自己的健康，也害妻子在家提心吊膽，更可能因晚歸減少了夫妻相處的時間，而引起婚姻危機，真的是得不償失！面對先生的熬夜應酬、喝酒交際的行為，妻子在表達不滿之前，不妨先將自己的關懷和擔心表現出來,讓先生知道妳真的很心疼見到他如此不經易地殘害自己。<br>
            &emsp;&emsp;然後在先生清醒時，靜下心來和他討論改善之道：是否一定要如此熬夜、喝酒,才能在公司裡受到重用和認同？有沒有其他方式可以取代？如果先生也有心改善這種情形，夫妻雙方將不難找出解決之道；但是，如果先生無意改善，甚至對這樣燈紅酒綠的生活感到樂此不疲，那麼，也許得借重婚姻諮商專業機構人員的協助，才能有所改進。<br><br>
            <span style="font-size:25px; font-weight:600;">Q2:我結婚五年了，有兩個孩子。最近我愛上了另一個男人，自己也變得喜歡打扮，彷彿又回到過去談戀愛時期；但另一方面，也對先生感到愧疚和有罪惡感，我該怎麼辦呢？</span><br><br>
            &emsp;&emsp;婚姻生活是現實的，它往往需要負擔柴米油鹽的責任，亦有照顧小孩、公婆的負擔，故生活就被一件一件瑣事堆積，而變得平淡無味。此時，若夫妻無法在生活中增添情趣，戀愛的虛幻感覺，即可能趁虛而入，使人有機會掉入外遇的陷阱中。<br>
            &emsp;&emsp;在上述事件中，因當事者處在兩個男人之中，故存在著很多的衝突矛盾，有必要在兩個男人中有所割捨。割捨前，不妨多給自己有段反省的時間，想想兩個男人對自己的意義與價值，及自己在割捨間之利弊得失。若自己一時無法釐清，尋求專業人士的幫助，亦是不錯的方式。 當然，在分離過程中，亦可能會遭遇割捨不下之難題或情緒空虛的害怕，此時若能在時空上配合，即拉遠和欲分離者的距離，並尋求一可信任之情緒之支持者，都是幫助自己走出外遇陰影的方式。當然若能告訴自己的「另一半」，並獲得「另一半」的支持，則在分離的路上，較不易顯得孤單和寂寞。 另外，先生於初知道此消息時，必定會暴跳如雷，但氣消之後，如果能協助太太度過此難關，相信對她將是最大的支柱。<br><br>
            <span style="font-size:25px; font-weight:600;">Q3: 我和我先生結婚五年了，我發現我們的性生活愈來愈平淡乏味，我擔心再這樣下去，一定會影響婚姻的關係與品質，該怎麼辦呢？</span><br><br>
            &emsp;&emsp;經驗分享：夫妻在一起生活愈久，就愈不懂得浪漫，也愈不懂得在生活中製造一些情趣，平淡和無趣成了每天必然的感受。雖然這樣的描述很寫實，但卻過於消極和無奈，其實每對夫妻是可以不必這樣過的。 夫妻結婚多年之後，對於另一半的新鮮感會漸漸減少許多，很多事無形中都變得例行而且單調，性生活也不例外。如果夫妻任何一方已意識到這種情形，不妨多回想以前戀愛或新婚時常去的場所、最喜歡的情境，然後在現實生活中重新營造類似的氣氛，讓彼此重溫舊、重燃愛火。當然你也可以將臥房的佈置稍做調整：燈光調柔和一點、擺些花飾、播放些浪漫的輕柔音樂………。當然，若環境、時間許可，換換做愛的地點，例如客廳的沙發上、渡假小木屋......，也是增進情趣的方法。總之，只要雙方願意肯花點心思營造一些情境與驚奇，日子就不會一直乏味下去的。<br><br>
            <span style="font-size:25px; font-weight:600;">Q4: 我是個晚睡晚起型的人，而我先生卻是早睡早起慣了，我們睡眠時間的不一致，讓我們彼此都很困擾。另外，我喜歡點小燈睡覺，但我先生卻堅持要關燈睡，為此，常常搞得不是我失眠，就是他無法入睡，怎麼辦呢？</span><br><br>
            &emsp;&emsp;兩個原本在不同家庭中成長的人，在結為夫妻之後，首先面臨到的衝突往往是生活習慣的不一致，有些夫妻甚至在新婚之夜就為了擠牙膏的小事而怒目相向。夫妻在遇到生活習慣不一致的情況時，千萬別想一定要對方改變，順應自己的方式，畢竟那些生活習慣已伴隨他數十年之久了。最好是採取折衷、雙贏的辦法，彼此都退半步，幾天的適應期之後，差不多可以習慣新的生活方式，夫妻雙方將不再為這些生活瑣事而大動干戈了。<br>
            &emsp;&emsp;晚睡的妻子和早睡的丈夫，不妨各自提早、延後一小時或兩小時就寢，如此一來，睡眠時間就可以逐漸調到一致了。而喜歡關燈睡覺的先生遇上喜歡開燈睡覺的太太時，何妨將臥室的燈掉，只留太太床頭邊的小燈，如此一來，對先生而言的確是關燈了，對太太而言也達到開燈的需求。<br><br>
            <span style="font-size:25px; font-weight:600;">Q5: 什麼是「家庭暴力」？遭受同居人虐待算不算是遭受家庭暴力呢？</span><br><br>
            &emsp;&emsp;依家庭暴力防治法第二條規定，家庭暴力指的是「家庭成員間實施身體或精神上不法侵害之行為」。 家庭成員」包括：
            <ul class="list">
              <li>配偶或前配偶。</li>
              <li>現有或曾有事實上之夫妻關係、家長家屬或家屬間關係者。（同居者即為事實上婚姻關係，即屬此類）</li>
              <li>現為或曾為直系血親或直系姻親。</li>
              <li>現為或曾為四親等以內之旁系血親或旁系姻親。</li>
            </ul>
            『身體上不法侵害』指的是：<br>
            例如虐待、遺棄、押賣、強迫、引誘從事不正當之職業或行為、濫用親權、利用或對兒童少年犯罪、傷害、妨害自由、性侵害…等。包括有鞭、毆、踢、捶、推、拉、甩、扯、摑、抓、咬、燒、扭曲肢體、揪頭髮、扼喉或使用器械攻擊等方式。<br>
            『精神上不法侵害』指的是：
            <ul class="list">
              <li>言詞虐待：用言詞、語調予以脅迫、恐嚇,以企圖控制被害人。如謾罵、吼叫、侮辱、諷刺、恫嚇、威脅傷害被害人或其親人、揚言使用暴力等。</li>
              <li>心理虐待：如竊聽、跟蹤、監視、冷漠、鄙視、羞辱、不實指控、試圖操縱被害人等足以引起人精神痛苦的不當行為。</li>
              <li>性虐待：強迫性幻想或特別的性活動、逼迫觀看色其影片或圖片等。</li>
            </ul>
            <span style="font-size:25px; font-weight:600;">Q6: 曾聽人提起『婚姻諮商』有助於改善夫妻之間的關係，當面臨家庭暴力時，不想離婚，如果去做婚姻諮商，會不會對問題有所幫助，到底什麼是婚姻諮商？</span><br><br>
            婚姻諮商不是化解夫妻衝突的萬靈丹,不是請輔導者幫助解決夫妻的問題,也不是達到某種仲裁效果,說明誰對誰錯,而是從實務的觀點,幫助雙方： <br>
            <ul class="list">
              <li>由不同角度看問題。</li>
              <li>培養更好的溝通能力與情緒控制能力。</li>
              <li>發展更親密的關係。</li>
              <li>正視困境、面對現狀、有效改善。</li>
              <li>了解差異、面對不同、尊重體諒。</li>
            </ul>
            &emsp;&emsp;好的諮商是提供夫妻更開放的溝通管道，協助雙方更有效的傾聽和表達，也能暸解問題所在，學習如何改善困境或是增加調適能力。如果希望婚姻諮商能對夫妻問題有所幫助，那麼「及早發現，及早治療」的觀念十分重要，在夫妻相處有問題、夫妻溝通有困難時，就可以尋求協助，不一定要等到已經有暴力發生時才去重視。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal7"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-7" size="lg" centered title="專案尋人查址">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;本公司擁有豐富的尋人經驗，範圍除了台灣本島外，海外地區涵蓋中國大陸、越南、泰國、新加坡、美國、日本等地區。本公司除了豐富的經驗之外，並有良好的外界關係，加上顧客若提供的正確諮料（如可以提供如室內電話、行動電話、戶籍、或身分證字號等，均可以透過這些來查址或查詢其他諮料，其他國家亦可）以達到在最快最短的時間之內找到想要找的人。<br><br>
            &emsp;&emsp;至於一般客戶所最擔心的價格問題，本公司收費保證公道合理，況且可以撥打免付費電話做諮詢，或許您只要花一點點時間撥個電話，可以得到最大的效益喔。不用懷疑，我們絕對有較阿亮更高級的本事去替您找尋你所要找尋的人。我們全省分佈的資源將會替您服務，相信我們的專業，將會帶給你想要找的。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal8"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-8" size="lg" centered title="免費法律諮詢">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;您在感情上有困惑嗎？這是一個女性意識抬頭的時代，女人已經不是弱者了，女人也可以是奧運金牌，也可以是政治首長，甚至家暴也不一定是女性受害，不過這些也只是您打開電視按鈕所看到的新聞，我們要讓您知道的，是當您在家庭內不被重視，無論是家暴（家庭暴力）、另一半外遇，”委曲求全”並不是您唯一可以做的，國華女人徵信無論在專業上貼心的建議、法律上實際的免費諮詢，都可以讓您感受到一整個無微不至的照顧。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal9"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
    </section>
  </div>
</template>

<script>
export default {
  name: "News",
  data() {
    return {};
  },
};
</script>

<style scoped>
.hero-box {
  width: 100%;
}
.hero {
  background-image: url(../assets/images/service.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  z-index: 1;
  -webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
.lineder {
  font-size: 3rem;
  text-align: center;
}
.lineder::after {
  content: "";
  width: 8%;
  height: 1.5px;
  background: red;
  display: block;
  margin: 0 auto;
}
.warp {
  display: block;
  position: relative;
  width: 100%;
  top: -100px;
  margin-bottom: -80px;
}
.item-t1{
  margin-top: 55px;
}
.item-content{
  margin-bottom: 20px;
}
.item-content .item {
  padding: 1rem;
  background: white;
  text-align: center;
  border-top: 5px solid rebeccapurple;
  border-radius: 0px 0px 10px 10px;
  text-align: inherit;
}
.scroll-btn{
  text-align: center;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid black; 
  border-bottom: 2px solid black;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
.grid {
	position: relative;
	margin: 0 auto;
	/* padding: 1em 0 4em;
	max-width: 1000px; */
	list-style: none;
	text-align: center;
}
.grid figure {
	position: relative;
	float: left;
	overflow: hidden;
	/* margin: 10px 1%;
	min-width: 320px;
	max-width: 480px;
	max-height: 360px;
	width: 48%; */
	background: #3085a3;
	text-align: center;
	cursor: pointer;
}
.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}
.grid figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}
.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
  z-index: 99;
}
.grid figure h2 {
	word-spacing: -0.15em;
	font-weight: 300;
  font-size: 22px;
}
.grid figure h2 span {
	font-weight: 800;
}
.grid figure h2,
.grid figure p {
	margin: 0;
}
.grid figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}
figure.effect-honey {
	background: #4a3753;
}
figure.effect-honey img {
	opacity: 0.9;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}
figure.effect-honey:hover img {
	opacity: 0.5;
}
figure.effect-honey figcaption::before {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 10px;
	background: gold;
	content: '';
	-webkit-transform: translate3d(0,10px,0);
	transform: translate3d(0,10px,0);
}
figure.effect-honey h2 {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 1em 1.5em;
	width: 100%;
	text-align: left;
	-webkit-transform: translate3d(0,-30px,0);
	transform: translate3d(0,-30px,0);
}
figure.effect-honey h2 i {
	font-style: normal;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0,-30px,0);
	transform: translate3d(0,-30px,0);
}
figure.effect-honey figcaption::before,
figure.effect-honey h2 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}
figure.effect-honey:hover figcaption::before,
figure.effect-honey:hover h2,
figure.effect-honey:hover h2 i {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}
.p-qa{
  margin-bottom: 0;
}
.p-qa:hover{
  color: slateblue;
}
.qa-p{
  margin-bottom: 0;
}
.list{
  margin: 0.5rem auto;
  list-style-type: decimal;
}
.card-text h4 {
  background: burlywood;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  padding: 8px 10px;
  display: inline-block;
  /* margin: 30px 0 20px; */
  text-align: left;
}
.btn-secondary:hover{
  background: rgb(255, 222, 89);
  color: black;
}
.disabled:hover{
  cursor: not-allowed;
}
/* .frame {
  margin: 0 auto;
  padding: 1.5rem;
  background: white;
  border-top: 5px solid rebeccapurple;
  margin-bottom: 20px;
  border-radius: 0px 0px 10px 10px;
} */
@media(max-width:767px){
  .lineder::after{
    width: 20%;
  }
  .modal-open .modal{
    padding: 0 !important;
  }
  .modal-dialog{
    margin: 0 0.5rem;
  }
}
</style>

<style>
.modal-title{
  font-size:26px;
  font-weight: 600;
  letter-spacing: 1px;
}
.pagination a{
  color: #007bff !important;
}
</style>
