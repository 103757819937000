<template>
  <div class="Contact">
    <div class="hero">
      <div class="container-fluid">
        <div class="row" style="position: relative;">
          <div class="hero-box">
            <div class="hero">
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="" style="background: #f5f4f0;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="warp">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="item-content">
                <div class="item">
                  <div class="scroll-btn">
                      <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -80,duration: 1500}">
                        <div>
                            <span class="down-arrow-1"></span>
                            <span class="down-arrow-2"></span>
                            <span class="down-arrow-3"></span>
                        </div>
                      </div>
                  </div>
                  <div class="row justify-content-center item-t1">
                    <div class="col-12">
                      <h2 class="lineder mb-5" id="element">聯絡我們</h2>
                    </div>
                    <div class="col-lg-8 col-md-10 col-sm-12">
                      <p>
                        &emsp;&emsp;許多女人誤解婚姻就是嫁給一個男人，而不知道還要嫁給這男人的習慣與性格，以及這男人身後的家族；如果認知的不足，我們將在這社會，看到更多破碎的婚姻；如果您有問題難以啟齒，可以透過線上寄信功能，我們會盡快與您聯絡，並幫您尋求最好的解答。<br><br>
                        &emsp;&emsp;如果您在我們的服務上，有任何的不滿意，也歡迎撥打專線或是利用線上留言，我們將會盡心改進您不滿意的地方。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="frame">
                <h2 class="lineder mb-4">如何慎選徵信社</h2>
                <ul class="list">
                  <li>公司是否有執照</li>
                  <li>必須設有申訴電話</li>
                  <li>是否加入徵信公會</li>
                  <li>貨比三家不吃虧</li>
                  <li>業務員是否有名片</li>
                  <li>有無委任書</li>
                  <li>一定要親自到徵信公司參觀</li>
                  <li>徵信公司是否廿四小時營業</li>
                </ul>
              </div>
              <div class="frame">
                <h2 class="lineder mb-4">24小時服務專線</h2>
                <ul class="list">
                  <li>
                    北部地區:
                    <a href="tel:0800611777" class="phone">0800-611-777</a>
                  </li>
                  <li>
                    中部地區:
                    <a href="tel:0800622777" class="phone">0800-622-777</a>
                  </li>
                  <li>
                    南部地區:
                    <a href="te:0800633777" class="phone">0800-633-777</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {};
  },
};
</script>

<style scoped>
.hero-box {
  width: 100%;
}
.hero {
  background-image: url(../assets/images/service.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  z-index: 1;
  -webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
.lineder {
  font-size: 3rem;
  text-align: center;
}
.lineder::after {
  content: "";
  width: 8%;
  height: 1.5px;
  background: red;
  display: block;
  margin: 0 auto;
}
.warp {
  display: block;
  position: relative;
  width: 100%;
  top: -100px;
  margin-bottom: -80px;
}
.item-t1{
  margin-top: 55px;
}
.item-content{
  margin-bottom: 20px;
}
.item-content .item {
  padding: 1rem;
  background: white;
  text-align: center;
  border-top: 5px solid rebeccapurple;
  border-radius: 0px 0px 10px 10px;
  text-align: inherit;
}
.scroll-btn{
  text-align: center;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid black; 
  border-bottom: 2px solid black;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
.grid {
	position: relative;
	margin: 0 auto;
	/* padding: 1em 0 4em;
	max-width: 1000px; */
	list-style: none;
	text-align: center;
}
.grid figure {
	position: relative;
	float: left;
	overflow: hidden;
	/* margin: 10px 1%;
	min-width: 320px;
	max-width: 480px;
	max-height: 360px;
	width: 48%; */
	background: #3085a3;
	text-align: center;
	cursor: pointer;
}
.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}
.grid figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}
.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
  z-index: 99;
}
.grid figure h2 {
	word-spacing: -0.15em;
	font-weight: 300;
  font-size: 22px;
}
.grid figure h2 span {
	font-weight: 800;
}
.grid figure h2,
.grid figure p {
	margin: 0;
}
.grid figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}
figure.effect-honey {
	background: #4a3753;
}
figure.effect-honey img {
	opacity: 0.9;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}
figure.effect-honey:hover img {
	opacity: 0.5;
}
figure.effect-honey figcaption::before {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 10px;
	background: gold;
	content: '';
	-webkit-transform: translate3d(0,10px,0);
	transform: translate3d(0,10px,0);
}
figure.effect-honey h2 {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 1em 1.5em;
	width: 100%;
	text-align: left;
	-webkit-transform: translate3d(0,-30px,0);
	transform: translate3d(0,-30px,0);
}
figure.effect-honey h2 i {
	font-style: normal;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0,-30px,0);
	transform: translate3d(0,-30px,0);
}
figure.effect-honey figcaption::before,
figure.effect-honey h2 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}
figure.effect-honey:hover figcaption::before,
figure.effect-honey:hover h2,
figure.effect-honey:hover h2 i {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}
.p-qa{
  margin-bottom: 0;
}
.p-qa:hover{
  color: slateblue;
}
.qa-p{
  margin-bottom: 0;
}
.card-text h4 {
  background: burlywood;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  padding: 8px 10px;
  display: inline-block;
  /* margin: 30px 0 20px; */
  text-align: left;
}
.btn-secondary:hover{
  background: rgb(255, 222, 89);
  color: black;
}
.disabled:hover{
  cursor: not-allowed;
}
.frame {
  margin: 0 auto;
  padding: 1.5rem;
  background: white;
  border-top: 5px solid rebeccapurple;
  margin-bottom: 20px;
  border-radius: 0px 0px 10px 10px;
}
.list{
  list-style: decimal;
  margin: 0 auto;
  width: 38%;
}
.list li{
  margin-bottom: 10px;
}
.phone {
  text-decoration: none;
  padding: 0;
  font-size: 22px;
  color: red;
}
.phone:hover {
  color: blue;
}
@media(max-width:767px){
  .lineder::after{
    width: 20%;
  }
  .list{
    width: 100%;
  }
  .modal-open .modal{
    padding: 0 !important;
  }
  .modal-dialog{
    margin: 0 0.5rem;
  }
}
</style>

<style>
.modal-title{
  font-size:26px;
  font-weight: 600;
  letter-spacing: 1px;
}
.pagination a{
  color: #007bff !important;
}
</style>
