<template>
  <div class="About">
  <div class="hero">
    <div class="container-fluid">
      <div class="row" style="position: relative;">
        <div class="hero-box">
          <div class="hero">
          </div>
        </div>
      </div>
    </div>
  </div>
    <section class="" style="background: #f5f4f0;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="warp">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="item-content">
                <div class="item">
                  <div class="scroll-btn">
                      <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -80,duration: 1500}">
                        <div>
                            <span class="down-arrow-1"></span>
                            <span class="down-arrow-2"></span>
                            <span class="down-arrow-3"></span>
                        </div>
                      </div>
                  </div>
                  <div class="row item-t1">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      <div class="about_us_img h-100"></div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      <div class="h-100">
                        <h3 id="element">
                          國華女人徵信有限公司
                        </h3>
                        <p>
                          &nbsp;&nbsp;國華女人徵信有專業優良的女性徵信專員，擁有優秀的專案處理能力，提供婚前徵信、離婚法規諮詢、外遇蒐證、專業捉姦、家暴防治處理、台灣大陸兩案三地徵信、工商徵信、專案尋人等服務。<br>
                          &nbsp;&nbsp;政府立案的合法的徵信公司其實也不乏，但是如何能得到滿意的服務，就是您需要好好思考的，我們擁有全省各地分公司、政府頒發的營業執照、專業心理諮商人員及專業法律諮詢顧問一同為您服務，當您遇到婚姻外遇的低潮時，國華女人徵信公司的每一位員工都願意陪您渡過，願我們的專業與熱忱能得到您的信任。<br>
                          &nbsp;&nbsp;在台灣社會的觀念裡，提到徵信兩個字，是為大部分人所排斥的，因為在現今社會和報導裡，總是對徵信行業充斥著不公平的論調，當然倚著徵信名號詐財的人不少，所以國家更應該合法訂定制度，以免更多的受害者受騙上當。<br>
                          &nbsp;&nbsp;我們秉持著貼心、熱忱的服務態度來達到您所需要的。瞬息萬變的社會中，誰是你最能信賴的朋友？想要尋找苦尋無處的他？懷疑商場對手的可信度？當您切身遭遇另一半外遇的疑惑時，誰能正確無誤地為您找到事實真相？人際關係複雜的社會裡，任何人都可能碰到的商場糾紛、消費糾紛、財務糾紛、家庭婚姻問題，當您感到求助無援，不知如何處理棘手問題之時，國華女人徵信能即時提供適當的解決方案及服務。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="frame">
                <h2 class="lineder mb-4">服務理念</h2>
                <p>
                  &nbsp;&nbsp;當我們人生之中，發生了身心無法承受的事情時，多半人都會不知所措或是無法面對事情的真相，可是當事情卡在咽喉的時候是最難過的，雖然我們知道認清事實是一件很殘酷的現實，但是我們會用專業的角度，幫助您理性釐清並找出最好的解答，猶如像我們自己的朋友，不會讓您平白受委屈與屈辱而不自拔；不會讓你在夜裡一個人哭泣，如何再找回你自已，人生的路還要繼續下去，請信賴我們的專業 ，得到您應有的解答，協助您恢復原有的自信，讓你能再一次的面對生活、面對未來。
                </p>
              </div>
              <div class="frame">
                <h2 class="lineder mb-4">如何慎選徵信社</h2>
                <ul class="list">
                  <li>公司是否有執照</li>
                  <li>必須設有申訴電話</li>
                  <li>是否加入徵信公會</li>
                  <li>貨比三家不吃虧</li>
                  <li>業務員是否有名片</li>
                  <li>有無委任書</li>
                  <li>一定要親自到徵信公司參觀</li>
                  <li>徵信公司是否廿四小時營業</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>  

export default {
  name: 'About',
  data(){
    return{}
  }
}
</script>

<style scoped>
.hero-box {
  width: 100%;
}
.hero {
  background-image: url(../assets/images/company.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  z-index: 1;
  -webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
.warp {
  display: block;
  position: relative;
  top: -100px;
  margin-bottom: -80px;
}
.about_us_img {
  margin: 20px auto 0px;
  width: 100%;
  background-image: url(../assets/images/aboutUs.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 80%;
}
.item-t1{
  margin-top: 55px;
  margin-bottom: 50px;
}
.item-content{
  margin-bottom: 20px;
}
.item-content .item {
  padding: 1rem;
  background: white;
  text-align: center;
  border-top: 5px solid rebeccapurple;
  border-radius: 0px 0px 10px 10px;
  text-align: inherit;
}
.scroll-btn{
  text-align: center;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
       transform: rotate(45deg);
       transform: rotate(45deg);
  border-right: 2px solid black; 
  border-bottom: 2px solid black;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}

.item-content .item h2 {
  width: 100%;
  background: linear-gradient(
    to left,
    rgba(134, 205, 249, 1) 0%,
    rgba(87, 139, 213, 1) 100%
  );
  font-family: YuMincho, yu-mincho-pr6, sans-serif;
  font-weight: 500;
  color: #fff;
  padding: 10px 0px;
  text-align: center;
}
.item-content .item h3 {
  color: burlywood;
  border-bottom: 1px solid burlywood;
  padding-bottom: 5px;
  font-weight: 500;
  margin: 20px 0 20px;
}
.item-content .item h4 {
  background: burlywood;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  padding: 8px 10px;
  display: inline-block;
  /* margin: 30px 0 20px; */
  min-width: 100px;
  text-align: left;
}
.item-content .item h5 {
  color: burlywood;
  font-weight: 500;
  font-family: cursive;
}
.item-content .item li {
  letter-spacing: 1.5px;
}
.item-content p {
  font-family: cursive;
  line-height: 28px;
  letter-spacing: 1.5px;
  text-align: left;
  font-size: 20px;
}
.frame {
  margin: 0 auto;
  padding: 1.5rem;
  background: white;
  border-top: 5px solid rebeccapurple;
  margin-bottom: 15px;
  border-radius: 0px 0px 10px 10px;
}
.frame p{
  font-family: cursive;
  font-size: 20px;
}
.lineder {
  font-size: 3rem;
  text-align: center;
}
.lineder::after {
  content: "";
  width: 8%;
  height: 1.5px;
  background: red;
  display: block;
  margin: 0 auto;
}
.list{
  list-style: decimal;
  margin: 0 auto;
  width: 38%;
}
.list li{
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .list{
  width: 100%;
}
.about_us_img {
  margin: 0 auto;
  padding: 60% 0%;
}
.lineder {
  font-size: 2rem;
}
.lineder::after{
  width: 20%;
}
}
</style>