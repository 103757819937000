<template>
  <div class="News2">
    <div class="hero">
      <div class="container-fluid">
        <div class="row" style="position: relative;">
          <div class="hero-box">
            <div class="hero">
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="" style="background: #f5f4f0;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="warp">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="item-content">
                <div class="item">
                  <div class="scroll-btn">
                      <div id="mouse-scroll" v-scroll-to="{el: '#element',offset: -80,duration: 1500}">
                        <div>
                            <span class="down-arrow-1"></span>
                            <span class="down-arrow-2"></span>
                            <span class="down-arrow-3"></span>
                        </div>
                      </div>
                  </div>
                  <div class="row justify-content-center item-t1">
                    <div class="col-12">
                      <h2 class="lineder mb-5" id="element">徵信新聞</h2>
                    </div>
                    <div class="col-12">
                        <div class="accordion" role="tablist">
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-1 variant=""
                                    >大小老婆爭遺產...醫屍長冰家裡</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-1"
                                visible
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;聯合新聞網&nbsp;2008/08/17</h4>
                                        <p>
                                            &emsp;&emsp;嘉義地區一名林姓醫師涉案遭收押，交保後心臟病發死亡，最近傳出疑因大小老婆爭奪遺產，大老婆把他的遺體放在自家冷凍數月不願下葬，引起鄰居恐慌、也有人嚇壞了。<br><br>
                                            &emsp;&emsp;記者昨天到林家查訪，林醫師大老婆及兒子不願證實，只說「很多事情不方便說，已經進入司法程序，一切交給老天，自有公道。」醫界人士說，享年六十歲的林醫師，生前在中南部大醫院擔任急診室主任、家醫科醫師，交遊廣闊，地方傳有多名紅粉知己。<br><br>
                                            &emsp;&emsp;去年十月，林醫師因涉及詐領保險金弊案遭羈押，後來交保，今年約二、三月傳出林醫師心肌梗塞死亡，但昔日醫界友人竟沒人知道，直到最近才傳出因他大小老婆為爭奪遺產，導致他的遺體一直被冰存在家裡。據悉，林醫師生前買了一棟值逾千萬元透天厝，給他與二奶所生女兒，但他死後，大老婆與兒子進住這棟透天厝，把他的遺體冰存屋內。也有人說，約三周前凌晨，林醫師遺體被一輛救護車載走，但是不知被移到那裡。<br><br>
                                            &emsp;&emsp;林醫師原服務的醫院院長昨天說，數月前，接到林醫師大老婆來電詢問「林醫師已經死亡了，是否還有欠薪未付。」他才知道林醫師已死。轄區警方說，曾到林家處理大小老婆爭執，但不知遺體是否冰存家中。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-2 variant=""
                                    >爆出軌醜聞&nbsp;美法官辭職</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-2"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;聯合新聞網&nbsp;2008/08/15</h4>
                                        <p>
                                            &emsp;&emsp;英國每日電訊報報導，英國一名地方法官背著妻子透過網路結交一名已婚護士，護士的丈夫發現妻子不忠後，竟殺害三歲的親生女兒以報復妻子，這名法官也因此案辭職。法官穆勒酖利多現年四十五歲，他在尋找性伴侶的網站上結識卅一歲的護士喬安．霍爾。兩人交換裸照，並在旅館內幽會兩次。喬安在電子郵件中說，她要當穆勒酖利多的「小淫婦」。<br><br>
                                            &emsp;&emsp;但喬安的丈夫、卅三歲的放射科醫師霍爾發現妻子有外遇後，先用安眠藥餵給三歲的親生女兒吃，再用衣服把她悶死，以報復妻子。他還殺死家中兩隻貓，再以電子郵件告訴妻子他幹的事。霍爾在二○○六年十一月被判謀殺罪名成立，並處以無期徒刑，至少得服刑十五年。<br><br>
                                            &emsp;&emsp;在審判期間，霍爾說，他發現妻子與已婚法官有染，並在網路性網站上推銷自己時，「已喪失心志」。穆勒酖利多也遭到司法申訴辦公室的調查，在調查結果出爐前即已辭職，調查隨即停止。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-3 variant=""
                                    >竊聽器捉姦&nbsp;未觸妨害秘密</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-3"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;自由時報&nbsp;2008/08/14&nbsp;記者余瑞仁／桃園報導</h4>
                                        <p>
                                            &emsp;&emsp;桃園縣賴姓男子與徐姓情婦92年間通姦生子被判刑，卻持續交往，馮姓妻子委託徵信社在丈夫車上裝設GPS與竊聽器，逮到丈夫與徐女在高鐵桃園站附近「車震」告進法院，賴某則反控妻子妨害秘密，檢察官調查認為，馮女並非「無故」侵害賴某隱私，昨天將馮女以不起訴處分。<br><br>
                                            &emsp;&emsp;這起捉姦蒐證與妨害秘密的法律衝突，因刑法妨害秘密罪是以「無故」竊錄他人隱私為要件，檢察官認為馮女因要保護婚姻與家庭，侵害隱私的蒐證行為並非「無故」，而在法律衝突中，馮女要保護的家庭與婚姻，在比例原則上優於丈夫的隱私權，因此認定不構成妨害秘密罪嫌。<br><br>
                                            &emsp;&emsp;這件人夫與情婦兩度被狀告通姦案，男主角39歲的賴某為印尼華僑，10餘年前與馮女結婚後，92年間被馮女發現出軌，與小他11歲的徐姓女子婚外情生下1女，馮女當時提告通姦，經法院各判刑3月，2人都以易科罰金執行完畢。<br><br>
                                            &emsp;&emsp;然而馮女於95年間發現丈夫與徐女仍然藕斷絲連，不僅互有聯絡，還兩度共赴印尼探親，經委託徵信社在賴某的轎車上裝設錄音設備與GPS衛星定位器。去年9月2日下午賴駕車搭載徐女外出，馮女隨著徵信社人員尾隨，發現丈夫與徐女在高鐵桃園青埔站區偏僻處「車震」，完事後還順手丟出衛生紙團。<br><br>
                                            &emsp;&emsp;馮女當下撿回衛生紙團，且收回錄音帶聽到男女談話及性愛的呻吟聲，於是再度控告賴與徐女通姦。<br><br>
                                            &emsp;&emsp;桃檢偵辦時，從衛生紙團驗出有賴某與徐女混合體液DNA，加上有錄音帶為證，且2人經查二度同時入出境，因此認定有通姦之嫌，但馮女最後關頭撤回對丈夫的告訴，檢察官6月間偵結將賴不起訴處分，徐女則以累犯通姦罪嫌起訴。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-5 variant=""
                                    >被騙惹惱妻?&nbsp;元配戳破外遇</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-5"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;自由時報&nbsp;2008/08/13&nbsp;記者何瑞玲／北縣報導</h4>
                                        <p>
                                            &emsp;&emsp;4年前遭詐騙集團誆騙2000多萬元積蓄的民航局陳姓管制員，當時聲稱被騙得很慘，致妻子氣得離家出走，他最近向法院訴請離婚，妻子出面戳破負氣離家謊言，指是管制員自己擁中國女，還把娘家購買的房子給中國女住，「鳩佔鵲巢」，她有苦無處申。<br><br>
                                            &emsp;&emsp;陳姓管制員的妻子溫女並出示中國籍曾姓女子寫給丈夫的情書為證，中國女在情書裡寫著「我們一起去南京、上海、本是你我難得的蜜月日…真是如痴如醉…多少歡樂、多少纏綿…」。溫女悲傷的說，娘家買給她住的房子，竟被丈夫提供給中國籍女子住，這段婚姻她也不想要，但要求拿回娘家名下房子及500萬元贍養費。<br><br>
                                            &emsp;&emsp;法官審酌物證及小孩證詞後認為，陳姓管制員對婚姻不忠發生婚外情後，又被詐騙集團詐騙2000多萬元而負債累累，對婚姻的破壞責任較重，訴請離婚無理由，予以駁回。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-6 variant=""
                                    >傳色情伊媚兒簡訊&nbsp;當心犯性騷法</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-6"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;聯合新聞網&nbsp;2008/08/13</h4>
                                        <p>
                                            &emsp;&emsp;用電子郵件寄色情圖片涉及性騷擾防治法？警察局婦幼隊證實有案可稽，而且已移請主管機關裁罰，現在性騷擾防治法的規定很嚴格，有人因為傳電話簡訊騷擾女性，就被裁罰新台幣1萬元。<br><br>
                                            &emsp;&emsp;台南地檢署襄閱主任檢察官李靜文說，不要被「襲胸10秒無罪」、「舌吻5秒無罪」等案例誤導，那是引用不同法條不足構成妨害性自主才判無罪，若改以刑法強制猥褻罪或性騷擾防治法，肯定有罪。<br><br>
                                            &emsp;&emsp;李靜文解釋，性騷擾防治法的立法精神在於保護當事人不受言語、肢體動作騷擾，只要言語或動作令人感覺「達到冒犯的情境」，就可以提出申訴，裁處行政罰，若有更進一步的肢體碰觸，則涉及刑事責任，可以依性騷擾防治法第25條例送地檢署。<br><br>
                                            &emsp;&emsp;市府社會處督導魏杏真說，去年全市有8個案例被裁罰，最高裁罰8萬元，是一名男性對女性保險業務員頻頻電話騷擾，口出穢語，又以電話傳送內容不堪的簡訊。性騷擾防治委員會開會表決，通過裁處8萬元罰鍰。<br><br>
                                            &emsp;&emsp;還有一名男子約出女網友見面，汙言穢語，要以2000元代價援交，女網友先前早已聲明見面是做朋友，這名男子還死纏爛打舔著臉要貼近。這名男子被裁罰1萬元。<br><br>
                                            &emsp;&emsp;婦幼隊副隊長吳麗娟說，前年有一名陳姓男子，電腦接到電子郵件，打開看到色情圖片與訊息，憤而向婦幼隊提告，強調他身家清白「一向不看這種荒淫圖片」，要告對方涉及性騷擾。婦幼隊從發信的IP位址追查到台中，發信人還很無辜地自稱是廣告商，以收人3000元代價，發送3000封情趣商品的廣告信，未料被控性騷擾。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-7 variant=""
                                    >包商妻陪睡&nbsp;嘉縣府貪官劈腿泡酒店</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-7"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;聯合新聞網&nbsp;2008/08/13</h4>
                                        <p>
                                            &emsp;&emsp;嘉義縣政府辦理治水工程爆發桃色和金錢風暴，水利處長陳處長被查出涉嫌和廠商勾結，收受逾五百萬元賄款，檢調昨天發動搜索，帶回陳殿寶、發包中心技士曾嫌及包商陳姓夫妻等八人偵訊，晚間依貪汙罪嫌移送地檢署複訊。<br><br>
                                            &emsp;&emsp;去年五月陳處長與包商陳太太鬧緋聞，陳姓包商不堪戴綠帽的嚴重打擊，但因經營的芳苑工程顧問公司有財務危機面臨停業，不得不隱忍。外界傳聞則指陳姓包商是利用妻子打通陳處長的關係，陳處長昨天向檢調人員承認和包商陳太太是男女朋友，但否認收賄。陳姓包商則供稱拿過一百萬元賄賂陳處長。<br><br>
                                            &emsp;&emsp;檢調是去年接獲檢舉，掌握陳處長等人接受廠商招待出入酒店、收受回扣等不法事證，昨天搜索相關人員的辦公室、住處，帶回陳處長、縣府發包中心技士曾嫌、方苑工程顧問公司負責人陳姓夫妻、立品及益川工程顧問公司負責人林X盛、副總經理莊X今、順智興業有限公司負責人王X順及京揚工程科技公司負責人郭X良等八人調查，今天凌晨依貪汙罪嫌移送地檢署複訊，檢察官向法院聲請羈押陳處長、曾嫌、王X順。<br><br>
                                            &emsp;&emsp;檢調指出，嘉義縣政府自九十五年十月至九十六年五月，辦理「新埤排水系統規劃委託技術服務」、「內田排水系統改善工程委託勘測設計及監造」、「龍宮溪排水系統改善工程委託勘測設計及監造」，及「布袋第三漁港開發計畫申請開發許可及土地編定委託服務」等四項工程，陳處長等人利用限制性招標，讓特定廠商得標，期約收受逾五百卅六萬元賄款。<br><br>
                                            &emsp;&emsp;檢調指出，陳姓包商因方苑公司已停業，向立品及益川公司借牌標下「新埤」、「布袋第三漁港」工程，直接拿錢給陳處長。綽號「順哥」的王X順與陳處長熟識，疑充當「白手套」，京揚標下的「龍宮溪」及「內田排水系統」兩案，即透過王拿錢給陳處長。<br><br>
                                            &emsp;&emsp;案發後嘉義縣長陳明文說，相信同仁清白，也絕不護短，靜候司法調查。新埤、內田和龍宮溪等三案，都屬於八年八百億元治水工程，四件工程目前仍在執行中。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-8 variant=""
                                    >首例！上網徵「性伴侶」&nbsp;判刑3個月</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-8"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;TVBS&nbsp;2008/08/11</h4>
                                        <p>
                                            &emsp;&emsp;一名32歲的刺青師父阿森，因為跟妻子離婚，想要交女朋友，上網留言「徵性伴侶」，卻換來3個月的徒刑，成為全台灣首件因為徵性伴侶而遭到判刑的首例。他說他沒有想性交易，單純交友，竟然被判刑，感嘆台灣沒有言論自由。<br><br>
                                            &emsp;&emsp;無奈的拿著法院寄來的判決書，刺青師父阿森，沒想到自己竟然成了全台灣，因為上網徵求性伴侶而遭到判刑的第一人。刺青師傅阿森：「我滿驚訝的，對啊，想想我是全台灣第一個最笨的，竟然會因為這個事情被判刑。」<br><br>
                                            &emsp;&emsp;跟妻子離婚後，阿森在網路上留言，想找個單純、長期無壓力的性伴侶，註明自己的年次、身高、體重，期待認識你，誰知道「性伴侶」3個字，法院認為他違反兒童及少年性交易防治條例的散佈暗示性交易訊息罪，判他3個月徒刑，換成罰金要9萬元。<br><br>
                                            &emsp;&emsp;阿森說，他只是單純想要交女朋友，不是想性交易，不懂為什麼，性伴侶跟援交會畫上等號，被判有罪，但有網友換成同音不同字的「等元」或3P卻無罪。阿森：「我看很多人都有留言，我以為上去留的人都跟我一樣，所以我也不以為有什麼不對的地方。」<br><br>
                                            &emsp;&emsp;感嘆台灣沒有言論自由，阿森只能努力刺青賺錢，如果這個星期五沒有籌到9萬元，就得因為徵求「性伴侶」去坐牢。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-9 variant=""
                                    >捉姦&nbsp;情婦竟是女上司&nbsp;妻當街罵夫：她又老又醜&nbsp;哪點比我好</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-9"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路&nbsp;2008/08/09&nbsp;張景閎╱彰化報導</h4>
                                        <p>
                                            &emsp;&emsp;桃園一對男女朋友因為感情生變，談判分手時男方心有不甘，竟然拿出水果刀往女友背上猛刺，甚至因為太用力了，刀刃斷掉留在女友體內，被人報警送醫，幸好動過手術把刀刃取出來，女子暫時沒有生命危險，而殺人的男子隨後投案，被移送法辦。<br><br>
                                            &emsp;&emsp;女子坐在醫院病床一臉痛苦表情，和男友談判分手不成，還被對方拿水果刀刺傷全身，背後大片血跡，剛送到醫院時情況相當危急。<br><br>
                                            &emsp;&emsp;警方：「他就亂揮，先劃傷她左手背，再朝她胸口刺傷，刺到右肩，她一縮，痛就一縮，就插到她背上，原本先送署桃，署桃說傷口太深不敢開，所以就轉長庚。」<br><br>
                                            &emsp;&emsp;交往同居4年的這對男女朋友，疑似因為債務壓力加上感情生變，女方約男方到這間汽車旅館談分手，還寫下切結書還給男方10萬元，沒想到男方不甘心分手，拿出預藏的水果刀往女友身上猛刺，還因為太用力，刀柄斷掉，長達6公分的刀刃就留在女子體內。<br><br>
                                            &emsp;&emsp;女子受傷倒地，陪同前往談判的朋友報警送醫，幸好留在體內的刀刃沒有傷到內臟，開刀取出來後，女子暫時沒有生命危險，而殺人男子隨後投案，他說因為太愛對方才會鑄下大錯，最後還是被依殺人罪移送法辦。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-11 variant=""
                                    >窮男愛上富家女&nbsp;簽約保證薪4萬</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-11"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路&nbsp;2008/08/08&nbsp;葉濬明╱彰化報導</h4>
                                        <p>
                                            &emsp;&emsp;一名王姓農家子弟透過人介紹與銀行主管的楊姓千金相戀，但楊父認為王男學歷收入低，不贊成兩人交往，不過楊女十分堅持，楊父只好擬妥「婚嫁合約書」，要求男方至少得念完大學、薪水四萬元以上的工作，不得未婚懷孕等條款。王男為求抱得美人歸，當場簽下合約書。<br>
                                            見證該合約介紹人唐俊民說，專科畢業的王男為早日迎娶，已插班考上大學，每天上班前先去送羊奶，晚上經營網路拍賣。<br><br>
                                            <span style="font-weight:600;">要求不可未婚懷孕</span><br>
                                            &emsp;&emsp;唐俊民表示，這名二十九歲的楊姓女主角、大學畢業，目前在私人診所擔任行政職，父親是銀行主管、母親則是退休教師，雖然幫他介紹過醫師、公務員等對象，但她就是看不上眼，反而對只有專科畢業王男來電。<br>
                                            &emsp;&emsp;但楊父獲知女兒交往對象學歷、收入都不高，並不贊成女兒與他交往，女兒為此差點鬧家庭革命，楊父才妥協簽下男方必須大學畢業、有穩定工作、交往期間不可在外過夜、婚前不可懷孕等內容，否則女方不得繼承遺產的「婚嫁合約書」。<br>
                                            &emsp;&emsp;兩性專家廖輝英說，女方父親要求簽下這份合約，主要也是鼓勵男方上進，在情、理上是ＯＫ的。但律師趙惠如認為，這份合約並沒有法律效力，尤其是財產繼承權部分，並不能對未發生的事，預先拋棄繼承，因此這份合約應該是女方家長測試男方是否真心與女兒交往而已。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-12 variant=""
                                    >夫愛A片勝過她&nbsp;判離&nbsp;妻控藏上萬張&nbsp;8點一到就鎖門猛看</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-12"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路&nbsp;2008/08/06</h4>
                                        <p>
                                            &emsp;&emsp;台中市婦人洪璿臻，不滿丈夫林永祥收藏了上萬片Ａ片，每天晚上八時就鎖門看Ａ片，不和她同房，還因想中樂透一天到晚算牌。法官現場勘驗後，發現林確實有滿滿一整箱的Ａ片，認為他沒顧及妻子感受，大量蒐集Ａ片又長期沉溺，已經超過正常程度，日前判決兩人離婚。<br>
                                            林永祥昨說：「太誇張了，那麼小的箱子怎麼可能裝上萬片光碟？況且那是十幾年來蒐集，裝箱準備丟掉的，她（指妻子）要用這理由離婚，我也認了。」他還強調，他從小就在電影院長大，也喜歡電影，蒐集的Ａ片都是較有藝術價值的影片，例如歐美的《艾曼妞》、《Ｏ孃》、《整型春秋》系列，和日本的風景Ａ片，至於變態影片他根本沒興趣，而自己雖然性慾很強，但絕不會強迫妻子。<br><br>
                                            林男稱喜歡研究影片<br>
                                            &emsp;&emsp;洪婦向法官表示，他與丈夫結婚十三年生下三個兒子，但近幾年，丈夫以睡眠習慣不同為由，在另一個房間睡覺，而且一到晚上八時就進房鎖門，後來她才發現丈夫沉溺色情，房內竟收藏了上萬片的色情影片。<br>
                                            &emsp;&emsp;洪婦還說，丈夫還花了許多時間，計算樂透彩中獎機率，她曾懇求丈夫丟掉Ａ片，也別再賭博，但都被拒絕，兩人分房七年來，也很少有性行為，她擔心孩子生活在色情與賭博中，才打離婚官司並爭取孩子監護權。<br><br>
                                            法官認未顧及妻感受<br>                                    
                                            &emsp;&emsp;林還解釋，他雖有買樂透的習慣，但一次只買幾百元，因為得四處送貨需要睡眠，才和妻子分房睡，但夫妻間還是有性生活。他還反控，自己賣屋幫妻子還債後，妻子反而無故離家。<br>
                                            &emsp;&emsp;而洪婦聲請保全證據後，法官在林的房間內，發現一個高約五十公分、寬約二十公分的紙箱，裡面裝滿色情光碟，抽取一片播放，果然也是有上馬賽克的Ａ片；法官也在林的另一住處，查到三百多片色情光碟。<br>
                                            &emsp;&emsp;法官認為，男性婚後觀賞Ａ片和買樂透，如果是偶一為之無可厚非，但林未顧及妻子感受，大量蒐集、長期沉溺，而兩人分房長達七年少有性行為，證明Ａ片和樂透彩已影響二人婚姻，判決兩人離婚。至於孩子的監護權，則尊重孩子的意願，判給父親。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-13 variant=""
                                    >軍官勾人妻&nbsp;25小時上3次&nbsp;受害夫檢舉&nbsp;軍方竟說：管不到家務事</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-13"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路&nbsp;2008/08/04&nbsp;專案組、政治中心╱台北報導</h4>
                                        <p>
                                            &emsp;&emsp;部落格裡藏著「不能說的秘密」！一名上尉軍官放假回家，意外發現妻子在個人部落格中書寫外遇過程，經質問，妻子坦承被陸軍官校一名上尉軍官勾引，25小時內發生3次性關係。軍官丈夫憤而要求陸官依軍紀處理，不料陸官竟回覆：「軍法管不到家務事。」讓他非常不滿地說：「難道國防部准國軍去妨害別人家庭嗎？」轉而向警方提出妨害家庭告訴。<br><br>
                                            &emsp;&emsp;軍官丈夫「小周」（30歲）與妻子（24歲）經朋友介紹相戀，結婚一年餘。他向《蘋果》記者表示，他6月20日自部隊放假回家，看到家中電腦開著，螢幕停在妻子的6月17日部落格網頁上，他好奇瀏覽，發現一篇文章寫著：「上星期的回憶..對我而言是美好的...是第一次有男生帶我去海邊看有月亮的夜景..是第一次和男生可以無話不談..」。<br><br>
                                            <span style="font-weight:600;">月色下沙灘偷情</span><br>
                                            &emsp;&emsp;小周說，他非常震驚，因為這段時間他沒帶妻子去看月亮，憤而質問妻子：「他是誰？」妻子驚慌失措坦承外遇，對方是陸軍官校學生部隊上尉副中隊長吳哲維（25歲，未婚，前天轉往政戰學校中共解放軍研究所就讀）。小周說，妻子和盤托出全部偷情過程，後悔地求他原諒。<br>
                                            &emsp;&emsp;周妻向《蘋果》表示，她先前與吳哲維只有一面之緣，但對方透過友人要到她的電話，頻繁打電話找她聊天。6月10日，吳突然邀她吃飯，還提議去高雄西子灣沙灘賞月。她原認為吳只是普通朋友，但兩人並坐沙灘時，吳突然強吻她，雙手並在她身上游移，她下意識地說：「不要！我們之間不可以發生性關係。」但吳說：「妳不要講話。」兩人就發生了性關係。隨後吳帶她到汽車旅館和高雄家中做愛。總計從11日凌晨零時30分至12日凌晨1時30分至吳家發生性關係，25小時內發生3次性關係。<br><br>
                                            <span style="font-weight:600;">妻：支持丈夫提告</span><br>
                                            &emsp;&emsp;周妻說，事發後她相當後悔，也不想和吳哲維再聯繫，才會在部落格寫下這段輕狂記憶，並作出結論，「在冷靜過後...或許在身邊的人才能帶給我平凡的幸福吧...因為他才是我需要負責的人..」。
                                            &emsp;&emsp;周妻說，不料事後吳哲維還打電話問她：「妳會不會因為我離婚？」她回說：「絕對不會。」吳卻慫恿：「妳要離婚，我們才有可能在一起。」更說：「妳離婚後，若又交往其他男友，是否願意再和我出遊偷情？」<br>
                                            &emsp;&emsp;小周說，他氣憤向陸官政戰主任林協詳檢舉，要求調查，之後陸官監察官張勝欽回覆：「軍法管不到家務事。」這種答案讓他難以接受。且吳哲維始終避不見面，僅透過父母表示願以10萬元和解，他認為吳無道歉誠意，拒絕和解，於上周提告。周妻也說，吳面對問題竟躲起來，不敢承擔，她支持丈夫提告。<br><br>
                                            <span style="font-weight:600;">妨害家庭可囚1年</span><br>
                                            &emsp;&emsp;記者昨致電找吳哲維對質，他表示與周妻只是朋友，「一開始她也沒有跟我講說她結婚了」，他們只是出去吃飯，根本沒發生性關係。<br>
                                            &emsp;&emsp;不過，吳哲維這番辯辭顯難自圓其說，因他首次見到外形亮麗的周妻時，是到周家接回他與周妻的共同女性友人，且小周當時陪在妻子身邊；事後他向這名女性友人要周妻電話，對方明白告知周妻已婚。<br>
                                            &emsp;&emsp;針對陸官說：「軍法管不到家務事。」上月剛上任的陸官政戰主任吳榮軒說，本案上月初已調查，吳哲維否認有此事，調查亦無具體事證。《蘋果》要求直接採訪監察官張勝欽，吳榮軒說，他回應即可。陸軍司令部政戰主任周彥中強調，官校意思是「有具體事證，我們就依法送法辦，如果找不出具體的事證，我們也沒辦法干涉。」只要被害人提出事證，絕對依法辦理。<br><br>
                                            &emsp;&emsp;國防部政戰局軍紀監察處表示，依《國軍風紀維護實施規定》，違反營內外兩性規定者，將由所屬單位召開人評會，根據《陸海空軍懲罰法》懲處。軍官懲處按情節輕重依序為申誡、檢束、罰薪、記過、撤職等，撤職未滿一年不得再任用。<br>
                                            &emsp;&emsp;立法院外交國防委員會委員蔡同榮昨痛批，陸官是培育軍官的搖籃，不能以身作則，如何教育未來軍官，陸官對本案不應敷衍了事。律師莊秀銘說，《刑法》妨害家庭及婚姻罪可處一年以下徒刑，但屬告訴乃論，需由當事人提告；《民法》部分，當事人可求損害賠償和精神撫慰金。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-14 variant=""
                                    >廚工保飯碗&nbsp;替老闆娶二奶</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-14"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路&nbsp;2008/08/02&nbsp;黎百代、張沛森、談曉泉╱桃園報導</h4>
                                        <p>
                                            &emsp;&emsp;一名在團膳公司幫廚的男子，今年元月以假結婚方式幫老闆娶回越南籍二奶，二奶還在台打工，男子事後不滿屢遭老闆扣薪刁難，憤而向新竹市調站自首並辭職。男子最近投訴《蘋果》，控訴老闆要求他翻供不成，恐嚇要將他「斷手斷腳」；老闆受訪時否認羅男指控，堅稱是離職員工挾怨報復。<br><br>                                    
                                            <span style="font-weight:600;">自首後屢被恐嚇</span><br>
                                            &emsp;&emsp;羅煥源（二十七歲，新竹縣人）表示，他在老闆張睿騰（四十四歲）開設的應達團膳公司廚房幫廚，去年因張非法僱用越南外勞被查獲，張的越南籍二奶阮氏萱也被遣送出境，張要求他以假結婚方式到越南迎娶阮女，當時他為保住飯碗而同意，今年一月阮女來台時，由張到桃園國際機場接走阮女，阮女更被安排到桃園少輔院廚房幫廚。<br>
                                            &emsp;&emsp;羅煥源說，幫忙娶回阮女半年來，老闆不斷無故扣薪，還揚言開除他，他才在今年七月間到新竹市調查站自首，調查站事後到桃園少輔院約談阮氏萱，全案移交移民署新竹專勤隊偵辦，他則因被老闆恐嚇而離職，他害怕遭報復四處躲藏，並向《蘋果》投訴揭發老闆惡行。<br><br>
                                            <span style="font-weight:600;">僱主指挾怨報復</span><br>
                                            &emsp;&emsp;新竹專勤隊表示，阮氏萱涉及假結婚案已在偵辦，張男妻子到案時也證實丈夫曾帶了一名二奶回來，但張男昨受訪時矢口否認羅男指控，堅稱是遭挾怨報復，至於阮氏是否為其二奶，他以私人問題為由拒絕回答。                                        
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-15 variant=""
                                    >夫需索無度&nbsp;妻聞做愛發抖&nbsp;日逼行房四、五次 聲請家暴保護令獲准</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-15"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;壹蘋果網路&nbsp;2008/08/02&nbsp;李菁豪╱高雄報導</h4>
                                        <p>
                                            &emsp;&emsp;高市一名婦人不堪性慾旺盛的丈夫十年來幾乎天天要做愛，有時甚至一天四、五次，連生理期也不放過她，今年五月向警方聲請家暴保護令；婦人日前取得保護令後，如釋重負地向警方道謝，但心有餘悸地說：「現在一聽到『做愛』兩個字，還會發抖害怕。」<br><br>
                                            <span style="font-weight:600;">不顧四歲兒在旁硬上</span><br>
                                            &emsp;&emsp;警方表示，朱姓婦人（三十二歲，高市人）五月下旬到警局表示：「我先生需索無度，我真的受不了，」要求聲請保護令。擔任全職主婦的婦人說，她十年前經介紹與做工的丈夫、林姓男子（三十五歲）結婚，丈夫幾乎天天都要做愛一、兩次，逢丈夫休假時，則按早中晚三餐外加消夜，一天要求做愛四、五次。<br>
                                            &emsp;&emsp;警方轉述，朱婦剛開始覺得也許是新婚，丈夫才會「要的比較多」，但生了四個孩子後，先生依然需索無度，連公婆到家中看孫子，丈夫也會突然性起，強拉婦人到房間做愛。<br>
                                            &emsp;&emsp;逢生理期，丈夫還是硬要，甚至說：「到廁所去，經血流出來直接用水沖，不會弄髒床。」 朱婦無奈向警方說：「為了孩子，我都忍了。」但今年五月初，丈夫竟趁同房分床睡的兩個兒子睡著時，又逼她做愛，吵醒四歲大的兒子，兒子看到爸爸壓在她身上，哭喊：「爸爸，你為什麼打媽媽？」林男竟怒吼：「把頭轉過去，不要看，快睡覺！」<br><br>
                                            <span style="font-weight:600;">要求至少遠離百公尺</span><br>
                                            &emsp;&emsp;經過此事，朱婦決定脫離十年來有如性虐待般的婚姻生活，帶著孩子搬出去住。朱婦日前取得的家暴保護令，要求丈夫「應遠離朱婦租屋處至少一百公尺。」高雄市婦女新知協會總幹事林秀怡表示，夫妻間的性生活若有不適，雙方應開誠溝通。高雄市立凱旋醫院副院長周煌智則說：「男人每周有三到四次的性行為算正常，他（林男）十年來幾乎每天四、五次，算是異於常人。」他建議林男最好檢查腦下垂體的性腺激素是否分泌異常。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-16 variant=""
                                    >婦斬婚外情&nbsp;慘遭小男友勒斃</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-16"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;中時電子報&nbsp;2008/07/30&nbsp;李菁豪╱高雄報導</h4>
                                        <p>
                                            &emsp;&emsp;女子婚外情，慘遭小男朋友勒斃！四十五歲女子歐姓婦人和小她十三歲的李嫌發生姊弟婚外情，疑因歐女要分手，李男心有不甘，廿九日徒手勒斃歐女後，又打電話報警表示要自首；不料警方趕抵命案現場時，李嫌已逃逸無蹤。<br>
                                            &emsp;&emsp;二十九日下午三點十五分，歐姓婦人的女兒發現媽媽已陳屍楊梅鎮住家地下室。但楊梅警方卻在三點十分接到自稱男子李嫌（卅二歲）的電話，李男向楊梅幼獅派出所說：「我人在楊梅鎮獅二路上，我殺人要自首！」警方趕到後，男子早已經騎乘機車畏罪潛逃。<br>
                                            &emsp;&emsp;幼獅派出所警方表示，隨後接到死者女兒報案電話，稱母親陳屍在地下室，警方立即趕抵現場，果然發現歐女被勒斃在自家地下室。「之前有聽到媽媽在電話中跟他大吵，媽媽說要分手。」歐姓婦人讀國中的小女兒，呆坐在警局雙手緊握靜靜地說著。<br>
                                            &emsp;&emsp;歐姓婦人女兒指稱，今天身體不舒服，提前下課回家。下午李嫌謊稱其母親要拿存摺給他，她一度心生懷疑，不過，看到嫌犯手持家中鑰匙進門，又堅稱是她媽媽託付的，於是就到母親房間拿郵局存摺和提款卡交給嫌犯，李嫌還要求她交付中機車鑰匙。<br>
                                            &emsp;&emsp;李嫌離開數分鐘過後，歐麗娟女兒就接到他的電話，自稱：「我做了傻事，我太愛你媽媽了！」電話中並表明，已經將歐姓婦人勒斃在地下室，歐姓婦人兩名女兒到地下室尋找，發現媽媽陳屍在地下室樓梯間。<br>
                                            &emsp;&emsp;死者女兒說，爸爸長期在大陸工作，母親和嫌犯是在便利商店上班認識的，已經認識一年多了，平常都是和媽媽在家門外聊天，不曾進家門，對她們也都不錯，沒想到會發生這種事。警方調查，李嫌近日常和被害人口角，行兇原因仍待釐清，將全力緝捕兇嫌到案說明。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-17 variant=""
                                    >妻買毒買出牆&nbsp;夫告密破毒窟</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-17"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;自由時報&nbsp;2008/07/30&nbsp;記者陳文嬋／高雄報導</h4>
                                        <p>
                                            &emsp;&emsp;張姓毒蟲與蘇女結婚3年多，經常透過老婆向吳姓毒販買毒，不料2人竟因此暗通款曲，張被迫離婚；張不甘戴綠帽，檢舉助警破獲藏身夜市毒窟，一舉逮獲販毒鴛鴦送辦。<br>
                                            警方調查，張姓毒蟲與蘇姓女子同是28歲時因吸毒認識，2人長期吸食毒品，由張負責掙錢，蘇女協助買毒，蘇女於7個月前買毒結識吳姓毒販後，轉為定期向他買毒。<br>
                                            &emsp;&emsp;警方指出，蘇女外表亮麗，吳某高大帥氣，雙方見面後互有好感，吳即對蘇女展開熱烈追求，男女關係發展迅速，最後買毒買到床上去。<br><br>
                                            &emsp;&emsp;警方說，張發現老婆與吳通話頻繁，內容曖昧不已，質問下蘇女坦承與吳發生關係，更以毒品供貨威脅，堅持要求兩人離婚，令張悔恨不已。<br>
                                            &emsp;&emsp;警方強調，張不甘老婆被搶，向警方檢舉吳販毒，三民二警分局展開跟監調查，歷時2個多月偵辦，前夜見時機成熟，循線埋伏六合夜市旁一棟出租套房大樓，一舉逮獲吳與蘇女，現場起獲毒品殘留吸食器、止血帶及2支涉嫌販毒交易手機。<br>
                                            &emsp;&emsp;吳嫌﹙28歲，有搶奪、毒品前科﹚、蘇女2人到案後否認犯行，辯稱是向張嫌買毒不成，反遭陷害入罪。但3名毒蟲指證歷歷，警方握有鴛鴦販毒通聯紀錄，訊後將2人依毒品罪嫌送辦。                                        
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-18 variant=""
                                    >嫂薄紗出軌&nbsp;弟破門抓姦兄生意夥伴「照顧到床上」&nbsp;遭毆手斷頭破</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-18"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;自由時報&nbsp;2008/07/29&nbsp;李菁豪╱高雄報導</h4>
                                        <p>
                                            &emsp;&emsp;高雄市一名男子大前天接到在中國經商的哥哥來電，表示家中電話無人接聽，希望他前往查看，男子到達後發現哥哥家大門反鎖，叫門也無人回應，他擔心出意外，破門而入，赫見大嫂穿著性感睡衣躺在床上，哥哥多年的生意夥伴全身赤裸睡在一旁，男子憤而將姦夫打得雙手骨折、頭破血流，大罵：「你照顧我嫂子竟然照顧到床上！」<br><br>
                                            <span style="font-weight:600;">不堪入目</span><br>
                                            &emsp;&emsp;警方表示，這起糾紛發生在上周四上午十一時左右，張姓男子（34歲）接到在中國大陸經營咖啡廳的哥哥來電，表示在台灣的大嫂（藍姓婦人，36歲）一直沒接電話，請他到家中看一下。<br><br>
                                            <span style="font-weight:600;">目睹姦情一時嚇呆</span><br>
                                            &emsp;&emsp;張男拿鑰匙到哥哥家，發現大門由內反鎖，打電話也沒人接，再打大嫂手機也無回應，他擔心大嫂出意外，從車上拿出螺絲起子，撬開大門栓鍊直衝哥哥臥房。<br>
                                            &emsp;&emsp;一打開哥哥臥房房門，張男整個人嚇呆，他看到大嫂穿著一件白色透明薄紗性感睡衣躺在床上，三點隱約可見，旁邊還躺著一名脫個精光的男子，地上還有一堆擦拭過的衛生紙，更令他抓狂的是，裸男竟是他哥哥的多年好友兼合開咖啡廳的生意夥伴蔡姓男子（36歲）。<br>
                                            &emsp;&emsp;為證明藍婦和蔡男通姦，張男趕緊用手機拍下兩人的同床畫面，並氣得搖醒兩人大罵：「你們這對狗男女，怎麼對得起我哥！」怒火中燒的張男拿起木棍，將蔡男痛毆一頓，打得蔡雙手骨折、頭破血流。<br>
                                            張男狂毆蔡男五、六分鐘後，馬上打電話給在大陸的哥哥：「哥，你的好朋友竟然在你的床上上你老婆，你一定要告他們！」哥哥則表示近日會趕回台灣處理，屆時再考慮是否要告兩人通姦。<br>
                                            &emsp;&emsp;掛掉電話後，張男數落大嫂：「妳沒接電話，我哥和我以為妳出意外，沒想到，妳竟然跟別人在爽！」張並質問兩人：「你們什麼時候開始的？」<br><br>
                                            <span style="font-weight:600;">辯稱蓋棉被純聊天</span><br>
                                            &emsp;&emsp;兩人一直辯稱只是普通朋友，從沒發生過性關係，因蔡男沒地方睡覺，藍婦才讓蔡暫住一晚，兩人雖然脫光，但只是蓋棉被純聊天，張男聽後怒斥：「這種鬼話，誰會相信！」<br>
                                            警方獲報後將蔡男送醫，並問他是否要提出傷害告訴，蔡男表示要保留法律追訴權，張男說：「他背叛我哥，上我大嫂，竟還有臉要告我，真無恥！」
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-19 variant=""
                                    >潛屋抓姦不成&nbsp;妻聚賭抽頭反被抓</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-19"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;中時電子報&nbsp;2008/07/22</h4>
                                        <p>
                                            &emsp;&emsp;43歲張女懷疑丈夫在外租屋打算金屋藏嬌，號召4位好友到租屋處，伺機埋伏準備抓姦，但苦等2天卻沒有發現，乾脆打起麻將打發時間。因柳女每圈抽頭100元，作為購買啤酒小菜基金，警方認定構成賭博罪嫌要件，偵訊後移送法辦。<br>
                                            &emsp;&emsp;警方指出，家住蘆洲的張姓女子不滿從事木工行業的陳姓丈夫，在外四處勾搭女子，抓包多次仍不知悔改，雙方為此多次爭吵。月初丈夫告知要在蘆洲市永樂街83號租屋，準備開設彩券行。但張女卻不見彩券行開張，懷疑丈夫租屋開設彩券行只是幌子，實際上是要金屋藏嬌，與蔡姓、蕭姓、黃姓、詹姓等4名友人商量後，決議輪班埋伏在租屋處，守株待兔伺機抓姦。埋伏2天絲毫不見張女丈夫身影，張女不好意思讓朋友久等，從自家搬來麻將桌與大家輪番上陣玩麻將、打發時間，並約定每圈要自摸的人捐100元當購買飲料、小菜等基金。<br>
                                            &emsp;&emsp;由於打麻將聲響過大，引來鄰居非議並報警處理，警方到場後將5人帶回並查扣麻將、骰子與賭資1300元，張女雖向警方撇清抓姦無聊才會打打衛生麻將，但因抽頭行為明確，警方仍依賭博罪嫌，將5人移送法辦。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-20 variant=""
                                    >妻外遇生子&nbsp;老榮民優雅復仇</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-20"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;聯合新聞網&nbsp;2008/07/18</h4>
                                        <p>
                                            &emsp;&emsp;新竹縣八十四歲的陳姓榮民，廿三年前娶小他四十二歲的妻子，當時妻子已與他人暗結珠胎，五個月後產子，他雖寬容以對，但他妻子卻又外遇產子並離家十餘年。他不甘將身後退伍俸留給妻子，上月訴請離婚獲准。<br>
                                            &emsp;&emsp;「我對她沒有恨，離婚主要目的是讓她無法領退伍俸，是希望符合社會正義。」昨天陳姓榮民談起拋夫棄子的妻子，不願口出惡言，但對法院判決，表示「很滿意」。<br>
                                            &emsp;&emsp;陳姓榮民說，民國七十四年他在新竹縣五峰鄉認識當時卅七歲的前妻，不久女方急著要結婚，當時他已經六十一歲，很想有個伴，便和女方結婚。新婚不久即發現妻子是「帶球」嫁給他，婚後不到五個月產下一子，他決定寬容以對，將沒有血緣的孩子視如己出。<br>
                                            &emsp;&emsp;「可能是年紀差太多，我沒辦法應付她吧。」陳姓榮民說，前妻生下孩子後，喜歡往外跑，無心顧家。民國八十二年，前妻又外遇產下一子，並在外和男友同居，八十七年離家出走，「我已經十年沒看過她了。」<br>
                                            &emsp;&emsp;陳姓女子離家後，鮮少返家探視兩個兒子，反倒是和孩子沒有血緣關係的陳姓榮民扛起照顧孩子的責任，父兼母職。<br>
                                            &emsp;&emsp;老榮民廿四歲的長子說：「老爸真的很偉大，無怨無悔照顧我和弟弟。」要出庭為父母離婚官司當證人，內心有過掙扎，「一邊是生我的母親，一邊是養育我的爸爸」，幾經思慮，才決定力挺老爸，出庭當證人。陳姓榮民的前妻經通知未到庭說明，法官認為她沒有不能同居的正當理由，裁准離婚。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-4">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle.accordion-4 variant=""
                                    >交往4年&nbsp;嬌聲女友...是矮胖男</b-button
                                >
                                </b-card-header>
                                <b-collapse
                                id="accordion-4"
                                accordion="my-accordion"
                                role="tabpanel"
                                >
                                <b-card-body>
                                    <b-card-text>
                                        <h4>轉載&nbsp;聯合新聞網&nbsp;2008/07/16</h4>
                                        <p>
                                            &emsp;&emsp;一名卅多歲的宅男，和女網友「陳姿語」交往四年多，連面都沒見到就花掉一百五十萬元，最後覺得奇怪而報案。檢方追查發現他的「女友」，竟然是「矮矮、黑黑、胖胖」的劉姓男大學生。<br><br>
                                            &emsp;&emsp;據了解，劉姓大學生雖然其貌不揚，但聲音卻尖尖、細細的，如果沒有看到本人，還真會被認為是女的。辦案人員說，這個「美聲男」與被害人聯繫時，都是透過電話聯絡，絕不假手他人。<br><br>
                                            &emsp;&emsp;賴姓男子是上班族，達適婚年齡找尋伴侶無著。九十二年間在蕃薯藤交友網站（現已更名為天空交友）留言交友，當時還就讀高中的劉姓大學生（現為廿一歲）透過網路與他聊天，並自稱是「陳姿語」、單身。<br><br>
                                            &emsp;&emsp;劉佯稱想要和賴男交朋友，互留電話；從九十二年十一月開始到去年九月止，兩人透過電話、網路，談了近四年戀愛。「戀情」穩定後，「陳姿語」即向賴姓男子騙錢，陸續借款數萬元到十餘萬元，後來改用「陳姿語」的姊妹、表姊妹或哥哥（冒用姓名包括陳姿涵、陳姿心、陳姿玟、陳語潔及陳凱文）繼續騙錢。<br><br>
                                            &emsp;&emsp;雖然劉姓大學生騙錢的理由千篇一律，不是親友生病死亡，就是被錢莊逼債，但賴卻沒有警覺，不斷以現金袋或匯款方式，陸續匯了一百四十萬元給「她」；去年初，劉又佯裝邀賴一起去日本玩，騙了四萬元後以身體不舒服為由沒有成行。<br><br>
                                            &emsp;&emsp;去年九月，賴開始警覺「女友」怪怪的，親自走訪「她」留給他的地址，卻發現地址是假的。賴質問後，劉姓大學生見騙局快被揭發，再編一套理由，自稱本名是「邱惠玲」，願意提供本票給賴做為借款擔保。<br><br>
                                            &emsp;&emsp;後來劉又假冒「賴佳惠」名義開了一張一千萬元本票給賴，還約在台中交付，賴仍不知道給他支票的就是他的「女友陳姿語」，直到向地檢署提出詐欺告訴，經檢方抽絲剝繭追查，才查到原來是劉姓大學生的騙局。劉姓學生坦承犯罪，並歸還廿七萬元，約定其他款項將繼續返還。法院認為他犯後態度良好，判處兩年有期徒刑、緩刑五年。
                                        </p>
                                    </b-card-text>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <nav class="d-flex justify-content-center">
                                <ul class="pagination">
                                <li class="page-item">
                                    <router-link to="/News" class="page-link">回上頁</router-link> 
                                </li>
                                <li class="page-item">
                                    <router-link to="/News" class="page-link">1</router-link>
                                </li>
                                <li class="page-item active" aria-current="page">
                                    <span class="page-link">2</span>
                                </li>
                                <li class="page-item disabled">
                                    <span class="page-link">下一頁</span>                                                               
                                </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <b-modal id="modal-lg" size="lg" centered title="外遇調查">
        <div class="d-block">
          <p class="qa-p">
            愛真的可以天長地久嗎? 我的另一半明天是否依然愛我?<br><br>
            &emsp;&emsp;親愛的他(她)對你越來越冷淡了?當你遇到人生中不如意的事情，正當不知所措急於想知道事情的真相，雖然我們都知道，事實的真相是殘酷的現實，況且您可能只是懷疑，我們給您快速又專業的徵信，你只要一如平常的作息等待我們用最短的時間給您真相。<br>
            國華女人徵信依據業務多年的經驗提供下列幾點給各位做個參考：<br>
            <ul class="list">
              <li>找各種徹夜不歸或晚歸的理由（如：開會、加班）</li>
              <li>行為舉止怪異（如：態度冷漠、動不動就說要離婚或分手）</li>
              <li>接到常有不出聲電話、電話費明顯暴增、手機有曖昧之簡訊</li>
              <li>接到某人來電時藉故迴避談話</li>
              <li>性愛次數明顯減少</li>
              <li>開銷花費明顯增加</li>
              <li>轎車上或身上有來歷不明的物品</li>
              <li>耳聞同事或友人所見之情形</li>
              <li>常外出說要去 7-11 等便利商店買東西，其實是為了掩飾打電話給情人</li>
              <li>突然變得很溫柔，變得很容易妥協 ! ( 彌補偷情的愧疚心理 )</li>
              <li>對於你的問題回答的很模糊，甚至問太多就會動怒</li>
            </ul>
            &emsp;&emsp;以上幾點是一般外遇徵兆常見之情形，也十之八九有問題；其實另一半只要有外遇，不管如何謹慎小心，一定都會有破綻。但是每個人常犯的錯誤就是打草驚蛇、沉不住氣或是杯弓蛇影、無中生有，所以在沒有確切證據前，沒有辦法小心沉著，是許多人無法忍耐的；我們的專業不僅只是告訴您真相，而是可以在您的另一半確實有出軌行為的蒐證與建議，重要的是如果另一半真是沒有外遇行為，也不希望因為懷疑造成家庭破碎。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-1" size="lg" centered title="外遇蒐證">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;在現今社會中，無論是男人或是女人，在外遇的時候都會給自己很多藉口，甚至大部分的偷情者都有種”家花不如野花香”的觀念，讓不知情的另一半因此而深受傷害；因為絕大多數的人，在偷情以後，就忽略了原本一直陪在身邊的伴侶。<br><br>
            &emsp;&emsp;經過了外遇調查後，若證實對方已經有外遇劈腿的情形，後續的處理，需請您沉住氣，因為接下來的就不是像吵架一樣的一吐為快，如何在這個時候佔到優勢的地方，就是您需要最專業的我們的原因，以下大致分為三種方式：<br><br>
            <span style="color:red; font-size:20px;">感情破壞：</span><br>
            針對第三者所研究出來的方式，配合狀況，使第三者或被查人在"不知情"的情況下自然的分手，並徹底不再往來，使夫妻兩人之間感情的障礙移除，但是想要恢復以往美滿和諧的家庭生活，有時候並不只是第三者的原因，在這方面，國華女人也可以提供最完整、實在的方式，來讓您了解問題的癥結點在哪，而達到完美的結局。<br><br>
            <span style="color:red; font-size:20px;">感情挽回：</span><br>
            &emsp;&emsp;如何挽回先生太太的心？需依當時情況而言，之中技巧萬千，非筆墨所能形容；主要需針對客戶狀況，主要為選用科技種類與心理技巧、打動人心......等，以達到挽回之目的。<br>
            其實女人外遇是最困難且挽回機率很低的，相對於男人外遇大多是肉體外遇，女人卻是精神外遇先開始才會到肉體外遇，但是女人變心並不快，可是只要一變心就會變的冷血！如果女人真的外遇，男人真的要沉靜心裡先想想看到底是什麼讓你們的感情走了樣；讓她覺得被忽略？覺得你不再呵護她？不在乎她的感受、把她捧在心上？女人永遠是希望自己是被捧在手中的寶。<br><br>
            &emsp;&emsp;節日送個小禮物、開口說愛她，甜言蜜語都不可少！不要怕肉麻，如果你真想挽回，就趕快去做吧！接著就是做些讓她感動的事，邊觀察她對外遇對象是真的動心，還是一時的慰藉。<br><br>
            <span style="color:red; font-size:20px;">捉姦在床：</span><br>
            &emsp;&emsp;這是對已經精疲力盡，不想再對這段感情付出的人的最佳途徑，也可以徹底和另一半斷絕關係，並且對於小孩監護權及贍養費求償方面有極大勝算。<br>
            相反的這也是斷絕第三者跟另一半糾纏不清的最好方法之一，不要以為這樣就會撕破臉以後夫妻感情會更糟，當然事前這些都會經過我們的建議跟當事人的決定情形來斟酌用何種方式來辦理。<br>
            &emsp;&emsp;然而這最後行動前所運用的精密儀器和專員使用的場合方式，都會讓委託人清楚的明白我們一定是用最符合您的方式，讓不管是任何一方都不會受到不必要的傷害。<br><br>
            提供幾種外遇方式作為參考：<br>
            <ul>
              <li>網路戀情......</li>
              <li>大陸包二奶......</li>
              <li>生意往來......</li>
            </ul>
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal2"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-2" size="lg" centered title="大陸及海外案件">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;對於大陸二奶問題特別重視，針對台商問題除有專業人員派遣之外，並與當地的業者配合加上精密的蒐證儀器，針對案情無往不利，所有的姦情絕對讓您寮如指掌，並且及早預防。<br><br>
            &emsp;&emsp;大陸地區一直是台商的最愛，近來由於政府對大陸政策鬆綁，使得台商更是有如脫韁野馬，每個人都想到大陸去發展，連一般人,尤其是男性，更是把大陸當作【觀光勝地】【出國考察】的第一順位。原因無他，因為大陸地區土地廣大，女人多，價錢便宜（在台灣酒店消費一次，約在大陸可以消費七天，並且可以帶出場），而且大陸女性對台胞情有獨鍾，深深了解如何施展手腕來擄獲台商的心、並非是台灣一般風化場所可比較的。使得每每去過大陸之友人，剛回台又想再去一次。更遑論居住在大陸的台商或台商員工，老婆不在身邊的甘苦，絕非一般人能體會，所以在當地尋找溫柔女子來慰寂反而是一種非常正當的情形,，有這樣，旁人還以為是你有問題呢！當然，妳也不要以為妳搬過去一起夫唱夫隨就可以預防這種事情的發生，據我們調查的案件顯示近3成外遇問題都是夫妻一起過去創業，創業危堅時夫妻同心協力共創美好的未來，有錢時老公就開始作怪，開始往外發展。案情輕者，往往只是拈花惹草。重者，搞得妻離子散，更甚者，除了【錢】進大陸外，事業也跟著走下坡，瀕臨破產的情形更是屢見不鮮，為人妻者不可不防。<br><br>
            <ul>
              <li>市場調查徵信 - 產品產銷地點、行銷管道等等。</li>
              <li>產品仿冒徵信 - 專利、產品商標之侵權、仿冒，生產公司、地點的追查。</li>
              <li>信用調查徵信 - 公司行號或個人生意往來間之信用程度。</li>
              <li>動產、不動產鑑定 鑑定個人及公司動產、不動產狀況。</li>
              <li>各種疑難徵信 - 協助個人或周遭之人、事、物的困難解決。</li>
              <li>外遇徵信-俗稱通姦的調查。</li>
              <li>個人素行 個人行為操守的偵查。</li>
              <li>婚前徵信 男女朋友交往的忠實性。</li>
              <li>尋人查址 如倒債、捲款潛逃、離家出走（逃妻、逃夫、子女等）。</li>
            </ul>
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal3"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-3" size="lg" centered title="外遇抓姦">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;真正優良的徵信公司會依照正常合理的抓姦過程來處理一個專案，因為要符合行使訴訟之人要負舉證之責的律法邏輯。換句話說必需採證到正在進行式的姦情才能在法律的觀點上去維護委託人的全面性立場。如果婚姻經過專業的我們蒐證與審慎評估後，無挽回之必要時，就只有用抓姦的方法，方可解決問題並保障自己與孩子的權益；並且我們會將通姦者對受害的委託人造成的傷害降到最低，這才是專業應有的表現。<br><br>
            &emsp;&emsp;【偷情】【外遇】【抓姦】【婚外情】【養小狼狗】【包二奶】這幾個字詞常態性地出現在電視新聞和報章雜誌裡，甚至媒體也經常的運用這些題材拍成電影或綜藝節目探討的主題。現代人對自我個體有著非常強烈的意識抬頭，忠貞也在現代社會的感情上讓人非常重視，「一夫多妻」和「一妻多夫」這種事情已不被通融允許，法律上也有嚴格的條例規範，但也因為不再光明正大，所以才會有所謂的外遇、偷情、抓姦、婚外情甚至在兩岸三通後更有包二奶的事情頻傳；甚至在價值觀扭曲的現今，有許多人為了這種作壞事的叛逆感而樂此不疲。<br><br>
            &emsp;&emsp;國華女人徵信在提供相關諮詢解答到蒐證之後，雖然台灣人傳統”勸和不卻離，家和萬事興”的道德觀念，但是每一段感情，並不是自己委屈求全就可以讓家庭幸福美滿，但是佛也有發火的時候，我們會給您最專業、貼心的建議，讓您在對的這一方取得優勢。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal4"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-4" size="lg" centered title="家庭暴力">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;國華女人徵信，專為解決家庭暴力，讓您及您的家人走出家庭暴力的陰霾；同時讓您瞭解什麼是家庭暴力防治法，此法是如何規範及保護您的權益；在現今社會中，人民生活水準提昇，所得卻不成比例的比比皆是；民眾面臨的生活壓力持續提昇，進而造成無辜的家人成了發洩的對像，引發社會上多起的家庭暴力事件。<br><br>
            &emsp;&emsp;家庭暴力影響堪大，它是造成家庭破碎的主要原因之一，更是影響孩子成長階段的心靈發展；真相真探徵信家暴防治部提供家暴相關新聞，讓施暴者瞭解家庭暴力對家人所造成的傷害有多大；是否應該冷靜想想，懸崖勒馬。家庭暴力防治法規，讓受害者勇於對外求助，免於家暴傷害。<br><br>
            <span style="font-size:25px;color:red;">注意事項</span><br>
            <ul class="list">
              <li>簡單清楚的流程，您可明白所有的作業程序，若有任何疑問本公司將有專人為您解答</li>
              <li>本公司有常年配合的律師顧問，您可以放心將此重任託付給我們</li>
              <li>對於客戶的資料，本公司負有保密之責，不會將客戶資料隨意散播</li>
              <li>一旦結案，本公司將會把所有資料銷毀，以確保您我權益</li>
            </ul>
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal5"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-5" size="lg" centered title="婚前徵信">
        <div class="d-block">
          <p class="qa-p">
            <span style="font-size:25px; font-weight:600;">背叛與不忠是人類追求刺激的誘因</span> <br><br>
            &emsp;&emsp;背叛與不忠是人類追求刺激的誘因,當你遇到人生中不如意的事情時,在這裡我們打破傳統,給你們迅速又專業的徵信,你只需要高枕無憂的在家中等待我們給您的答案。你真的了解你身邊的另一半嗎 ? 為了你的終身幸福婚,你記得幫身體做健康檢查,怕身體出狀況,給自己多一點保障。國華女人婚前徵信，絕對是你披上白紗前必做的幸福健診！<br>
            &emsp;&emsp;有鑑於此,只要委託者提供被查人之基本資料，國華女人婚前徵信，即可提供以下六大部分,作為日後交往或結婚之參考。<br>
            <ul>
              <li>家庭背景投資事業有無誇大不實</li>
              <li>家族有無先天性疾病或隔代遺傳之精神疾病</li>
              <li>偵查是否與前男 ( 女 ) 友藕斷絲連以及劈腿之惡行</li>
              <li>蒐集有無前科或不良之紀錄</li>
              <li>個人信用調查及銀行往來之紀錄</li>
              <li>學經歷是否虛假偽造</li>
            </ul>
            <span style="font-weight:600;">國華女人婚前徵信</span>調查您所要調查的對象，無論男女情人或者是準新人，為您提供相關法律諮詢或心理輔導等多項服務，並根除不肖份子以婚姻方式之名行詐騙之實，造成社會治安問題及人性道德之淪喪，亦可避免自身無端成為他人婚姻之第三者。我們提供您最溫柔的建議以及百分之百的忠誠服務！保護您的個人權益，更是我們永不退讓的堅持。<br><br>
            <span style="font-size:25px; font-weight:600;">人的一生,往往需要做出許多重要的決定</span><br><br>
            &emsp;&emsp;人的一生中，往往需要做出許多重要的決定，不論事情的大小；婚姻使得兩個相愛的人做出決定，但我們無法正確的得知所嫁或所娶的是不是用真實的那一面對著您？往往我們都會尋求一種模式，交往過一陣子在愛情的喜悅與甜蜜中，自以為他 ( 她 ) 是我的真命天子、 Mr.right ？但真的了解以及認識我們的另一半嗎？<br>
            &emsp;&emsp;所以為何現今社會的婚姻所維繫的時間越來越短？人們當初眼中的佳偶，卻又往往以怨偶收場，不能老是拿那一套個性不合，因不了解而結合又因了解而分離吧！我們常聽到未雨綢繆，這叫事先規劃的風險，評估管理，投資理財會想到、但為何在人生婚姻上不做些評估及管理呢？屆時再來個吵鬧分離人才兩失！<br>
            &emsp;&emsp;一個婚姻是兩個人的結合，我們自以為像童話故事般的王子與公主，從此過快樂無憂的日子，孰不知這才是故事的開始。但這個故事是要喜劇收場還是歹戲拖棚往往看雙方有無用心，干擾一段婚姻能否美滿，在於雙方有無正確了解彼此？<br>
            &emsp;&emsp;因為任何一件小事甚鉅，比如雙方的家庭背景 ( 如前科、已婚、通緝 ) ；財務狀況 ( 如信用、資產 ， 有無遺傳病，在感情尚未真正投入埋下往後的不定時炸彈前，做好婚前的徵信讓用心經營的人種下良緣得到善果，祝福全天下真心相愛的人，永浴愛河！<br><br>
            <span style="font-size:25px; font-weight:600;">你真的了解你身邊的另一半嗎？</span><br><br>
            &emsp;&emsp;你真的了解你身邊的另一半嗎？ 你怕取錯妻、嫁錯郎嗎？ 怕身體出狀況，在走上紅毯前，為了你的終身幸福， 當然更應該替您的婚姻也把把脈！ 給自己多一點保障， 為什麼命運差那麼多...？為什麼最初的理想會崩潰...？為何愛情會被生活磨蝕而幻滅...？<br>
            &emsp;&emsp;時代的變遷，新台灣人的觀念隨之而改變，道德及兩性間的認知或有所不知，導致離婚、家庭暴力不斷發生，進而影響下一代的教育，導致製造社會上的問題！ 其實戀愛是抽象的，婚姻卻是現實的，會有許多衝突摩擦！所以，愛情是沒有永久保證書的！<br>
            &emsp;&emsp;婚前徵信的重要性： 讓你透視另一半的本質，可提供你不了解的另一面，可供參考，作為你走進婚姻的心理依據！ 健診內容<br>
            <ul class="list">
              <li>感情忠實度</li>
              <li>家世背景</li>
              <li>學經歷、經濟狀況...</li>
              <li>動產、不動產</li>
              <li>另一半家族病史</li>
              <li>交友狀況及前科紀錄</li>
            </ul>
            國華女人婚前徵信&emsp;絕對是你披上白紗前必做的幸福健診！<br><br>
            <span style="font-size:25px; font-weight:600;">婚前徵信的重要性更甚於健康檢查！</span><br><br>
            &emsp;&emsp;人的一生中，不管大小事，都不停的在做選擇賭注。 對男人或女人而言，最大的賭注就是婚姻！押對的人，一生幸福快樂；押錯的人，情路坎坷波折不斷，甚至遭到暴力相向以至於家破人亡。 這其中當然有環境或經濟上的因素，難以克服；但也有一部份是因為婚前對於交往對象的了解不夠，而造成終身的遺憾。<br>
            &emsp;&emsp;以前或許你沒有能力及管道去徹底了解你的交往對象，也許是對方的甜言蜜語而沉溺在幸福浪漫的假象裡，而不敢去面對現實。然而婚前徵信就如同婚前健康檢查一樣重要！ 透過健康檢查可以幫您發現對方是否有家族遺傳病史或其他傳染疾病，在個人可接受的範圍內，或許您會願意接受陪伴您的另外一半一路走下來，哪怕是身體有缺陷的人，因為他 ( 她 ) 是坦白的，能夠讓我們眼睛看的見。<br>
            &emsp;&emsp;婚前徵信的重要性更甚於健康檢查！ 有人因為貪圖對方的一切或家產，交往之初，對當事人百依百順，百般奉承的表現出一副忠誠老實模樣，一旦結婚後慢慢地露出真面目侵占當事人的一切或家產；若配偶不從將以百般虐待，甚至於暴力相向，逼迫 ( 他 ) 她就範以及離婚收取贍養費收場。 也有人交往的對象是走私、販毒者前科累累而不知，等到感情全部投入後，才發現真相後悔已來不及了；這麼重要的終身幸福，您能不重視嗎？ 國華女人徵信可幫您做完整的婚前調查，為您的幸福把關。<br><br>
            <span style="font-size:25px; font-weight:600;">結婚就是兩個人帶了不同的期待走進禮堂</span><br><br>
            &emsp;&emsp;有人說：「結婚就是兩個人帶了不同的期待走進禮堂」所以婚後有人吵鬧有人白頭偕老。台灣的結離婚的比例，從前些年的每十對有一對到近年的的每十對就有七對，數字的飆漲不禁令人咋舌，但相對也讓我們警覺出婚前徵信的重要性。<br>
            &emsp;&emsp;愛情的迷思有時讓人無法理性思考，但透過業者的協助，精準的搜尋與客觀的分析，希望能讓您在婚姻的路途上走的更踏實、甜蜜；而且若一但有不利於你的危機查出，你才能理性的去解決 ( 還要不要繼續這段關係；要不要懸崖勒馬 ) 而重新設定您的標準。 離婚率不斷爬升，失婚男女越來越多，當一對對的佳偶變怨偶，當婚後的您必須面對喜歡酗酒、外遇 ( 偷情 ) 或者還要忍受家庭暴力、沉重負債，小孩的監護權及雙方家長的問題，林林總總的痛苦之後，何不讓我們防範於未然，人人都需要有危機意識，有時靠自己的眼光是有限的，求助於外力並不丟臉，而是建立信心的第一步，話說知己知彼，讓我們運用智慧來換取雙贏的美滿婚姻。真正的原因就是這麼簡單，但人們總是被誤導，生活中許多解決事情的辦法都很容易，但要找出這個辦法卻很不容易。<br><br>
            <span style="font-size:25px; font-weight:600;">您所選的對象真的值得您託付終生嗎?</span><br><br>
            &emsp;&emsp;戀愛是那麼甜蜜美好，因為那只是兩個個體的心靈交流、相處。但婚姻是現實的它是兩個家庭、經濟，兩個人的生活的結合。然而愛情是盲目的，熱戀中的男女往往看不清事實的真相，在現成離婚率高的社會，更增顯婚前徵信的重要性。<br>
            &emsp;&emsp;「婚前徵信」有如選舉前對候選人的徵信，也是抓住幸福的機會，它可以準確地為您倆的愛情開出診斷証明，讓您全盤了解將來的另一半，例如財產、信用、交友(包括對你(妳)是否專一)、健康(包括家族遺傳疾病)、學歷(是否造假)....。每個人尋尋覓覓,直到遇見自以為的真命天子(女)而開始交往戀愛，到論及婚嫁。但您所選的對象真的值得您託付終生嗎？婚姻是人生中重要的一環，可知若是選對了人，婚姻就是一輩子，選錯了人，就可以只有幾年，甚至只有短短幾天！ 經過了婚前徵信的調查，若是選擇正確，可以加深您對將來另一半的信任感；選擇錯誤，方可讓您看清真相，是要繼續？還是放棄？有了「婚前徵信」，讓您做出最明智的選擇，再也不怕娶錯妻、嫁錯郎。<br><br>
            <span style="font-size:25px; font-weight:600;">婚前徵信讓您遠離『危險情人』與『分手暴力』生嗎?</span><br><br>
            &emsp;&emsp;自殺殉情、潑硫酸、情殺…等『分手暴力』事件頻傳，熱戀中的男女往往忽略如何觀察自己心儀的對象是否是『危險情人』，而一頭栽進未來可能會遭遇『分手暴力』的未知風險中。 先前白曉燕案獲判無罪的關係人張智輝女友遭其殺害事件，就是分手談判破裂慘遭情殺的典型個案，如果能在交往中小心觀察，委婉、漸進式疏遠的分手，應該可以避免生命遭受威脅甚至喪命的分手暴力事件。 危險情人的特質（資料提供/高雄市立凱旋醫院）：<br>
            <ul class="list">
              <li>甜言蜜語、容易呈現迷人與自吹自擂的特質。</li>
              <li>缺乏長期知交的人際關係。</li>
              <li>低自尊、不關心自己的前途。</li>
              <li>與家人關係多為不和睦。</li>
              <li>不易對他人表示關懷。</li>
              <li>在校、工作期間行為紀錄不良。</li>
              <li>易生氣、衝動、與他人發生衝突。</li>
              <li>堅持伴侶凡事都需要應順從自己的要求。</li>
              <li>工作穩定度不夠。</li>
              <li>有酗酒、吸毒習慣。</li>
              <li>不易為自己的犯錯感到抱歉或自責。</li>
            </ul>
            &emsp;&emsp;一般熱戀交往的男女朋友即將步入結婚禮堂前，往往疏忽將來兩人世界會面臨的諸多問題，僅會規劃拍美美的婚紗照，細心一點的會去做婚前健康檢查及婚後財務、理財約定，『婚前徵信』可以調查交往中的朋友交友狀況、財務現況、個人信用、前科或法院判決、訴訟紀錄、抵押紀錄、學歷文憑是否屬實、日常品性及行為、家庭成員及相處情況等提供給準新郎、新娘做為評估終身伴侶之參考資料。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal6"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-6" size="lg" centered title="婚姻諮詢">
        <div class="d-block">
          <p class="qa-p">
            <span style="font-size:25px; font-weight:600;">Q1:我先生經常半夜喝得酩酊大醉才回來，他說是為了公事，才出去交際應酬的，可是我很擔心他這樣殘害自己的身體，我該怎麼勸他呢？</span><br><br>
            &emsp;&emsp;熬夜、喝酒對身體的負面影響很大，而且這種影響往往不是立即展現，所以很多夜貓族、酗酒族就忽視了其危害性。先生經常在外熬夜喝酒交際，不但戕害自己的健康，也害妻子在家提心吊膽，更可能因晚歸減少了夫妻相處的時間，而引起婚姻危機，真的是得不償失！面對先生的熬夜應酬、喝酒交際的行為，妻子在表達不滿之前，不妨先將自己的關懷和擔心表現出來,讓先生知道妳真的很心疼見到他如此不經易地殘害自己。<br>
            &emsp;&emsp;然後在先生清醒時，靜下心來和他討論改善之道：是否一定要如此熬夜、喝酒,才能在公司裡受到重用和認同？有沒有其他方式可以取代？如果先生也有心改善這種情形，夫妻雙方將不難找出解決之道；但是，如果先生無意改善，甚至對這樣燈紅酒綠的生活感到樂此不疲，那麼，也許得借重婚姻諮商專業機構人員的協助，才能有所改進。<br><br>
            <span style="font-size:25px; font-weight:600;">Q2:我結婚五年了，有兩個孩子。最近我愛上了另一個男人，自己也變得喜歡打扮，彷彿又回到過去談戀愛時期；但另一方面，也對先生感到愧疚和有罪惡感，我該怎麼辦呢？</span><br><br>
            &emsp;&emsp;婚姻生活是現實的，它往往需要負擔柴米油鹽的責任，亦有照顧小孩、公婆的負擔，故生活就被一件一件瑣事堆積，而變得平淡無味。此時，若夫妻無法在生活中增添情趣，戀愛的虛幻感覺，即可能趁虛而入，使人有機會掉入外遇的陷阱中。<br>
            &emsp;&emsp;在上述事件中，因當事者處在兩個男人之中，故存在著很多的衝突矛盾，有必要在兩個男人中有所割捨。割捨前，不妨多給自己有段反省的時間，想想兩個男人對自己的意義與價值，及自己在割捨間之利弊得失。若自己一時無法釐清，尋求專業人士的幫助，亦是不錯的方式。 當然，在分離過程中，亦可能會遭遇割捨不下之難題或情緒空虛的害怕，此時若能在時空上配合，即拉遠和欲分離者的距離，並尋求一可信任之情緒之支持者，都是幫助自己走出外遇陰影的方式。當然若能告訴自己的「另一半」，並獲得「另一半」的支持，則在分離的路上，較不易顯得孤單和寂寞。 另外，先生於初知道此消息時，必定會暴跳如雷，但氣消之後，如果能協助太太度過此難關，相信對她將是最大的支柱。<br><br>
            <span style="font-size:25px; font-weight:600;">Q3: 我和我先生結婚五年了，我發現我們的性生活愈來愈平淡乏味，我擔心再這樣下去，一定會影響婚姻的關係與品質，該怎麼辦呢？</span><br><br>
            &emsp;&emsp;經驗分享：夫妻在一起生活愈久，就愈不懂得浪漫，也愈不懂得在生活中製造一些情趣，平淡和無趣成了每天必然的感受。雖然這樣的描述很寫實，但卻過於消極和無奈，其實每對夫妻是可以不必這樣過的。 夫妻結婚多年之後，對於另一半的新鮮感會漸漸減少許多，很多事無形中都變得例行而且單調，性生活也不例外。如果夫妻任何一方已意識到這種情形，不妨多回想以前戀愛或新婚時常去的場所、最喜歡的情境，然後在現實生活中重新營造類似的氣氛，讓彼此重溫舊、重燃愛火。當然你也可以將臥房的佈置稍做調整：燈光調柔和一點、擺些花飾、播放些浪漫的輕柔音樂………。當然，若環境、時間許可，換換做愛的地點，例如客廳的沙發上、渡假小木屋......，也是增進情趣的方法。總之，只要雙方願意肯花點心思營造一些情境與驚奇，日子就不會一直乏味下去的。<br><br>
            <span style="font-size:25px; font-weight:600;">Q4: 我是個晚睡晚起型的人，而我先生卻是早睡早起慣了，我們睡眠時間的不一致，讓我們彼此都很困擾。另外，我喜歡點小燈睡覺，但我先生卻堅持要關燈睡，為此，常常搞得不是我失眠，就是他無法入睡，怎麼辦呢？</span><br><br>
            &emsp;&emsp;兩個原本在不同家庭中成長的人，在結為夫妻之後，首先面臨到的衝突往往是生活習慣的不一致，有些夫妻甚至在新婚之夜就為了擠牙膏的小事而怒目相向。夫妻在遇到生活習慣不一致的情況時，千萬別想一定要對方改變，順應自己的方式，畢竟那些生活習慣已伴隨他數十年之久了。最好是採取折衷、雙贏的辦法，彼此都退半步，幾天的適應期之後，差不多可以習慣新的生活方式，夫妻雙方將不再為這些生活瑣事而大動干戈了。<br>
            &emsp;&emsp;晚睡的妻子和早睡的丈夫，不妨各自提早、延後一小時或兩小時就寢，如此一來，睡眠時間就可以逐漸調到一致了。而喜歡關燈睡覺的先生遇上喜歡開燈睡覺的太太時，何妨將臥室的燈掉，只留太太床頭邊的小燈，如此一來，對先生而言的確是關燈了，對太太而言也達到開燈的需求。<br><br>
            <span style="font-size:25px; font-weight:600;">Q5: 什麼是「家庭暴力」？遭受同居人虐待算不算是遭受家庭暴力呢？</span><br><br>
            &emsp;&emsp;依家庭暴力防治法第二條規定，家庭暴力指的是「家庭成員間實施身體或精神上不法侵害之行為」。 家庭成員」包括：
            <ul class="list">
              <li>配偶或前配偶。</li>
              <li>現有或曾有事實上之夫妻關係、家長家屬或家屬間關係者。（同居者即為事實上婚姻關係，即屬此類）</li>
              <li>現為或曾為直系血親或直系姻親。</li>
              <li>現為或曾為四親等以內之旁系血親或旁系姻親。</li>
            </ul>
            『身體上不法侵害』指的是：<br>
            例如虐待、遺棄、押賣、強迫、引誘從事不正當之職業或行為、濫用親權、利用或對兒童少年犯罪、傷害、妨害自由、性侵害…等。包括有鞭、毆、踢、捶、推、拉、甩、扯、摑、抓、咬、燒、扭曲肢體、揪頭髮、扼喉或使用器械攻擊等方式。<br>
            『精神上不法侵害』指的是：
            <ul class="list">
              <li>言詞虐待：用言詞、語調予以脅迫、恐嚇,以企圖控制被害人。如謾罵、吼叫、侮辱、諷刺、恫嚇、威脅傷害被害人或其親人、揚言使用暴力等。</li>
              <li>心理虐待：如竊聽、跟蹤、監視、冷漠、鄙視、羞辱、不實指控、試圖操縱被害人等足以引起人精神痛苦的不當行為。</li>
              <li>性虐待：強迫性幻想或特別的性活動、逼迫觀看色其影片或圖片等。</li>
            </ul>
            <span style="font-size:25px; font-weight:600;">Q6: 曾聽人提起『婚姻諮商』有助於改善夫妻之間的關係，當面臨家庭暴力時，不想離婚，如果去做婚姻諮商，會不會對問題有所幫助，到底什麼是婚姻諮商？</span><br><br>
            婚姻諮商不是化解夫妻衝突的萬靈丹,不是請輔導者幫助解決夫妻的問題,也不是達到某種仲裁效果,說明誰對誰錯,而是從實務的觀點,幫助雙方： <br>
            <ul class="list">
              <li>由不同角度看問題。</li>
              <li>培養更好的溝通能力與情緒控制能力。</li>
              <li>發展更親密的關係。</li>
              <li>正視困境、面對現狀、有效改善。</li>
              <li>了解差異、面對不同、尊重體諒。</li>
            </ul>
            &emsp;&emsp;好的諮商是提供夫妻更開放的溝通管道，協助雙方更有效的傾聽和表達，也能暸解問題所在，學習如何改善困境或是增加調適能力。如果希望婚姻諮商能對夫妻問題有所幫助，那麼「及早發現，及早治療」的觀念十分重要，在夫妻相處有問題、夫妻溝通有困難時，就可以尋求協助，不一定要等到已經有暴力發生時才去重視。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal7"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-7" size="lg" centered title="專案尋人查址">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;本公司擁有豐富的尋人經驗，範圍除了台灣本島外，海外地區涵蓋中國大陸、越南、泰國、新加坡、美國、日本等地區。本公司除了豐富的經驗之外，並有良好的外界關係，加上顧客若提供的正確諮料（如可以提供如室內電話、行動電話、戶籍、或身分證字號等，均可以透過這些來查址或查詢其他諮料，其他國家亦可）以達到在最快最短的時間之內找到想要找的人。<br><br>
            &emsp;&emsp;至於一般客戶所最擔心的價格問題，本公司收費保證公道合理，況且可以撥打免付費電話做諮詢，或許您只要花一點點時間撥個電話，可以得到最大的效益喔。不用懷疑，我們絕對有較阿亮更高級的本事去替您找尋你所要找尋的人。我們全省分佈的資源將會替您服務，相信我們的專業，將會帶給你想要找的。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal8"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal id="modal-8" size="lg" centered title="免費法律諮詢">
        <div class="d-block">
          <p class="qa-p">
            &emsp;&emsp;您在感情上有困惑嗎？這是一個女性意識抬頭的時代，女人已經不是弱者了，女人也可以是奧運金牌，也可以是政治首長，甚至家暴也不一定是女性受害，不過這些也只是您打開電視按鈕所看到的新聞，我們要讓您知道的，是當您在家庭內不被重視，無論是家暴（家庭暴力）、另一半外遇，”委曲求全”並不是您唯一可以做的，國華女人徵信無論在專業上貼心的建議、法律上實際的免費諮詢，都可以讓您感受到一整個無微不至的照顧。
          </p>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-right"
              @click="hideModal9"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
    </section>
  </div>
</template>

<script>
export default {
  name: "News2",
  data() {
    return {};
  },
};
</script>

<style scoped>
.hero-box {
  width: 100%;
}
.hero {
  background-image: url(../assets/images/service.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  z-index: 1;
  -webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
.lineder {
  font-size: 3rem;
  text-align: center;
}
.lineder::after {
  content: "";
  width: 8%;
  height: 1.5px;
  background: red;
  display: block;
  margin: 0 auto;
}
.warp {
  display: block;
  position: relative;
  width: 100%;
  top: -100px;
  margin-bottom: -80px;
}
.item-t1{
  margin-top: 55px;
}
.item-content{
  margin-bottom: 20px;
}
.item-content .item {
  padding: 1rem;
  background: white;
  text-align: center;
  border-top: 5px solid rebeccapurple;
  border-radius: 0px 0px 10px 10px;
  text-align: inherit;
}
.scroll-btn{
  text-align: center;
  margin: 0 auto;
}
#mouse-scroll {
  display: block;
  margin: 0 auto;
  width: 30px;
  z-index: 10;
}
#mouse-scroll span{
  display: block;
  width: 18px; 
  height: 18px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      transform: rotate(45deg);
  border-right: 2px solid black; 
  border-bottom: 2px solid black;
  margin: 0 auto;
}
#mouse-scroll .down-arrow-1, #mouse-scroll .down-arrow-2, #mouse-scroll .down-arrow-3 {
    -webkit-animation: mouse-scroll 1s infinite; 
    -moz-animation: mouse-scroll 1s infinite;
}
#mouse-croll .down-arrow-1 {
   -webkit-animation-delay: .1s; 
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-2 {
  -webkit-animation-delay: .2s; 
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}
#mouse-scroll .down-arrow-3 {
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}
@-webkit-keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-webkit-keyframes mouse-scroll {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  } 
}
@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
#mouse-scroll:hover{
  cursor: pointer;
}
.grid {
	position: relative;
	margin: 0 auto;
	/* padding: 1em 0 4em;
	max-width: 1000px; */
	list-style: none;
	text-align: center;
}
.grid figure {
	position: relative;
	float: left;
	overflow: hidden;
	/* margin: 10px 1%;
	min-width: 320px;
	max-width: 480px;
	max-height: 360px;
	width: 48%; */
	background: #3085a3;
	text-align: center;
	cursor: pointer;
}
.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}
.grid figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}
.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
  z-index: 99;
}
.grid figure h2 {
	word-spacing: -0.15em;
	font-weight: 300;
  font-size: 22px;
}
.grid figure h2 span {
	font-weight: 800;
}
.grid figure h2,
.grid figure p {
	margin: 0;
}
.grid figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}
figure.effect-honey {
	background: #4a3753;
}
figure.effect-honey img {
	opacity: 0.9;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}
figure.effect-honey:hover img {
	opacity: 0.5;
}
figure.effect-honey figcaption::before {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 10px;
	background: gold;
	content: '';
	-webkit-transform: translate3d(0,10px,0);
	transform: translate3d(0,10px,0);
}
figure.effect-honey h2 {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 1em 1.5em;
	width: 100%;
	text-align: left;
	-webkit-transform: translate3d(0,-30px,0);
	transform: translate3d(0,-30px,0);
}
figure.effect-honey h2 i {
	font-style: normal;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0,-30px,0);
	transform: translate3d(0,-30px,0);
}
figure.effect-honey figcaption::before,
figure.effect-honey h2 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}
figure.effect-honey:hover figcaption::before,
figure.effect-honey:hover h2,
figure.effect-honey:hover h2 i {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}
.p-qa{
  margin-bottom: 0;
}
.p-qa:hover{
  color: slateblue;
}
.qa-p{
  margin-bottom: 0;
}
.list{
  margin: 0.5rem auto;
  list-style-type: decimal;
}
.card-text h4 {
  background: burlywood;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  padding: 8px 10px;
  display: inline-block;
  /* margin: 30px 0 20px; */
  text-align: left;
}
.btn-secondary:hover{
  background: rgb(255, 222, 89);
  color: black;
}
.disabled:hover{
  cursor: not-allowed;
}
/* .frame {
  margin: 0 auto;
  padding: 1.5rem;
  background: white;
  border-top: 5px solid rebeccapurple;
  margin-bottom: 20px;
  border-radius: 0px 0px 10px 10px;
} */
@media(max-width:767px){
  .lineder::after{
    width: 20%;
  }
  .modal-open .modal{
    padding: 0 !important;
  }
  .modal-dialog{
    margin: 0 0.5rem;
  }
}
</style>

<style>
.modal-title{
  font-size:26px;
  font-weight: 600;
  letter-spacing: 1px;
}
.pagination a{
  color: #007bff !important;
}
</style>
